/* Arquivo da homepage */
/* Criado por Carlos Eduardo em 03/01/2019 */
/* Em caso de dúvidas: contato.carlos@outlook.com */

import React, { Component } from 'react';
import Title from '../../template/title';
import Backend from '../../utils/backend';
// import Config from '../../config';
import axios from 'axios';
import { getToken, isAuthenticated } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import './home.css';
import Table from '../detalhado/table';
import TableApontamentos from '../detalhado/tableApontamentos';
import Cabecalho from '../detalhado/cabecalho';
import DatePicker, { registerLocale } from 'react-datepicker';
import pt_BR from "date-fns/locale/pt-BR";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import "react-datepicker/dist/react-datepicker.css";
momentDurationFormatSetup(moment);
registerLocale("pt-BR", pt_BR);

export default class Home extends Component {



  constructor() {
    super();
    if(isAuthenticated() === false) window.location.href = "";
    this.state = { 
      carregando : false,
      user: jwt_decode(getToken()),
      perfil: null,
      horas : [],
      total: [],
      apontamentos : [],
      semana : [],
      intervalo: [],
      date: new Date(),
      dataInicio : null,
      dataFim : null,
      maxDate: new Date(),
      collapse: false,
      loading: false,
      tipoFiltro: 1,
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeDataInicio = this.changeDataInicio.bind(this);
    this.changeDataFim = this.changeDataFim.bind(this);
    this.filtrarData = this.filtrarData.bind(this);


    this.getDataSemana = (d, formatted = null, data_ = null) => {
      //função retorna data no formato YYYY-MM-DD contando a partir de
      // 1 = segunda, 2 = terça .... da semana atual
      // se formatted === true retorna dd/mm/yyyy
      // se data_ tem uma data, retorna da semana daquela data
      let data = null;
      if(data_) data = new Date(data_);
      else data = new Date();
      let day = data.getDay();
      if(day === 0) day = 7; // resolve o problema de ser de seg à dom
      data.setDate(data.getDate() - day + d);
      let dia = data.getDate();
      let mes = data.getMonth()+1;
      let ano = data.getFullYear();
      if(dia < 10) dia = `0${dia}`;
      if(mes < 10) mes = `0${mes}`;
      if(formatted) return `${dia}/${mes}/${ano}`;
      return `${ano}-${mes}-${dia}`;
    }
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  handleChange(date) {
    this.setState({ dataFim: date });
  }

  changeDataInicio(date){
    this.setState({ dataInicio: date });
  }

  changeDataFim(date){
    this.setState({ dataFim: date });
  }

  filtroMesPassado = async () => {
    const { date } = this.state;
    let mes = date.getMonth();
    const ano = date.getFullYear();
    const ultimoDia = this.diasNoMes((mes + 1), ano);
    mes = this.pad(mes, 2);
    // console.log(ultimoDia)

    await this.setState({
      dataInicio: new Date(ano, mes, '01'),
      dataFim: new Date(ano, mes, ultimoDia),
      tipoFiltro: 3,
    })
    this.filtrarData();
  }

  filtroSemanaPassada = async () => {
    const { date } = this.state;
    await this.setState({
      dataInicio: new Date(`${this.getDataSemana(-6, null, date)}T00:00:00`),
      dataFim: new Date(`${this.getDataSemana(0, null, date)}T00:00:00`),
      tipoFiltro: 2,
    })
    this.filtrarData();
  }

  filtroSemanaCorrente = async () => {
    await this.setState({
      dataInicio : null,
      dataFim : null,
      tipoFiltro: 1,
    })
    this.filtrarData();
  }

  filtroDias = async () => {
    await this.setState({
      tipoFiltro: 0,
    })
    this.filtrarData();
  }

  filtrarData = async () => {    
    await this.setState({
      loading: true,
      intervalo : []
    })
    let { dataInicio, dataFim, date } = this.state;
    if(dataInicio === null || dataFim === null){
      this.setState({
        dataInicio: new Date(`${this.getDataSemana(1, null, date)}T00:00:00`),
        dataFim: new Date(`${this.getDataSemana(7, null, date)}T00:00:00`)
      })
      dataInicio = this.state.dataInicio;
      dataFim = this.state.dataFim;
    }
    const { id_recurso }= this.state.user
    const timesheet_projeto = this.handleProjeto(id_recurso, dataInicio, dataFim);
    
    axios.post(Backend(`timesheet_projeto`), timesheet_projeto, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      this.setState({ horas: response.data.horas, total: response.data.total });
    });

    axios.post(Backend(`timesheet_apontamentos`), timesheet_projeto, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      this.setState({ apontamentos: response.data.apontamentos, loading: false });
    });

    let dataAtual = dataInicio;
    while(dataAtual <= dataFim){
      let strDataAtual = `${this.pad(dataAtual.getDate(), 2)}/${this.pad(dataAtual.getMonth() + 1, 2)}/${dataAtual.getFullYear()}`;
      let objDataAtual = `${this.pad(dataAtual.getMonth() + 1, 2)}-${this.pad(dataAtual.getDate(), 2)}-${dataAtual.getFullYear()}`;
      this.state.intervalo.push({
        strDataAtual,
        objDataAtual
      });
      // console.log(this.state.intervalo)
      dataAtual = new Date(dataAtual.getTime()+86400000)
    }    
  }

  diasNoMes(month, year) {
    return new Date(year, month, 0).getDate();
  }

  pad(number, length) {   
    let str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }       
    return str;    
  }

  handleProjeto(id_recurso, data_inicio, data_fim){
    return {
      "user": id_recurso,
      "data_inicio": data_inicio,
      "data_fim": data_fim
    }
  }


  componentDidMount = async () => {

    await this.setState({
      carregando : true
    })

    this.filtrarData()
    await axios.get(Backend(`perfil/${this.state.user.id_perfil}`)).then((response) => {
      this.setState({ 
        perfil: response.data.perfil,
        carregando : false
      });
    });
  }

  render() {
    
    return (
      <React.Fragment>
        <div className="animated fadeInRight home">
          <Title name="Detalhado" />
 
          <div className="container">
            <div className="row">
              <h2 style={{marginLeft:'15px'}}> Lançamento de horas por projeto </h2>
              <br />
            </div>
            {
              !this.state.carregando? 
              <Cabecalho callbackParent={() => this.filtrarData()} name={this.state.user.recurso} profile={this.state.perfil}
              tooltip="Apontamento detalhado" />
              : null
            }

            <div className="row">
              <div className="col-md-2">

                <label htmlFor="inicio">Data início</label>
                <br />
                <DatePicker
                  id="inicio" selected={this.state.dataInicio}
                  onChange={e => this.changeDataInicio(e)} locale='pt-BR'
                  dateFormat="dd/MM/yyyy" maxDate={this.state.dataFim}
                  todayButton={"Hoje"}
                  className="form-control data data-inicio"
                />
              </div>
              <div className="col-md-2">

                <label htmlFor="fim">Data fim</label>
                <br />
                <DatePicker
                  id="fim" selected={this.state.dataFim}
                  onChange={e => this.changeDataFim(e)} locale='pt-BR'
                  dateFormat="dd/MM/yyyy" minDate={this.state.dataInicio}
                  todayButton={"Hoje"}
                  className="form-control data data-fim"
                />
              </div>
              <div className="col-md-1 col-sm-1 col-1">
                <button onClick={() => this.filtroDias()} 
                  className={`${this.state.tipoFiltro === 0 ? 'btn-filtrar-data-selected' : 'btn-filtrar-data'} btn btn-sm`}>
                    Filtrar
                </button>
              </div>
              <div className="col-md-2 col-sm-2 col-2">
                <button onClick={() => this.filtroSemanaCorrente()} 
                  className={`${this.state.tipoFiltro === 1 ? 'btn-filtrar-data-selected' : 'btn-filtrar-data'} btn btn-sm`}>
                    Semana corrente
                </button>
              </div>
              <div className="col-md-2 col-sm-2 col-2">
                <button onClick={() => this.filtroSemanaPassada()} 
                  className={`${this.state.tipoFiltro === 2 ? 'btn-filtrar-data-selected' : 'btn-filtrar-data'} btn btn-sm`}>
                    Semana passada
                </button>
              </div>
              <div className="col-md-2 col-sm-2 col-2">
                <button onClick={() => this.filtroMesPassado()} 
                  className={`${this.state.tipoFiltro === 3 ? 'btn-filtrar-data-selected' : 'btn-filtrar-data'} btn btn-sm`}>
                    Mês corrente
                </button>
              </div>
            </div>
            
            <Table intervalo={this.state.intervalo} horas={this.state.horas} total={this.state.total} />
         
          </div>
        </div>

        <div className="animated fadeInUp home">
          <div className="container">
            <div className="row mt-5">
              <h2 style={{marginLeft:'15px'}}> Detalhe do apontamento </h2>
              <br />
            </div>

            <TableApontamentos
              loading={this.state.loading}
              functionParent={() => this.toggle()} stateCollapse={this.state.collapse}
              callbackParent={() => this.filtrarData()} apontamentos={this.state.apontamentos}
            />
          
          </div>
        </div>
      </React.Fragment>
    )
  }
}
