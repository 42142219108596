import { ADD_ID_PROJETO, REMOVE_ID_PROJETO } from '../actions/actionType';

const initState = {
    idProjeto: null
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case ADD_ID_PROJETO:
            return { idProjeto: action.payload.id };
        case REMOVE_ID_PROJETO:
            return { idProjeto: null };
        default:
            return state;
    }
}

export default reducer;