import React from 'react';
import THead from './thead';
import TBody from './tbody';
import axios from 'axios';
import Backend from '../../../../utils/backend';
import Swal from 'sweetalert2';

const Table = (props) => {

    const { macro, loading, nome } = props

    const downloadFile = (nome) => {
        const dadosDownload = { nome };
        axios({
            headers: {
                'Content-Type': 'application/json',
                dataType: "json",
                Accept: 'application/vnd.ms-excel'
            },
            url: Backend(`download_relatorio_banco_horas`),
            responseType: 'blob',
            data: dadosDownload,
            method: 'POST'
        }).then((response) => {
            if(response.data === false){
                Swal.fire({
                    title:"Erro ao baixar o arquivo",
                    text: "O arquivo solicitado para download não se encontra alocado no servidor. Informe o suporte.",
                    type: "error", confirmButtonColor: '#720425',
                });
            }else{
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                // console.log(blob)
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                const fileName = 'Banco_de_Horas_Detalhado.xlsx'
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();// you need to remove that elelment which is created before
            }
        });
    }
    
    return (
        <div>
            <div className="table-responsive col-md-11" style={{marginLeft:'-15px'}}>
                <table className="table table-bordered" cellSpacing="0" cellPadding="0">
                    <THead/>
                    { !loading ?  
                    <TBody 
                        macro={macro}/> 
                    : null} 
                </table>
            </div>
            <div className="relatorios animated fadeInRight col-md-11" style={{marginLeft:'-25px', marginBottom: '50px'}}>
                <button onClick={() => downloadFile(nome)} className="box-relatorios">
                    <div>
                        <p>Baixar relatório</p>
                        <i className="fa fa-download fa-2x" aria-hidden="true"></i>
                    </div>
                </button>
            </div>
        </div>
        
    )
}
export default Table;