import React from 'react';
import { Modal } from 'react-bootstrap';
import axios from "axios"
import Backend from '../../utils/backend';
import "./modal.css"
import { getToken } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import moment from "moment";
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import pt_BR from "date-fns/locale/pt-BR";
import momentDurationFormatSetup from "moment-duration-format";
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import AbaFolga from './components/abaFolga'
import 'react-toastify/dist/ReactToastify.css';
momentDurationFormatSetup(moment);
registerLocale("pt-BR", pt_BR);


class ModalComponente extends React.Component {

  componentDidMount = async () => {

    axios.post(Backend('subprojetos_select'), { "user": this.state.user.id_recurso }, {
      headers: { 'Content-Type': 'application/json' }
    })
      .then(res => {
        this.setState({ projetoList: res.data })
      })

    if (this.state.edit) {
      this.setState({
        carregando: true
      })

      await axios.post(Backend('busca_apontamento_id'), { "id_timesheet": this.state.id_timesheet }, {
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          this.setState({
            id_projeto_comercial: res.data.id_projeto_comercial,
            horaInicio: new Date(`${res.data.data_apontamento}T${res.data.dt_entrada}`),
            horaFim: new Date(`${res.data.data_apontamento}T${res.data.dt_saida}`),
            descricao: res.data.descricao,
            nomeProjeto: res.data.id_subprojeto,
            idSubprojeto: res.data.id_subprojeto,
            dataEntrada: new Date(`${res.data.data_apontamento} GMT-0300`),
            horaInicioInput: res.data.dt_entrada,
            horaFimInput: res.data.dt_saida,
            horaFimEdit: res.data.dt_saida,
            horaInicioEdit: res.data.dt_entrada,
            nomeSubprojeto: res.data.subprojeto,
            horarioAlmoco: res.data.hora_almoco,
            licenca: res.data.licenca,
            carregando: false
          })
          document.getElementById("checkHorarioAlmoco").disabled = true;
        })
    }
  }

  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.checkFerias = this.checkFerias.bind(this);
    this.changeFerias = this.changeFerias.bind(this);
    this.changeAba = this.changeAba.bind(this);

    this.state = {
      carregando: false,
      show: props.show,
      edit: props.edit,
      id_timesheet: props.id_timesheet,
      maxDate: new Date(),
      dataInsercao: new Date(),
      horaInicio: null,
      horaFim: null,
      completo: "",
      descricao: "",
      nomeProjeto: "",
      nomeSubprojeto: "",
      projetoList: [],
      idSubprojeto: "",
      dataEntrada: new Date(),
      id_projeto_comercial: '',
      status: 1,
      user: jwt_decode(getToken()),
      esforco: "",
      nomeProjetoErro: "",
      horaInicioErro: "",
      horaFimErro: "",
      descricaoErro: "",
      diffErroInicio: "",
      diffErroFim: "",
      overlapErro: "",
      horaInicioInput: "",
      horaFimInput: "",
      ferias: false,
      diasFerias: "",
      feriasErro: "",
      licenca: 0,
      horarioAlmoco: false,
      horarioInicioAlmoco: "",
      horarioFimAlmoco: "",
      errors: [],
      horaAlmocoErro: "",
      horaAlmocoErro2: "",
      horaAlmocoNum: null,
      salvando: false,
      horaFimEdit: "",
      horaInicioEdit: "",
      aba: "workgroup"
    }
    this.requisicao = props.callbackParent;
  }

  changeAba = (aba) => {
    this.setState({ aba })
  }

  salvarApontamentoAlmoco = async (sair) => {

    const apontamentoAlmoco = {
      "idSubprojeto": this.state.nomeProjeto,
      "dataEntrada": this.state.dataEntrada,
      "idColaborador": this.state.user.id_recurso,
      "descricao": this.state.descricao,
      "horaAlmoco1": this.state.horarioInicioAlmoco,
      "horaAlmoco2": this.state.horarioFimAlmoco,
      "horaInicio": this.state.horaInicioInput,
      "horaFim": this.state.horaFimInput,
      "licenca": this.state.licenca,
      "noc": this.state.user.noc
    };

    /* Veridica se há horas disponíveis no projeto*/
    const aceitaApontamento = await this.verificaHorasDisponiveis({ apontamentoAlmoco, almoco: true, edit: false })
    if (aceitaApontamento) {
      axios.post(Backend("post_almoco"), apontamentoAlmoco, {
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          const apontSalvo = 'Successo';
          if (res.data === apontSalvo) {
            this.limpaState(false)
            this.chamaMsgOk();
            if (sair)
              this.handleClose();
          }
          else {
            this.chamaMsgNok();
            this.setState({ errors: res.data })
          }
          this.setState({
            salvando: false
          });
        })
        .catch(err => {
          console.log(err)
          this.chamaMsgNok();
          this.setState({
            salvando: false,
          });
        })
    } else {
      this.alertDisponibilidadeHoras();
      this.chamaMsgNok();
    }
  }

  handleClose() {
    this.setState({
      show: false,
      horaInicio: "",
      horaFim: "",
      descricao: "",
      nomeProjeto: "",
      nomeProjetoErro: "",
      horaInicioErro: "",
      horaFimErro: "",
      descricaoErro: "",
      diffErroInicio: "",
      diffErroFim: "",
      escolhaErro: "",
      dataEntrada: new Date(),
      overlapErro: "",
      horaInicioInput: "",
      horaFimInput: "",
      ferias: false,
      diasFerias: 1,
      maxDate: new Date(),
      horarioInicioAlmoco: "",
      horarioFimAlmoco: "",
      errors: [],
      horaAlmocoErro: "",
      horaAlmocoErro2: "",
      horaFimEdit: "",
      horaInicioEdit: "",
    });
    this.requisicao()
  }

  handleShow() {
    this.setState({ show: true });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onChange2 = (e) => this.setState({
    idSubprojeto: e.target.options[e.target.selectedIndex].getAttribute("value")
  })

  onChange3 = (e) => this.setState({
    idSubprojeto: e.target.options[e.target.selectedIndex].getAttribute("value")
  })

  changedataEntrada = (data) => this.setState({ dataEntrada: data })

  changeHoraInicio = async (e, ferias = false, hora = null) => {
    let valor;
    let arrayHora = []
    if (ferias) {
      this.setState({
        horaInicioInput: hora
      })
      valor = hora;
      arrayHora = valor.split(":")
    }
    else {
      valor = e.target.value;
      arrayHora = valor.split(":")
      this.setState({
        horaInicioInput: `${arrayHora[0]}:${arrayHora[1]}`
      })
    }
    if (valor.length === 5) {
      this.setState({ horaInicioErro: "" })

      if (arrayHora[0] <= 23 && arrayHora[1] <= 59) {
        document.getElementById("horaFim_Input").focus();
        this.setState({
          horaInicio: this.state.horaInicioInput,
        })
      } else {
        this.setState({
          horaInicioInput: "",
          horaInicioErro: "Preencha o campo Hora com um valor real"
        })
      }
    } else {
      this.setState({
        horaInicio: null
      })
    }
  }

  changeHora = () => {
    let arrayHora1, arrayHora2;
    this.setState({
      horaInicio: this.state.horaInicioInput,
      horaFim: this.state.horaFimInput
    }, () => {
      arrayHora1 = this.state.horaInicio.split(":");
      arrayHora2 = this.state.horaFim.split(":");
      const arrayDia = document.getElementById('dataApontamento').value.split("/");
      const h1 = new Date(arrayDia[2], (arrayDia[1] - 1), arrayDia[0], arrayHora1[0], arrayHora1[1]);
      const h2 = new Date(arrayDia[2], (arrayDia[1] - 1), arrayDia[0], arrayHora2[0], arrayHora2[1]);
      if (arrayHora1[0] <= 23 && arrayHora1[1] <= 59) {
        this.setState({
          horaInicio: h1,
        })
      } else {
        this.setState({
          horaInicioInput: "",
          horaInicioErro: "Preencha o campo Hora com um valor real"
        })
      }

      if (arrayHora2[0] <= 23 && arrayHora2[1] <= 59)
        this.setState({
          horaFim: h2,
        })
      else {
        this.setState({
          horaFimInput: "",
          horaFimErro: "Preencha o campo Hora com um valor real"
        })
      }
    })

  }


  changeHoraFim = async (e, ferias = false, hora = null) => {
    let valor;
    let arrayHora = []
    if (ferias) {
      this.setState({
        horaFimInput: hora
      })
      valor = hora;
      arrayHora = valor.split(":")
    }
    else {
      valor = e.target.value;
      arrayHora = valor.split(":")
      this.setState({
        horaFimInput: `${arrayHora[0]}:${arrayHora[1]}`
      })
    }
    if (valor.length === 5) {
      this.setState({ horaFimErro: "" })

      if (arrayHora[0] <= 23 && arrayHora[1] <= 59)
        this.setState({
          horaFim: this.state.horaFimInput,
        })
      else {
        this.setState({
          horaFimInput: "",
          horaFimErro: "Preencha o campo Hora com um valor real"
        })
      }
    }
    else {
      this.setState({
        horaFim: null
      })
    }
  }

  changeHoraInicioAlmoco = async e => {

    const valor = e.target.value;
    this.setState({ horaAlmocoErro: "", horarioInicioAlmoco: e.target.value });
    let arrayHora = this.state.horarioInicioAlmoco.split(":");

    if (valor.length === 5) {
      if ((arrayHora[0] > 23) || (arrayHora[1] > 59)) {
        this.setState({ horaAlmocoErro: "Preencha o campo Hora com valor real", horarioInicioAlmoco: "" })
      }

      else {
        this.setState({ horaAlmocoErro: "", horarioInicioAlmoco: e.target.value });
        document.getElementById("horaFimAlmoco_Input").focus();
      }
    }
  }

  changeHoraFimAlmoco = e => {

    const valor = e.target.value;
    this.setState({ horaAlmocoErro2: "", horarioFimAlmoco: e.target.value });
    let arrayHora = this.state.horarioFimAlmoco.split(":");

    if (valor.length === 5) {
      if ((arrayHora[0] > 23) || (arrayHora[1] > 59)) {
        this.setState({ horaAlmocoErro2: "Preencha o campo Hora com valor real", horarioFimAlmoco: "" })
      }

      else {
        this.setState({ horaAlmocoErro2: "", horarioFimAlmoco: e.target.value });
      }
    }
  }

  checkFerias(e) {

    document.getElementById("checkLicenca").disabled = true;
    document.getElementById("checkHorarioAlmoco").disabled = true;

    if (this.state.edit) {
      document.getElementById("checkHorarioAlmoco").disabled = true;
    }

    const valor = e.target.checked;
    if (valor) {
      let variacao = 90 * 86400000;
      let { maxDate } = this.state;
      maxDate = maxDate.getTime();
      this.setState({
        ferias: valor,
        descricao: 'Férias',
        maxDate: new Date((maxDate + variacao)),
      })
      if (this.state.user.noc) {
        this.changeHoraInicio(null, true, '06:00');
        this.changeHoraFim(null, true, '12:00');
      } else {
        this.changeHoraInicio(null, true, '09:00');
        this.changeHoraFim(null, true, '18:00');
      }
    } else {
      this.setState({
        ferias: valor,
        descricao: '',
        maxDate: new Date(),
        horaInicio: null,
        horaFim: null,
        horaInicioInput: "",
        horaFimInput: "",
      })
      document.getElementById("checkLicenca").disabled = false
      document.getElementById("checkHorarioAlmoco").disabled = false
      if (this.state.edit) {
        document.getElementById("checkHorarioAlmoco").disabled = true;
      }
    }
  }

  changeFerias(e) {
    let dias = e.target.value.replace(/\D/g, '');

    if (dias.length === 0) {
      this.setState({
        diasFerias: '',
      })
    }
    else if (!isNaN(dias)) {
      dias = parseInt(dias);
      if (dias > 30) dias = 30;
      else if (dias < 1) dias = 1;
      this.setState({
        diasFerias: dias,
      })
    }
  }

  changeAlmoco = async e => {

    await this.setState({ horarioAlmoco: !this.state.horarioAlmoco || this.state.horarioAlmoco === 1 ? true : false })
    if (!this.state.horarioAlmoco) {

      if (!this.state.edit) {
        await this.setState({ horaInicioInput: "", horaFimInput: "", horarioInicioAlmoco: "", horarioFimAlmoco: "" })
        document.getElementById("checkLicenca").disabled = false;
        document.getElementById("checkFerias").disabled = false;
      }
    }

    else {
      await this.setState({ horarioInicioAlmoco: "", horarioFimAlmoco: "" })
      document.getElementById("checkLicenca").disabled = true;
      document.getElementById("checkFerias").disabled = true;
    }
  }

  tempoDiff = (comeco, fim) => {
    let a = moment(comeco);
    let b = moment(fim);
    let c = b.diff(a, 'seconds');

    function customTemplate() {
      return this.duration.asSeconds() >= 86400 ? "w [weeks], d [days]" : "hh:mm";
    }

    let d = moment.duration(c, 'seconds').format(customTemplate, {
      trim: false
    });

    this.setState({ esforco: d })
  }

  verificaSeAlmoco(dadosApontamento, sair, ferias, almoco) {
    if (almoco) {
      this.salvarApontamentoAlmoco(sair);
    }
    else {
      this.salvarApontamento(dadosApontamento, sair, ferias);
    }
  }

  checkLicenca = () => {
    this.setState({ licenca: this.state.licenca === 0 ? 1 : 0 })

    if (this.state.licenca === 0) {
      this.setState({ descricao: "Atestado:", ferias: false })
      document.getElementById("checkFerias").disabled = true;
      document.getElementById("checkHorarioAlmoco").disabled = true;

      if (this.state.edit) {
        document.getElementById("checkHorarioAlmoco").disabled = true;
      }
    }

    else {
      this.setState({ descricao: "" })
      document.getElementById("checkFerias").disabled = false;
      document.getElementById("checkHorarioAlmoco").disabled = false;

      if (this.state.edit) {
        document.getElementById("checkHorarioAlmoco").disabled = true;
      }
    }
  }

  validacao = () => {

    const {
      nomeProjeto,
      horaInicio,
      horaFim,
      descricao,
      ferias,
      diasFerias,
      dataEntrada
    } = this.state;

    let a = moment(horaInicio);
    let b = moment(horaFim);
    let c = b.diff(a, 'seconds');

    let nomeProjetoMensagem = "";
    let horaInicioMensagem = "";
    let horaFimMensagem = "";
    let descricaoMensagem = "";
    let tempoDiffMensagemInicio = "";
    let tempoDiffMensagemFim = "";
    let escolhaMensagem = "";
    let feriasMensagem = "";

    if (dataEntrada < new Date('2019-09-16T00:00')) {

    }

    if (nomeProjeto === "Escolha...") {
      escolhaMensagem = "Todos os campos obrigatórios devem ser preenchidos!";
    }

    if (nomeProjeto === "") {
      nomeProjetoMensagem = "Todos os campos obrigatórios devem ser preenchidos!";
    }

    if ((horaInicio === null) || (horaInicio === "")) {
      horaInicioMensagem = "Todos os campos obrigatórios devem ser preenchidos!";
    }

    if ((horaFim === null) || (horaFim === "")) {
      horaFimMensagem = "Todos os campos obrigatórios devem ser preenchidos!";
    }

    if (descricao === "") {
      descricaoMensagem = "Todos os campos obrigatórios devem ser preenchidos!";
    }

    if (c <= 0) {
      tempoDiffMensagemInicio = "Hora Início deve ser menor do que Hora Fim"
      tempoDiffMensagemFim = "Hora Fim deve ser maior do que Hora Início";
    }

    if (ferias && diasFerias === "") {
      feriasMensagem = "Todos os campos obrigatórios devem ser preenchidos!";
    }

    if (feriasMensagem) {
      this.setState({ feriasErro: feriasMensagem })
    }

    else {
      this.setState({ feriasErro: "" })
    }


    if (escolhaMensagem) {
      this.setState({ escolhaErro: escolhaMensagem })
    }

    else {
      this.setState({ escolhaErro: "" })
    }

    if (nomeProjetoMensagem) {
      this.setState({ nomeProjetoErro: nomeProjetoMensagem })
    }

    else {
      this.setState({ nomeProjetoErro: "" })
    }

    if (horaInicioMensagem) {
      if (this.state.horarioAlmoco) {
        this.setState({ horaInicioErro: "" })
      }

      else {
        this.setState({ horaInicioErro: horaInicioMensagem })
      }

    }

    else {
      this.setState({ horaInicioErro: "" })
    }

    if (horaFimMensagem) {
      if (this.state.horarioAlmoco) {
        this.setState({ horaFimErro: "" })
      }

      else {
        this.setState({ horaFimErro: horaFimMensagem })
      }
    }

    else {
      this.setState({ horaFimErro: "" })
    }

    if (descricaoMensagem) {
      this.setState({ descricaoErro: descricaoMensagem })
    }

    else {
      this.setState({ descricaoErro: "" })
    }

    if (tempoDiffMensagemInicio) {
      this.setState({
        diffErroInicio: tempoDiffMensagemInicio,
        diffErroFim: tempoDiffMensagemFim,
      })
    }

    else {
      this.setState({ diffErroFim: "", diffErroInicio: "" })
    }
  }


  pad(number, length) {
    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

  limpaErros = () => {
    this.setState({
      diffErroInicio: "",
      diffErroFim: "",
      descricaoErro: "",
      horaFimErro: "",
      horaInicioErro: "",
      nomeProjetoErro: "",
      escolhaErro: "",
      feriasErro: "",
      overlapErro: "",
      errors: []
    })
  }

  limpaState(entradaTambem) {
    this.setState({
      horaInicio: "",
      horaFim: "",
      descricao: "",
      nomeProjeto: "",
      nomeProjetoErro: "",
      horaFimErro: "",
      descricaoErro: "",
      diffErroInicio: "",
      diffErroFim: "",
      escolhaErro: "",
      overlapErro: "",
      horaInicioInput: "",
      horaFimInput: "",
      ferias: false,
      diasFerias: 1,
      licenca: 0,
      idSubprojeto: null,
      id_projeto_comercial: "",
      nomeSubprojeto: "",
      horaInicioErro: "",
      horarioAlmoco: false,
      horarioInicioAlmoco: "",
      horarioFimAlmoco: "",
      errors: [],
      horaAlmocoErro: "",
      horaAlmocoErro2: "",
      horaFimEdit: "",
      horaInicioEdit: "",
    })

    document.getElementById("checkFerias").disabled = false;
    document.getElementById("checkLicenca").disabled = false;
    document.getElementById("checkHorarioAlmoco").disabled = false;


    if (entradaTambem) {
      this.setState({
        dataEntrada: new Date(),
      })
    }
  }

  verificaToleranciaHoras = async (dadosApontamento, sair, ferias, almoco) => {
    await axios.post(Backend('verifica_tolerancia_horas'), dadosApontamento, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      if (!response.data) {
        Swal.fire({
          title: "Atenção",
          text: "O intervalo entre um apontamento e outro é menor que 6 minutos, deseja arredondar o registro?",
          type: "warning",
          showCancelButton: true,
          showCloseButton: true,
          reverseButtons: true,
          confirmButtonColor: '#720425',
          cancelButtonColor: '#3085d6',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim',
        }).then(async (result) => {
          if (result.value) {
            await axios.post(Backend('arredondamento_apontamento'), dadosApontamento, {
              headers: { 'Content-Type': 'application/json' }
            }).then((response) => {
              dadosApontamento.horaInicio = response.data.entrada;
              dadosApontamento.horaFim = response.data.saida;
              this.setState({
                horaInicioInput: response.data.entrada,
                horaFimInput: response.data.saida,
                horaInicioEdit: response.data.entrada,
                horaFimEdit: response.data.saida
              }, () => {
                this.verificaSeAlmoco(dadosApontamento, sair, ferias, almoco)
              })
            })
          } else {
            this.setState({
              salvando: false
            });
          }
        });
      } else {
        this.verificaSeAlmoco(dadosApontamento, sair, ferias, almoco)
      }
    });
  }

  chamaMsgNok = () => {
    toast.error(`Apontamento não salvo!`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  chamaMsgOk = () => {
    toast.success(`Apontamento salvo!`, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  salvarApontamento = async (dadosApontamento, sair, ferias) => {

    const horaReprovada = "Já existe um registro de horas reportado nesse mesmo período. Por favor, verificar!";
    const dataFutura = "Não é possivel apontar uma data/hora futura. Por favor, verificar!";
    const peridoFechado = 'Período fechado para lançamento!';
    const apontSalvo = 'Successo';
    const apontSalvoNoc = 'Successo_NOC';
    if (this.state.edit) {

      const aceitaApontamento = await this.verificaHorasDisponiveis({ dadosApontamento, almoco: false, edit: true });
      if (aceitaApontamento) {
        axios.post(Backend('update_timesheet'), dadosApontamento, {
          headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
          if (response.data === dataFutura) {
            this.setState({ overlapErro: dataFutura });
          }
          else if (response.data === horaReprovada) {
            this.setState({ overlapErro: horaReprovada });
          }
          else if (response.data === peridoFechado) {
            this.setState({ overlapErro: peridoFechado });
          }
          else if (response.data === apontSalvo || response.data === apontSalvoNoc) {
            this.limpaState(true);
            if (sair) {
              this.handleClose();
            }
            this.chamaMsgOk();
          } else {
            this.chamaMsgNok();
          }
          this.setState({
            salvando: false
          });
        }).catch(err => {
          console.log(err)
          this.chamaMsgNok();
          this.setState({
            salvando: false
          });
        });
      } else {
        this.alertDisponibilidadeHoras();
        this.chamaMsgNok();
      }

    }

    else {
      const aceitaApontamento = await this.verificaHorasDisponiveis({ dadosApontamento, almoco: false, edit: false })
      if (aceitaApontamento) {
        if (ferias) {
          axios.post(Backend('apontamento_ferias'), dadosApontamento, {
            headers: { 'Content-Type': 'application/json' }
          }).then((response) => {
            if (response.data === horaReprovada) {
              this.setState({ overlapErro: horaReprovada });
            }
            else if (response.data === peridoFechado) {
              this.setState({ overlapErro: peridoFechado });
            }
            else if (response.data === apontSalvo || response.data === apontSalvoNoc) {
              this.chamaMsgOk();
              this.limpaState(true);
              if (sair) {
                this.handleClose();
              }
            }
            else {
              this.chamaMsgNok();
            }
            this.setState({
              salvando: false
            });
          }).catch(err => {
            console.log(err)
            this.chamaMsgNok();
            this.setState({
              salvando: false
            });
          })
        }
        else {
          axios.post(Backend('post'), dadosApontamento, {
            headers: { 'Content-Type': 'application/json' }
          }).then((response) => {
            if (response.data === dataFutura) {
              this.setState({ overlapErro: dataFutura });
            }
            else if (response.data === horaReprovada) {
              this.setState({ overlapErro: horaReprovada });
            }
            else if (response.data === peridoFechado) {
              this.setState({ overlapErro: peridoFechado });
            }
            else if (response.data === apontSalvo || response.data === apontSalvoNoc) {
              if (response.data === "Successo" && this.state.user.noc === 1 && this.state.horaInicioInput === "18:00" && this.state.horaFimInput === "23:59") {
                Swal.fire({
                  title: "Aviso",
                  text: "Apontamento ajustado para 17:59hs até 23:59hs.",
                  confirmButtonColor: "#720425"
                })
              }
              this.chamaMsgOk();

              this.limpaState(false);
              if (sair) {
                this.handleClose();
              }
            }
            else {
              this.chamaMsgNok();
            }
            this.setState({
              salvando: false
            });
          }).catch(err => {
            console.log(err)
            this.chamaMsgNok();
            this.setState({
              salvando: false
            });
          });
        }
      } else {
        this.alertDisponibilidadeHoras();
        this.chamaMsgNok();
      }
    }
  }

  alertDisponibilidadeHoras = () => Swal.fire({
    title: "Atenção",
    text: "Total de horas apontadas ultrapassou o limite permitido para o projeto. Por favor, entrar em contato com o gestor do projeto.",
    type: "warning",
    showCloseButton: true,
    confirmButtonColor: '#720425',
    confirmButtonText: 'Ok',
  }).then(async (result) => {
    this.setState({
      salvando: false
    });
  });

  verificaHorasDisponiveis = async ({ apontamentoAlmoco, dadosApontamento, almoco, edit }) => {

    let dados = almoco ? apontamentoAlmoco : dadosApontamento
    dados.almoco = almoco;
    dados.edit = edit;

    const retorno = await axios.post(Backend('verifica_disponibilidade_horas_projeto'), dados, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      return response.data.status;
    }).catch(error => {
      console.log(error.response)
      return false;
    });
    return retorno;
  }


  verificarHoras = async (sair, almoco) => {
    if (!this.state.salvando) {
      this.setState({
        salvando: true
      });
      await this.changeHora();
      this.limpaErros();
      const {
        horaInicioInput, horaFimInput, horaInicio,
        horaFim, descricao, nomeProjeto,
        // esforco, dataInsercao,
        dataEntrada, ferias, diasFerias } = this.state;
      if (!nomeProjeto || !horaInicio || !horaFim || !descricao) {
        this.validacao();
        this.setState({
          salvando: false
        })
        return false;
      } else if (dataEntrada < new Date('2019-09-16T00:00')) {
        this.setState({ overlapErro: 'Não é possivel apontar uma data anterior a 16/09/2019.' });
        return false;
      }
      else {
        let a = moment(horaInicio);
        let b = moment(horaFim);
        let c = b.diff(a, 'seconds');
        if ((c <= 0)) {
          this.validacao();
          this.setState({
            salvando: false
          })
          return false;
        }
        let hour = horaInicio.getHours();
        let min = horaInicio.getMinutes();
        let day2 = dataEntrada.getDate();
        let month = dataEntrada.getMonth() < 12 ? (dataEntrada.getMonth() + 1) : dataEntrada.getMonth();
        let year = dataEntrada.getFullYear();
        let month2 = month < 10 ? `0${month}` : month;
        let formatIns = `${year}-${month2}-${day2} ${hour}:${min}`;
        let hrIni = horaInicioInput;
        let hrFim = horaFimInput;
        let data_apontamento = dataEntrada.getFullYear() + '-' + this.pad((dataEntrada.getMonth() + 1), 2) + '-' + this.pad(dataEntrada.getDate(), 2);

        const dadosApontamento = {
          "dataApontamento": data_apontamento,
          "dataInsercao": formatIns,
          "descricao": this.state.descricao,
          "horaInicio": hrIni,
          "horaFim": hrFim,
          "status": this.state.status,
          "nomeProjeto": this.state.nomeProjeto,
          "idColaborador": this.state.user.id_recurso,
          "diasFerias": diasFerias,
          "noc": this.state.user.noc,
          "idTimesheet": this.state.id_timesheet,
          "licenca": this.state.licenca,
        }
        this.verificaFeriadoFds(dadosApontamento, sair, ferias, almoco);
      }
    }
  }

  verificaFeriadoFds = async (dadosApontamento, sair, ferias, almoco) => {
    await axios.post(Backend('verifica_feriado_fds'), dadosApontamento, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {

      if (response.data.status === 4) {
        Swal.fire({
          title: "Atenção",
          text: "Este apontamento está fora do período permitido para registro de horas trabalhadas. Deseja realmente realizar este apontamento?",
          type: "warning",
          showCancelButton: true,
          showCloseButton: true,
          reverseButtons: true,
          confirmButtonColor: '#720425',
          cancelButtonColor: '#3085d6',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim',
        }).then(async (result) => {
          if (result.value) {
            this.verificaToleranciaHoras(dadosApontamento, sair, ferias, almoco);
          } else {
            this.setState({
              salvando: false
            });
          }
        });
      } else if (response.data.status === 3) {
        Swal.fire({
          title: "Atenção",
          text: "O dia do apontamento é fim de semana ou feriado na sua cidade. Deseja realmente realizar este apontamento?",
          type: "warning",
          showCancelButton: true,
          showCloseButton: true,
          reverseButtons: true,
          confirmButtonColor: '#720425',
          cancelButtonColor: '#3085d6',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim',
        }).then(async (result) => {
          if (result.value) {
            this.verificaToleranciaHoras(dadosApontamento, sair, ferias, almoco);
          } else {
            this.setState({
              salvando: false
            });
          }
        });
      } else if (response.data.status === 2) {
        Swal.fire({
          title: "Atenção",
          // text: "Você não tem permissão para fazer esse apontamento. Contate o seu gestor.",
          text: response.data.message,
          type: "warning",
          showCloseButton: true,
          confirmButtonColor: '#720425',
          confirmButtonText: 'Ok',
        }).then(() => {
          this.setState({
            salvando: false
          });
        });
      } else if (response.data.status === 1) {
        this.verificaToleranciaHoras(dadosApontamento, sair, ferias, almoco);
      }
    });
  }



  render() {

    return (
      <section>
        {
          !this.state.carregando ?

            <Modal
              aria-labelledby="modal-title"
              dialogClassName="modal-apontamento-detalhado-projeto"
              size="lg"
              show={this.state.show}
              onHide={this.handleClose}
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title id="modal-title" className="h3 font-weight-bold">
                  Apontamentos
                        </Modal.Title>
              </Modal.Header>
              <Modal.Body className="ml-3 mr-3">
                <p className="h4 font-weight-bold">
                  <button onClick={() => this.changeAba('workgroup')} className={`link-aba aba-esquerda ${this.state.aba === 'workgroup' ? 'aba-active' : ''}`}>Registro de Trabalho</button>
                  |
                  <button onClick={() => this.changeAba('folga')} className={`link-aba aba-direita ${this.state.aba === 'folga' ? 'aba-active' : ''}`}>Registro de Ausência</button>
                </p>
                {
                  this.state.aba === "folga" ?
                    <AbaFolga
                      user={this.state.user}
                      maxDate={this.state.maxDate}
                      close={this.handleClose}
                    />
                    :
                    <section>
                      <div className="row">
                        <div className="col-md-6 col-sm-7">
                          <div className="form-group">
                            <div className="input-group input-group-sm">
                              <label className="text15" htmlFor="subprojeto"><span className="asteric">*</span>Projetos</label>
                              <select id="subprojeto" as="select" className="form-control icon-fa" name="nomeProjeto" value={this.state.nomeProjeto} onChange={this.onChange}>
                                <option value=""></option>
                                {this.state.projetoList.map((pl, i) => {
                                  if (pl.vezes > 0) return (
                                    <option key={i} name="idSubprojeto" value={pl.id_subprojeto} onChange={this.onChange2}>
                                      &#xf006;&nbsp;{pl.sidpc}{"    "}{pl.subprojeto}
                                    </option>
                                  )
                                  else return (
                                    <option key={i} name="idSubprojeto" value={pl.id_subprojeto} onChange={this.onChange2}>
                                      &emsp;{pl.sidpc}{"    "}{pl.subprojeto}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                            <p className="erro">{this.state.nomeProjetoErro}</p>
                            <p className="erro">{this.state.escolhaErro}</p>
                            <p className="erro">{this.state.errors.idSubprojeto}</p>
                          </div>
                        </div>
                        <div className="checkboxes">
                          <div className="form-check">
                            {this.state.ferias ?
                              <input onClick={this.checkFerias} type="checkbox" className="form-check-input" id="checkFerias" checked />
                              :
                              <input onClick={this.checkFerias} type="checkbox" className="form-check-input" id="checkFerias" />
                            }
                            <label className="text15" htmlFor="checkFerias"> Férias</label>
                          </div>

                          <div className="form-check">
                            {this.state.licenca === 0 ?
                              <input onClick={this.checkLicenca} type="checkbox" className="form-check-input" id="checkLicenca" />
                              :
                              <input onClick={this.checkLicenca} type="checkbox" className="form-check-input" id="checkLicenca" checked />
                            }
                            <label className="text15" htmlFor="checkLicenca"> Atestado</label>
                          </div>

                          <div className="form-check">
                            {!this.state.horarioAlmoco || this.state.edit ?
                              <input onChange={this.changeAlmoco} type="checkbox" className="form-check-input" id="checkHorarioAlmoco" />
                              :
                              <input onChange={this.changeAlmoco} type="checkbox" className="form-check-input" id="checkHorarioAlmoco" checked />
                            }
                            <label className="text15" htmlFor="checkHorarioAlmoco"> Horário Almoço</label>
                          </div>
                        </div>

                        {this.state.ferias ?
                          <div className="col-md-3 col-sm-3">
                            <label className="form-check-label class003 text15" htmlFor="diasFerias"> Dias</label>
                            <input value={this.state.diasFerias} onChange={this.changeFerias} className="input-ferias form-control" id="diasFerias" />
                            <p className="erro">{this.state.feriasErro}</p>
                          </div>
                          : null
                        }
                      </div>

                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="text15" htmlFor="dataApontamento"><span className="asteric">*</span>Data</label>
                            <div className="input-group input-group-sm">
                              <DatePicker
                                selected={this.state.dataEntrada}
                                onChange={(e) => this.changedataEntrada(e)}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                minDate={new Date('2019-09-16T00:00')}
                                maxDate={this.state.maxDate}
                                locale="pt-BR"
                                id="dataApontamento"
                                todayButton={"Hoje"}
                              />
                              <p className="erro">{this.state.overlapErro}</p>
                              <p className="erro">{this.state.errors.check1}</p>
                              <p className="erro">{this.state.errors.check2}</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="text15" htmlFor="inicioModal"><span id="horaI" className="asteric">*</span>Hora Início</label>
                            <div className="input-group input-group-sm">
                              {
                                this.state.ferias ?
                                  <input
                                    type="text"
                                    className="form-control input-mask"
                                    value={this.state.horaInicioInput}
                                  />
                                  :
                                  <InputMask
                                    type="text"
                                    maskChar={null}
                                    className="form-control input-mask"
                                    mask="99:99"
                                    value={this.state.horaInicioInput}
                                    onChange={this.changeHoraInicio}
                                  />
                              }
                            </div>
                            <p className="erro">{this.state.horaInicioErro}</p>
                            <p className="erro">{this.state.diffErroInicio}</p>
                            <p className="erro">{this.state.errors.horaInicio}</p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="text15" htmlFor="fimModal"><span className="asteric">*</span>Hora Fim</label>
                            <div className="input-group input-group-sm">
                              {
                                this.state.ferias ?
                                  <input
                                    type="text"
                                    className="form-control input-mask"
                                    value={this.state.horaFimInput}
                                  />
                                  :
                                  <InputMask
                                    type="text"
                                    maskChar={null}
                                    className="form-control input-mask"
                                    mask="99:99"
                                    id="horaFim_Input"
                                    value={this.state.horaFimInput}
                                    onChange={this.changeHoraFim}
                                  />
                              }
                            </div>
                            <p className="erro">{this.state.horaFimErro}</p>
                            <p className="erro">{this.state.diffErroFim}</p>
                            <p className="erro">{this.state.errors.horaFim}</p>
                          </div>
                        </div>
                      </div>

                      {this.state.horarioAlmoco && !this.state.edit ?
                        <div>
                          <div className="row" id="horaAlmocoDiv">
                            <div className="col-md-3 hideme">
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="text15" htmlFor="inicioModal"><span id="horaI" className="asteric">*</span>Hora Início Almoço</label>
                                <div className="input-group input-group-sm">
                                  <InputMask
                                    type="text"
                                    maskChar={null}
                                    className="form-control input-mask"
                                    mask="99:99"
                                    id="horaInicioAlmoco_Input"
                                    value={this.state.horarioInicioAlmoco}
                                    onChange={this.changeHoraInicioAlmoco}
                                  />
                                  <p className="erro">{this.state.errors.almoco1}</p>
                                  <p className="erro">{this.state.horaAlmocoErro}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label className="text15" htmlFor="fimModal"><span className="asteric">*</span>Hora Fim Almoço</label>
                                <div className="input-group input-group-sm">
                                  <InputMask
                                    type="text"
                                    maskChar={null}
                                    className="form-control input-mask"
                                    mask="99:99"
                                    id="horaFimAlmoco_Input"
                                    value={this.state.horarioFimAlmoco}
                                    onChange={this.changeHoraFimAlmoco}
                                  />
                                  <p className="erro">{this.state.errors.almoco2}</p>
                                  <p className="erro">{this.state.horaAlmocoErro2}</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3 hideme">
                            </div>
                            <p className="erro">{this.state.errors.almoco}</p>

                          </div>
                        </div> : null
                      }

                      <div className="row">
                        <div className="col">
                          <div className="form-group" style={{ marginLeft: "15px" }}>
                            <label className="text15" htmlFor="descricao"><span className="asteric">*</span>Descrição</label>
                            <div className="input-group">
                              <textarea id="descricao" name="descricao" rows="3" cols="100" className="form-control" aria-label="With textarea" onChange={this.onChange} value={this.state.descricao}></textarea>
                              <p className="erro">{this.state.errors.descricao}</p>
                            </div>
                            <p className="erro">{this.state.descricaoErro}</p>
                          </div>
                        </div>
                      </div>
                      <button onClick={this.state.horarioAlmoco && !this.state.edit ? () => this.verificarHoras(false, true) : () => this.verificarHoras(false, false, false)} className="primary-color btn btn-sm btn-modal btn-modal-apontamento-detalado-projeto">Salvar e continuar</button>
                      <button onClick={this.state.horarioAlmoco && !this.state.edit ? () => this.verificarHoras(true, true) : () => this.verificarHoras(true, false, false)} className="primary-color btn btn-sm btn-modal btn-modal-apontamento-detalado-projeto">Salvar e sair</button>
                      <button type="button" className="btn btn-warning btn-sm btn-modal-apontamento-detalado-projeto" onClick={this.handleClose}>Cancelar</button>

                    </section>
                }

              </Modal.Body>
            </Modal>
            :
            null
        }
      </section>
    );
  }
}

export default ModalComponente;