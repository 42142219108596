import { SEND_COLABS, SEND_COLABS_FILTEREDS } from '../actions/actionType';

const initState = {
    colaboradores: [],
    colaboradoresFiltrados: []
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        case SEND_COLABS:
            return {
                ...state,
                colaboradores: action.colaboradores
            }
        case SEND_COLABS_FILTEREDS:
            return {
                ...state,
                colaboradoresFiltrados: action.colaboradoresFiltrados
            }
        default:
            return state;
    }
}

export default reducer;