import React from 'react';

const Thead = () => {

    return (
        <thead>
            <tr>
                <th className="th-black text-center">Funcionário</th>
                <th className="th-black text-center">Apontamento</th>
            </tr>
        </thead>
    )
}

export default Thead;