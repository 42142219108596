import { ADD_ID_PROJETO, REMOVE_ID_PROJETO } from './actionType';

export const addIdProjectEditHours = (id) => {

    return {
        type: ADD_ID_PROJETO,
        payload: {
            id
        }
    }
}

export const removeIdProjectEditHours = () => {

    return {
        type: REMOVE_ID_PROJETO,
        payload: {
            id: null
        }
    }
}