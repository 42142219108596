import React from 'react';

const THeadApontamentos = () => {
    return (
        <>
            <thead>
                <tr>
                    <th className="th-black text-center col-md-2">Data</th>
                    <th className="th-black text-center col-md-1">Hora inicial</th>
                    <th className="th-black text-center col-md-1">Hora final</th>
                    <th className="th-black text-center col-md-4">Projeto</th>
                    <th className="th-black text-center col-md-3">Descrição</th>
                    <th className="th-black text-center col-md-1">Ação</th>
                </tr>
            </thead>
        </>
    )
}

export default THeadApontamentos;