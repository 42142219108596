import React from 'react';
import './table.css'
import axios from 'axios';
import Backend from '../../../utils/backend';
import Swal from 'sweetalert2';
// import Swal from 'sweetalert2'
// import ReactTooltip from 'react-tooltip'


const TBody = (props) => {

    const downloadFile = (caminho, tipo) => {
        const dadosDownload = { caminho, tipo };
        axios.post(Backend(`download`), dadosDownload, {
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            if(response.data === false){
                Swal.fire({
                    title:"Erro ao baixar o arquivo",
                    text: "O arquivo solicitado para download não se encontra alocado no servidor. Informe o suporte.",
                    type: "error", confirmButtonColor: '#720425',
                });
            }else{
                const url = window.URL.createObjectURL(new Blob(["\ufeff", response.data]));
                const link = document.createElement('a');
                link.href = url;
                const fileName = caminho
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();// you need to remove that elelment which is created before
            }
        });
    }

    const { relatorios } = props

    return (
        <tbody className="text-center">

            {
                relatorios.map( (element, i) => {
                    return (
                        <tr key={i}>
                            <td>{element.data_criacao}</td>
                            <td>{element.solicitante}</td>
                            <td>{element.data_inicial}</td>
                            <td>{element.data_final}</td>
                            <td>{element.colaborador}</td>
                            <td>{element.centro_custo}</td>
                            <td>{element.empresa}</td>
                            <td>
                                {
                                    element.tipo.map((tipo, i) => {
                                        let tipoArquivo = null;
                                        tipo === 2 ? tipoArquivo = 'txt' : 
                                        tipo === 3 ? tipoArquivo = 'csv1' : 
                                        tipoArquivo = 'csv2';
                                        return(
                                            <div key={i} onClick={() => downloadFile(element.caminho[i], tipoArquivo)}>
                                                {
                                                    <button className={`button-retangulo ${tipoArquivo}`}></button>
                                                }
                                            </div>
                                        )
                                    })
                                    
                                }
                            </td>
                        </tr>
                    )
                })
            }
            
        </tbody>
    )
}

export default TBody;