/* Serviço axios de autenticação via API */
/* Criado por Carlos Eduardo em 04/01/2019 */
/* Em caso de dúvidas: contato.carlos@outlook.com */

import axios from "axios";
import { getToken } from "./auth";
import Config from '../config';

const authAPI = axios.create({
  baseURL: Config('AuthURL') //Porta que roda o server de autenticação
});

authAPI.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default authAPI;
