import React, { Component } from 'react';
import Thead from './Thead';
import Tbody from './Tbody';
import { getToken } from '../../../auth/auth';
import jwt_decode from 'jwt-decode';
import './Table.css';

export default class Table extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: jwt_decode(getToken()),
            projetos: this.props.projetos,
            filterProjetos: this.props.projetos.filter((e, i) => i < 20),
            filtro: '',
            qtdFiltro: 20
        }
    }

    changeSearch = async (e, more, clear) => {
        let filtro;
        if (clear) filtro = ''
        else if (more) filtro = this.state.filtro || '';
        else filtro = e.target.value.toLowerCase();
        const filtered = this.state.projetos.filter(e => {
            return e.id_projeto_comercial.toLowerCase().includes(filtro) ||
                e.subprojeto.toLowerCase().includes(filtro) ||
                e.aprovador.toLowerCase().includes(filtro) ||
                (e.horas && e.horas.toString().includes(filtro)) ||
                (`${e.gastas.hours ? e.gastas.hours.toString() : '00'}:${e.gastas.minutes ? e.gastas.minutes.toString() : '00'}`.includes(filtro))
        })

        this.setState({
            filterProjetos: filtered.filter((e, i) => i < this.state.qtdFiltro),
            // qtdFiltro: 20,
            filtro,
        })
    }

    carregarTabela = () => {
        let qtd = this.state.qtdFiltro + 20;
        this.setState({ qtdFiltro: qtd }, () => this.changeSearch(null, true))
    }

    render() {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-12 pesquisar-div">
                        <input
                            placeholder="Pesquisar"
                            type="text"
                            onChange={this.changeSearch}
                            id="filtro-nome"
                            value={this.state.filtro}
                            className="form-control input-search" />
                        {
                            this.state.filtro !== '' ?
                                <div onClick={() => this.changeSearch(null, false, true)}>
                                    <i className="fa fa-times zoom-15" aria-hidden="true"></i>
                                </div> :
                                <i className="fa fa-search zoom-15" aria-hidden="true"></i>
                        }
                    </div>
                </div>
                <div>
                    <div className="table-responsive col-md-11" style={{ marginLeft: '-15px' }}>
                        <table
                            className="table"
                            cellSpacing="0" cellPadding="0" id="lista">
                            <Thead />
                            <Tbody
                                projetos={this.state.filterProjetos}
                                id_aprovador={this.state.user.id_recurso}
                            />
                        </table>
                        <div className="load-more-prj">
                            <button className="btn btn-carregar"
                                onClick={this.carregarTabela}
                                disabled={this.state.qtdFiltro > this.state.filterProjetos.length}
                            >
                                Carregar Mais
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}