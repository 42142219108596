import React from 'react';
import THead from './thead';
import TBody from './tbody';
import './table.css'

const Table = (props) => {

    const { relatorios, loading } = props
    
    return (
        <div>
            <div className="row mb-3">
                <div className="group">
                    <div className="retangulo_ txt"><span className="legenda_" >Apontamentos dos colaboradores Entrada/Saída</span></div>
                    <br />
                    {/* <div className="retangulo_ csv1"> <span className="legenda_">Horas Gastas por Projeto</span></div>
                    <br /> */}
                    <div className="retangulo_ csv2"> <span className="legenda_">% Gastas por Projeto</span></div>
                </div>
            </div>

            <div className="table-responsive col-md-11" style={{marginLeft:'-15px'}}>
                <table className="table table-bordered" cellSpacing="0" cellPadding="0">
                        <THead/>
                        { !loading ?  
                        <TBody 
                            relatorios={relatorios}/> 
                        : null} 
                </table>
            </div>
        </div>
        
    )
}
export default Table;