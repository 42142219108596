
import React, { Component } from 'react';

import Backend from '../../../utils/backend';
import axios from 'axios';

import { bindActionCreators } from 'redux';
import * as editProjectActions from '../../../store/actions/editHoraProjeto';
import * as sendProjColActions from '../../../store/actions/sendProjCol';
import { connect } from 'react-redux';

import { getToken, isAuthenticated } from '../../../auth/auth';
import jwt_decode from 'jwt-decode';

import Title from '../../../template/title';
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';

import Table from './tableColabComercial/Table';

import "./editarHoraProjeto.css";

class EditarHorasProjeto extends Component {

    constructor(props) {
        super(props);
        if (!isAuthenticated()) window.location.href = "";
        if (props.editHoraProjeto.idProjeto === null)
            window.location.href = "#/gerenciamento-horas";
        this.state = {
            user: jwt_decode(getToken()),
            idProjeto: props.editHoraProjeto.idProjeto,
            originalColabs: [],
            loading: false,
            projeto: {},
            horasIniciais: null,
            descricao: '',
        };

        this.handleAlert = this.handleAlert.bind(this)
    }

    componentDidMount = () => {
        if (this.state.idProjeto === null)
            window.location.href = "#/gerenciamento-horas";
        else {
            this.setState({ loading: true }, this.selectProjHora());
        }
    }

    componentWillUnmount = () => {
        this.props.removeIdProjectEditHours();
        this.props.resetColabs();
    }

    selectProjHora = () => {
        // console.log(this.state)
        axios.post(Backend('select_projeto_hora'), { id: this.state.idProjeto }, {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            this.setState({
                projeto: response.data.projeto,
                horasIniciais: response.data.projeto.horas
            }, () => {
                this.selectColabHora(true)
                this.selectColabHora(false)
            })
        })
    }

    selectColabHora = (store) => {
        axios.post(Backend('select_colab_hora'), { idProjeto: this.state.idProjeto }, {
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            if (store) this.props.sendColaboratorsToStore(response.data.colaboradores)
            else
                this.setState({
                    loading: false,
                    originalColabs: response.data.colaboradores
                })
        })
    }

    comparar = (a, b) => {
        return a.recurso < b.recurso ?
            -1 :
            a.recurso > b.recurso ?
                1 :
                0
    }

    changeHours = e => {
        const horas = e.target.value;

        this.setState(prevState => ({
            ...prevState,
            projeto: {
                ...prevState.projeto,
                horas
            }
        }))
    }

    changeDescription = e => {
        const descricao = e.target.value;
        this.setState({ descricao })
    }

    backPage = () => {
        const link = document.createElement('a');
        link.href = '#/gerenciamento-horas';
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    saveEdit = () => {

        const alterouFuncionario = this.props.colaboradores.some(e => e.status)
        const alterouHoras = this.state.projeto.horas !== this.state.horasIniciais;
        const alterouMotivo = this.state.descricao !== '';
        // console.log("alterouFuncionario", alterouFuncionario)
        // console.log(alterouFuncionario, alterouHoras, alterouMotivo)
        if (!alterouFuncionario) {
            if (!alterouHoras && !alterouMotivo) {
                this.handleAlert({
                    title: "Atenção",
                    text: "Voce não fez nenhuma alteração.",
                    type: "warning"
                })
            } else if (!alterouHoras) {
                this.handleAlert({
                    title: "Atenção",
                    text: "Preencha a quantidade de horas do projeto.",
                    type: "warning"
                })
            } else if (!alterouMotivo) {
                this.handleAlert({
                    title: "Atenção",
                    text: "Preencha o motivo da alteração",
                    type: "warning"
                })
            } else {
                const obj = {
                    idProjeto: this.state.idProjeto,
                    descricao: this.state.descricao,
                    horas: this.state.projeto.horas,
                    aprovador: this.state.user.id_recurso,
                }
                const apiMudaHora = this.state.horasIniciais ?
                    'salvar_edicao_projeto_hora' :
                    'salvar_insercao_projeto_hora';
                axios.post(Backend(apiMudaHora), obj, {
                    headers: { 'Content-Type': 'application/json' }
                }).then((response) => {
                    const code = response.data.code;
                    if (code === 0) {
                        Swal.fire({
                            title: "Sucesso",
                            text: "Alteração realizada com sucesso.",
                            type: "success",
                            showCloseButton: true,
                            confirmButtonColor: '#8c1818',
                            confirmButtonText: 'Ok',
                        }).then(() => {
                            this.backPage();
                        });
                    } else if (code === 1) {
                        this.handleAlert({
                            title: "Aviso",
                            text: "Usuário sem permissão para realziar essa alteração.",
                            type: "warning"
                        })
                    }
                });
            }
        } else {
            if (alterouHoras && alterouMotivo) {
                const obj = {
                    idProjeto: this.state.idProjeto,
                    descricao: this.state.descricao,
                    horas: this.state.projeto.horas,
                    aprovador: this.state.user.id_recurso,
                }
                const apiMudaHora = this.state.horasIniciais ?
                    'salvar_edicao_projeto_hora' :
                    'salvar_insercao_projeto_hora';
                axios.post(Backend(apiMudaHora), obj, {
                    headers: { 'Content-Type': 'application/json' }
                }).then((response) => {
                    const code = response.data.code;
                    if (code === 0) {

                        const objFunc = {
                            idProjeto: this.state.idProjeto,
                            colaboradores: this.props.colaboradores.filter(e => e.status),
                            aprovador: this.state.user.id_recurso,
                        }

                        axios.post(Backend(`salvar_edicao_projeto_hora_colaborador`), objFunc, {
                            headers: { 'Content-Type': 'application/json' }
                        }).then((response2) => {
                            console.log(response2.data.code)
                            if (response2.data.status) {
                                Swal.fire({
                                    title: "Sucesso",
                                    text: "Alteração realizada com sucesso.",
                                    type: "success",
                                    showCloseButton: true,
                                    confirmButtonColor: '#8c1818',
                                    confirmButtonText: 'Ok',
                                }).then(() => {
                                    this.backPage();
                                })
                            } else if (response2.data.code === 1) {
                                this.handleAlert({
                                    title: "Aviso",
                                    text: "Um erro ocorreu.",
                                    type: "warning"
                                })
                            } else if (response2.data.code === 2) {
                                this.handleAlert({
                                    title: "Aviso",
                                    text: "O colaborador não pode ser desabilitado.",
                                    type: "warning"
                                })
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.handleAlert({
                                title: "Aviso",
                                text: "Um erro ocorreu.",
                                type: "warning"
                            })
                        })
                    } else if (code === 1) {
                        this.handleAlert({
                            title: "Aviso",
                            text: "Usuário sem permissão para realziar essa alteração.",
                            type: "warning"
                        })
                    }
                });

            } else if (!alterouHoras && !alterouMotivo) {
                const objFunc = {
                    idProjeto: this.state.idProjeto,
                    colaboradores: this.props.colaboradores.filter(e => e.status),
                    aprovador: this.state.user.id_recurso,
                }

                axios.post(Backend(`salvar_edicao_projeto_hora_colaborador`), objFunc, {
                    headers: { 'Content-Type': 'application/json' }
                }).then((response2) => {
                    if (response2.data.status) {
                        Swal.fire({
                            title: "Sucesso",
                            text: "Alteração realizada com sucesso.",
                            type: "success",
                            showCloseButton: true,
                            confirmButtonColor: '#8c1818',
                            confirmButtonText: 'Ok',
                        }).then(() => {
                            this.backPage();
                        })
                    } else if (response2.data.code === 1) {
                        this.handleAlert({
                            title: "Aviso",
                            text: "Um erro ocorreu.",
                            type: "warning"
                        })
                    } else if (response2.data.code === 2) {
                        this.handleAlert({
                            title: "Aviso",
                            text: "O colaborador não pode ser desabilitado.",
                            type: "warning"
                        })
                    }
                }).catch((error) => {
                    // handle error
                    console.log(error);
                    this.handleAlert({
                        title: "Aviso",
                        text: "Um erro ocorreu.",
                        type: "warning"
                    })
                })
            } else if (!alterouHoras) {
                this.handleAlert({
                    title: "Atenção",
                    text: "Preencha a quantidade de horas do projeto.",
                    type: "warning"
                })
            } else if (!alterouMotivo) {
                this.handleAlert({
                    title: "Atenção",
                    text: "Preencha o motivo da alteração",
                    type: "warning"
                })
            }
        }
    }

    handleAlert = ({ title, text, type }) => {
        Swal.fire({
            title,
            text,
            type,
            showCloseButton: true,
            confirmButtonColor: '#8c1818',
            confirmButtonText: 'Ok',
        })
    }
    render() {

        return (
            <React.Fragment>
                <div className="animated fadeInRight home">
                    <Title name="Editar horas do projeto" />
                    <div className="container">

                        <div className="row mt-5" >
                            <h2 className="col-md-11" style={{ fontWeight: 'bold' }}> Edição de Horas - {this.state.projeto.subprojeto} </h2>
                            <br />
                        </div>

                        {!this.state.loading ?
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label className="text15" htmlFor="horas_projeto">
                                                Horas do projeto
                                            </label>
                                            <div className="input-group input-group-sm">
                                                <InputMask
                                                    id="horas_projeto"
                                                    type="text"
                                                    maskChar={null}
                                                    className="form-control input-horas-projeto"
                                                    mask="9999999"
                                                    value={this.state.projeto.horas}
                                                    onChange={this.changeHours}
                                                />
                                                <p className="erro"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group" style={{ marginLeft: "15px" }}>
                                            <label className="text15" htmlFor="descricao_alteracao">
                                                Motivo da alteração de Horas do projeto
                                            </label>
                                            <div className="input-group">
                                                <textarea
                                                    id="descricao_alteracao"
                                                    name="descricao_alteracao"
                                                    rows="5"
                                                    cols="60"
                                                    className="form-control descricao_alteracao"
                                                    onChange={this.changeDescription}
                                                    value={this.state.descricao}></textarea>
                                                <p className="erro"></p>
                                            </div>
                                            <p className="erro"></p>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ border: "0.05rem solid #676a6c" }} />
                                <div className="row" style={{ marginLeft: "0", marginTop: "1rem" }}>
                                    <label className="text15">
                                        Colaboradores com permissão para apontamentos fora do horário comercial
                                    </label>
                                    <Table idProjeto={this.state.idProjeto} />
                                </div>
                                <div className="row" style={{ marginLeft: "0" }}>
                                    <div className="buttom-group-editar-horas-projeto">
                                        {
                                            // <Button onClick={() => console.log('aqui')} className='secundary-button-md' children='Texto do botão' />
                                        }
                                        <button onClick={this.backPage} className="btn btn-cancelar-edicao">Cancelar edição</button>
                                        <button onClick={this.saveEdit} className="btn btn-salvar-edicao">Salvar</button>
                                    </div>
                                </div>

                            </React.Fragment>
                            : null
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    editHoraProjeto: state.editHoraProjeto,
    colaboradores: state.colaboradores.colaboradores,
    colaboradoresFiltrados: state.colaboradores.colaboradoresFiltrados,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ ...editProjectActions, ...sendProjColActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditarHorasProjeto);