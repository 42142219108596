import React from 'react';

import { bindActionCreators } from 'redux';
import * as sendProjCol from '../../../../store/actions/sendProjCol';
import { connect } from 'react-redux';

const Tbody = (props) => {

    let { colaboradores, idProjeto } = props;

    const changeColaboradores = (id_col, id_sub) => {
        let col = colaboradores.filter(e => e.id_recurso === id_col);
        const colabs = colaboradores.filter(e => e.id_recurso !== id_col);
        col = col[0];
        col.acao = (col.id_subprojeto && col.id_subprojeto === id_sub) ?
            'Excluindo' :
            'Adicionando';
        col.id_subprojeto = (col.id_subprojeto && col.id_subprojeto === id_sub) ?
            null :
            id_sub;
        col.status = !col.status;
        colabs.push(col);
        props.sendColaboratorsToStore(colabs);
    }

    return (
        <tbody className="text-center table-striped">

            {
                props.colaboradoresFiltrados.map(e => {
                    return (
                        <tr key={e.id_recurso}>
                            <td className="td-gerenciamento">{e.recurso}</td>
                            <td className="td-gerenciamento">
                                {
                                    !e.especial ?
                                        <label htmlFor="" className="switch switch-t">
                                            <input
                                                type="checkbox"
                                                className="icon-col"
                                                checked={e.id_subprojeto === idProjeto}
                                                onChange={() => []}
                                            />
                                            <span className="slider round"
                                                onClick={() => changeColaboradores(e.id_recurso, idProjeto)}
                                            />
                                        </label>
                                        :
                                        <label htmlFor="" className="switch switch-t">
                                            <input
                                                type="checkbox"
                                                className="icon-col"
                                                checked={e.especial}
                                                onChange={() => []}
                                            />
                                            <span className="slider round inactive-slider-round" style={{}} />
                                        </label>
                                }
                            </td>
                        </tr>
                    )
                })
            }
        </tbody>
    )
}
const mapStateToProps = state => ({
    colaboradores: state.colaboradores.colaboradores,
    colaboradoresFiltrados: state.colaboradores.colaboradoresFiltrados,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(sendProjCol, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Tbody);