import React from 'react';
import axios from "axios"
import Backend from '../../../utils/backend';
import pt_BR from "date-fns/locale/pt-BR";
import moment from "moment";
import DatePicker, { registerLocale } from 'react-datepicker';
import { Button } from 'react-bootstrap';
// import { toast } from 'react-toastify';
import "../modal.css"
import 'react-toastify/dist/ReactToastify.css';
registerLocale("pt-BR", pt_BR);

class AbaFolga extends React.Component{
    constructor(props, context){
        super(props, context);

        this.state = {
            user: this.props.user,
            loading: false,
            folgasList: [],
            newFolgasList: [],
            maxDate: new Date(),
			excluir: [],
            error: null,
            salvando: false,
        }
        this.close = this.props.close;
    }

    registroNaoSalvo = (reason = null) => {
        // toast.error(`Registro não salvo!`, {
        //     position: toast.POSITION.TOP_RIGHT,
		// });
		if(reason) this.setState({error: reason});
    }
    registroSalvo = () => {
        // toast.success(`Registro salvo!`, {
        //     position: toast.POSITION.TOP_RIGHT,
		// });
		this.setState({error: null});
    }
    registroNaoExcluido = (reason = null) => {
        // toast.error(`Registro não excluído!`, {
        //     position: toast.POSITION.TOP_RIGHT,
        // });
		if(reason) this.setState({error: reason});
    }
    registroExcluido = () => {
        // toast.success(`Registro excluído!`, {
        //     position: toast.POSITION.TOP_RIGHT,
        // });
		this.setState({error: null});
    }

    componentDidMount(){
        // set maxDate as yesterday 
        this.setState(prevState => ({
            maxDate : new Date(prevState.maxDate.setDate(prevState.maxDate.getDate() - 1)),
           
        }), () => this.buscaFolgas())
	}
	
	componentDidUpdate(){
		
	}

    buscaFolgas = () => {
        axios.get(Backend(`busca_folgas?colaborador_id=${this.state.user.id_recurso}`))
        .then(res => {
            if(res.status){
                this.setState(prevState => ({
                    ...prevState,
                    folgasList: res.data.folgas
                }))
            }else{
                console.log('erro')
            }
        })
    }

    changedataEntrada = (data) => this.setState({ dataEntrada: data })

    novaFolga = () => {
        let newList = this.state.newFolgasList;        
        
        newList.push({folga_id: null, folga_data: null, folga_descricao: ''})

        this.setState({newFolgasList: newList})
    }

    excluirFolga = async () => {
        // const folgas = this.state.folgasList.filter(e=> this.state.excluir.indexOf(e.folga_id) === -1);
        // this.setState({folgasList : folgas});        

        if(this.state.excluir.length > 0){
			axios.delete(Backend('excluir_folga'), {data: {excluir_folgas: this.state.excluir}}, {
				headers: {'Content-type': 'application'}
			})
			.then(res => {
				if(res.data.status){
                    this.registroExcluido();
                    this.buscaFolgas();
				}else{
					this.registroNaoExcluido(res.data.reason);
				}
			}).catch(e => this.registroNaoExcluido())
		}
    }

    salvarFolga = async () => {

        let folgas = this.state.newFolgasList;
        let vazio = false
        
        folgas.forEach(e => {
            if(e.folga_data === null || e.folga_descricao === ''){
                this.setState({error: "Campos de data e descrição são obrigatórios"});
                vazio = true;
            }
        })

        if(vazio)
            return

        this.setState({
            salvando : true
        });

        folgas = folgas.map(e => {
            return {
                ...e,
                folga_data: moment(e.folga_data).format('YYYY-MM-DD')
            }
        })

        if(folgas.length > 0){
            axios.post(Backend('salva_folga'), {folgas, colaborador_id: this.state.user.id_recurso}, {
                headers: {'Content-type' : 'application/json'}
            })
            .then(res => {
                if(res.data.status){
                    this.setState({
                        salvando : false
                    });
                    this.registroSalvo();
                    this.close();

                }else{
                    this.setState({
                        salvando : false
                    });
                    this.registroNaoSalvo(res.data.reason);
                }
            }).catch(e => this.registroNaoSalvo())
        } else {

            this.setState({
                salvando : false
            });
            this.close();

        }
    }
    
    changeData = (e, i) => {
        let newFolgas = this.state.newFolgasList;
        newFolgas[i].folga_data = e;
        this.setState({newFolgasList: newFolgas })
    } 

    changeDescicao = (e, i) => {
        let newFolgas = this.state.newFolgasList;
        newFolgas[i].folga_descricao = e.target.value;
        this.setState({newFolgasList: newFolgas })
    } 
	
	handleExcluir = (e, folga_id) => {
		let excluir = this.state.excluir;
		if(excluir.includes(folga_id)) excluir = excluir.filter(e => e !== folga_id)
		else excluir.push(folga_id);
		this.setState({excluir})
	}
 
    render(){
        return(
            <section>
                <div id="bodyFolga" className=" bodyFolga table-responsive col-md-12" style={{marginLeft:'-15px'}}>
                    <table className="table table-bordered" cellSpacing="0" cellPadding="0">

                        <thead>
                            <tr>
                                <th className="th-black text-center col-md-1"></th>
                                <th className="th-black text-center col-md-3">Data</th>
                                <th className="th-black text-center col-md-8">Descrição</th>
                            </tr>
                            </thead>
                            { !this.state.loading ? 
                            
                                <tbody className="">

                                {
                                    this.state.folgasList.map((element, i) => {

                                        return(
                                            <tr key={i} id={element.folga_id} onClick={()=> this.setState({clicado : element.folga_id})}>
												<td className="text-center">{element.folga_id ?
													<input
														key={'check'+i}
														type="checkbox"
														checked={this.state.excluir.includes(element.folga_id)}
														onChange={(e) => this.handleExcluir(e, element.folga_id)}/> : null}
												</td>
												<td>
													<input type="text" className="form-control" value={moment(element.folga_data).format('DD/MM/YYYY')} readOnly />
												</td>
                                                <td>
                                                    <input type="text" className="form-control" value={!element.folga_id ? `Novo: ${element.folga_descricao}`: element.folga_descricao} readOnly />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    this.state.newFolgasList.map((element, i) => {

                                        return(
                                            <tr key={i} id={element.folga_id}>
                                                <td></td>
                                                <td 
                                                    onClick={e => {
                                                        document.getElementById('bodyFolga').scrollTo({top:10000, behavior: 'smooth'});
                                                        if(this.state.newFolgasList.length + this.state.folgasList.length === 3 ){
                                                            setTimeout(function(){
                                                                document.getElementById('bodyFolga').scrollTo({
                                                                    top:10000, behavior: 'smooth'
                                                                })
                                                            }, 100);
                                                        }
                                                    }}
                                                    ><DatePicker
                                                    placeholderText="Selecione uma data"
                                                    selected={element.folga_data}
                                                    onChange={(e) => {this.changeData(e, i)}}
                                                    dateFormat="dd/MM/yyyy"
                                                    id="data-folga"
                                                    popoverAttachment='bottom right'
                                                    popoverTargetAttachment='top right'
                                                    // withPortal
                                                    className="form-control"
                                                    minDate={new Date('2019-09-16T00:00')}
                                                    maxDate={this.state.maxDate}
                                                    locale="pt-BR"
                                                    todayButton={"Hoje"}
                                                /></td>
                                                <td><input
                                                    type="text"
                                                    onChange={(e) => this.changeDescicao(e, i)}
                                                    className="form-control" value={element.folga_descricao}
                                                /></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            : null
                        }
					</table>
					<div className="add-remove-box">

                        <Button
                            onClick={this.excluirFolga}
                            type="button"
                            className="btn remove-folga btn-warning"
                        >
                            Excluir Folga
                        </Button>
						<Button
							type='button'
							className='btn add-folga'
							id="add-folga"
							onClick={this.novaFolga}
						>
							Adicionar Folga
						</Button>
					</div>
                </div>
                <div className="buttons-folga">
                    <button onClick={this.state.salvando ? null : this.salvarFolga} className={`primary-color btn btn-sm btn-modal btn-modal-apontamento-detalado-projeto ${this.state.salvando ? "wait" : null}`}>Salvar e sair</button>
                    <button onClick={this.close} className="btn btn-warning btn-sm btn-modal-apontamento-detalado-projeto">Cancelar</button>
                    <span className='erro'>{this.state.error}</span>	
            </div>
            </section>
        )
    }
}

export default AbaFolga;