import React, { Component } from 'react';
import Title from '../../template/title';
import Backend from '../../utils/backend';
import axios from 'axios';
import { getToken } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import DatePicker, { registerLocale } from 'react-datepicker';
import pt_BR from "date-fns/locale/pt-BR";
import Swal from 'sweetalert2';
import "react-datepicker/dist/react-datepicker.css";
import "./relatorios.css"
registerLocale("pt-BR", pt_BR);
const moment = require('moment');

export default class Relatorios extends Component{

  pad = (number, length) => {   
    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }       
    return str;    
  }

  componentDidMount = async () => {
    this.verificaAprovador();
    
    this.buscarSubProjetosAtivos();
    this.buscarRelatorios();
  }

  constructor(){
    super()
    this.state = { 
      user: jwt_decode(getToken()),
      is_aprovador: false,
      startDate: new Date(),
      endDate: new Date(),
      colaboradores: [],
      id_colaborador: null,
      grupos: [],
      id_grupo: null,
      subprojetos: [],
      id_subprojeto: "-1",
      id_gerado: null
    };
    this.handleChangeGrupo = this.handleChangeGrupo.bind(this)
  }

  verificaAprovador = () => {
    axios.post(Backend(`verifica_se_aprovador`), { id_user: this.state.user.id_recurso  }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      this.setState({
        is_aprovador : response.data,
      });
    });
    if(this.state.user.id_perfil === 6 || this.state.user.id_perfil === 1 || this.state.is_aprovador){
      this.buscarColaboradores(this.state.id_grupo);
      this.buscarGrupos(this.state.id_grupo);
    }else{
      this.setState({
        id_colaborador : this.state.user.id_recurso,
        id_grupo : this.state.user.id_grupo
      })
    }
  }

  buscarSubProjetosAtivos = () =>{
    axios.get(Backend(`subprojetos_ativos`)).then((response) => {
      this.setState({ subprojetos: response.data });
    });
  }

  handleChangeDate(date, periodo) {
    if(periodo === 1) {
      this.setState({ startDate: date });
      // if(date <= this.state.endDate){
      //   this.setState({ startDate: date });
      // }
    }
    else {
      this.setState({ endDate: date });
      // if(date >= this.state.startDate){
      //   this.setState({ endDate: date });
      // }
    }
  }

  mudarSubProjeto = e => {
    this.setState({
      id_subprojeto: e.target.value
    })
  }

  limparCampos = async () => {
    await this.setState({
      startDate: new Date(),
      endDate: new Date(),
      colaboradores: [],
      id_colaborador: null,
      id_subprojeto: "-1",
      grupos: [],
      id_grupo: null,
      relatorioMessage: null,
      relatorioStatus: 0
    })
    this.verificaAprovador();
    document.getElementById('subprojeto').value = "-1";
  }

  buscarColaboradores = id_grupo => {
    axios.post(Backend(`colaboradores_ativos`), { id_grupo, id_gestor: this.state.user.id_recurso  }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      this.setState({
        colaboradores : response.data,
        id_grupo
      });
    });
  }

  buscarGrupos = id_grupo => {
    axios.post(Backend(`grupos`), { id_grupo, id_gestor: this.state.user.id_recurso  }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      this.setState({
        grupos : response.data,
        id_grupo
      });
    });
  }

  buscarRelatorios = () => {
    axios.post(Backend(`relatorios`), { tipo: 2 }).then((response) => {
      this.setState({
        relatorios : response.data
      });
    });
  }

  handleChangeGrupo = (e) => {
    let value = e.target.value;
    if(value === 'todos'){
      value = null;
      this.setState({
        id_grupo: null,
        id_colaborador: null
      })
    }
    this.buscarColaboradores(value);
    this.buscarGrupos(value);
  }

  handleChangeGrupoColaborador = (e) => {
    let id_colaborador = e.target.value;
    if(id_colaborador === 'todos'){
      this.buscarColaboradores(null);
      this.buscarGrupos(null);
      this.setState({
        id_grupo: null,
        id_colaborador: null
      })
    }
    else{
      axios.post(Backend(`colaboradorById`), { id_colaborador }, {
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        this.buscarColaboradores(response.data.id_grupo);
        this.buscarGrupos(response.data.id_grupo);
        this.setState({ 
          id_colaborador,
          id_grupo: response.data.id_grupo
        });
      });
    }    
  }

  gerarRelatorios = async () => {

    const dadosRelatorio = {
      id_colaborador : this.state.id_colaborador,
      id_grupo : this.state.id_grupo,
      start : moment(this.state.startDate).format("YYYY-MM-DD"),
      end : moment(this.state.endDate).format("YYYY-MM-DD"),
      id_solicitante : this.state.user.id_recurso,
      id_gerado: this.state.id_gerado,
      id_subprojeto : this.state.id_subprojeto
    }

    if(dadosRelatorio.start > dadosRelatorio.end){
      Swal.fire({
        title:"Atenção",
        text: "Data inicial não pode ser posterior a data final",
        type: "warning", confirmButtonColor: '#720425',
      });
    }
    else if(dadosRelatorio.start < '2019-09-16'){
      Swal.fire({
        title:"Atenção",
        text: "Data inicial não pode ser anterior ao dia 16/09/2019",
        type: "warning", confirmButtonColor: '#720425',
      });
    }
    else{
      await axios.post(Backend(`novaLinhaTbl_relatorio_gerados`), dadosRelatorio, {
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        this.setState({ 
          id_gerado: response.data.id_gerado
        });
        dadosRelatorio.id_gerado = response.data.id_gerado;
      });
      
      await axios.post(Backend(`gerar_relatorio_csv1`), dadosRelatorio, {
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        if(response.data === false){
          Swal.fire({
              title:"Erro ao baixar o arquivo",
              text: "O arquivo solicitado para download não se encontra alocado no servidor. Informe o suporte.",
              type: "error", confirmButtonColor: '#720425',
          });
        }else{
            const url = window.URL.createObjectURL(new Blob(["\ufeff", response.data]));
            const link = document.createElement('a');
            link.href = url;
            const fileName = 'Relatório_horas_por_projeto.csv'
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();// you need to remove that elelment which is created before
        }
      });
    }
  }

  render(){
    return (
      <>
        <div className="animated fadeInRight home">
          <Title name="Relatórios" />
          <div className="container">
            <div className="row">
              <h2 style={{marginLeft:'15px'}}> Relatório de Horas por Projeto  </h2>
              <br />
            </div>

            {this.state.user.acesso.indexOf(7) !== -1? 
            <>
            <div className="row" style={{marginLeft: "0px", marginBottom: "20px"}}>
              <label className="mr15" htmlFor="inicio"><span className="astericoVermelho">*</span>Data inicial: </label>
              <DatePicker
                id="inicio" 
                onChange={e => this.handleChangeDate(e, 1)}
                selected={this.state.startDate}
                minDate='2019-09-16'
                maxDate={this.state.endDate}
                locale='pt-BR'
                dateFormat="dd/MM/yyyy"
                todayButton={"Hoje"}
                className="mr15"
              />
              <label className="mr15" htmlFor="fim"><span className="astericoVermelho">*</span>Data final: </label>
              <DatePicker
                id="fim" 
                onChange={e => this.handleChangeDate(e, 2)}
                selected={this.state.endDate}
                minDate={this.state.startDate}
                locale='pt-BR'
                dateFormat="dd/MM/yyyy"
                todayButton={"Hoje"}
                className="mr15"
              />
            </div>
            {/* {this.state.user.id_perfil === 6 || this.state.user.id_perfil === 1 || this.state.is_aprovador ?
            <div className="row" style={{marginLeft: "0px", marginBottom: "20px"}}>
              <label className="mr15" htmlFor="grupo">Centro de custo: </label>
              <select id="grupo" 
              value={this.state.id_grupo || "todos"} 
              onChange={this.handleChangeGrupo} className="p15 mr25">
                <option value="todos">Todos</option>
                {
                  this.state.grupos.map((element, i) => {                   
                    return(
                      <option key={i} value={element.id_grupo}>{element.grupo}</option>
                    )
                  })
                }
              </select>
              <label className="mr15" htmlFor="recurso">Colaborador: </label>
              <select id="recurso"
              value={this.state.id_colaborador || 'todos'}
              onChange={this.handleChangeGrupoColaborador} className="p15 mr25">
                <option value='todos'>Todos</option>
                {
                  this.state.colaboradores.map((element, i) => {
                    return(
                      <option key={i} value={element.id_recurso}>{this.pad(element.matricula, 12) + " - "+ element.recurso}</option>
                    )
                  })
                }
              </select>
            </div>
            : 
            <div className="row" style={{marginLeft: "0px", marginBottom: "20px"}}>
              <label className="mr15" htmlFor="grupo">Centro de custo: </label>
              <input type="text" value={this.state.user.grupo} className="p15 mr25" readOnly/>
              
              <label className="mr15" htmlFor="recurso">Colaborador: </label>
              <input type="text" value={this.state.user.recurso} style={{width: "300px"}} className="p15 mr25" readOnly/>
              
            </div>
            } */}
            <div className="row" style={{marginLeft: "0px", marginBottom: "20px"}}>
              <label className="mr15" htmlFor="subprojeto">Subprojeto: </label>
              <select id="subprojeto" onChange={this.mudarSubProjeto} className="p15 mr25">
                <option value="-1">Todos</option>
              {
                this.state.subprojetos.map((element, i) => {
                  return(
                    <option key={i} value={element.id_subprojeto}>{element.comercial} - {element.subprojeto}</option>
                  )
                })
              }
              </select>
            </div>
            <div className="row" style={{marginLeft: "0px", marginBottom: "20px"}}>
              <button onClick={this.limparCampos} className="btn mr15 btn-danger">Limpar filtro</button>
              <button onClick={this.gerarRelatorios} className="btn mr15 btn-gerar">Gerar relatório</button>
            </div>
            </>:null}         
          </div>
        </div>
      </>
    )
  }
}