import React, { Component } from "react";
import "./CadastroPRJ.css";
import axios from "axios";
import Backend from "../../utils/backend";
import InputMask from "react-input-mask";
import Swal from 'sweetalert2'


export default class CadastroPRJ extends Component {
  constructor() {
    super();

    this.state = {
      projetoList: [],
      clienteList: [],
      aprovadorList: [],
      natruezaList: [],
      projectListTable: [],
      idNatrueza: "",
      codigoDoProjeto: "",
      nomeProjeto: "",
      btnNovoProjeto: false,
      novoNomeProjeto: "",
      novoCodigoProjeto: "",
      cliente: "",
      idCliente: "",
      ativo: 1,
      nomeSubprojeto: "",
      codigoSubprojeto: "",
      idProjeto: "",
      idAprovador: "",
      btnSalvarProjeto: false,
      idEditarPrj: "",
      idEditarSubprj: "",
      ativacao: 1,
      editCodigoProjeto: "",
      editCodigoSubprojeto: "",
      errors: {},
      index: 1,
      resultCount:20,
      loading: false,
      filtro:"",
      filtroPor:"",
      btnfiltro:false
    };
  }

  componentDidMount() {
    axios.get(Backend("cadastreProjeto_select")).then(res => {
      this.setState({ projetoList: res.data });
    });

    axios.get(Backend("cadastreCliente_select")).then(res => {
      this.setState({ clienteList: res.data });
    });

    axios.get(Backend("colaboradores_select")).then(res => {
      this.setState({ aprovadorList: res.data });
    });

    axios
      .get(Backend("natrueza_select"))
      .then(res => this.setState({ natruezaList: res.data }));

    axios
      .get(Backend(`projeto_table/${1}`))
      .then(res =>this.setState({ projectListTable: res.data }))
      .catch(err => console.log(err));
  }

  changeCodigoProjeto = e => this.setState({ codigoDoProjeto: e.target.value });
  changeNovoProjeto = e => this.setState({ novoNomeProjeto: e.target.value });
  changeNomeProjeto = e => this.setState({ nomeProjeto: e.target.value });
  changeNovoCodigoProjeto = e =>
    this.setState({ novoCodigoProjeto: e.target.value });
  changeAprovador = e =>
    this.setState({
      idAprovador: e.target.options[e.target.selectedIndex].getAttribute("value")
    });
  changeNomeSubprojeto = e => this.setState({ nomeSubprojeto: e.target.value });
  changeCodigoSubprojeto = e =>
    this.setState({ codigoSubprojeto: e.target.value });
  changeCliente = e =>
    this.setState({
      idCliente: e.target.options[e.target.selectedIndex].getAttribute("value")
    });
  changeAtivo = e =>
    this.setState({
      ativo: e.target.options[e.target.selectedIndex].getAttribute("data-key")
    });
  changeNatrueza = e =>
    this.setState({
      idNatrueza: e.target.options[e.target.selectedIndex].getAttribute("value")
    });

  changeFiltro = e => this.setState({ filtro: e.target.value, index:1});
  changeFiltroPor = e => this.setState({ filtroPor: e.target.value});


  hierarquia = async () => {

        await axios.get(Backend("get_download2"))
        .then(res=>{
          // const blob = new Blob([res.data], { type: 'text/csv' })
          const url = window.URL.createObjectURL(new Blob(["\ufeff", res.data]));
          const link = document.createElement('a');
          link.href = url;
          const fileName = 'Timesheet.csv'
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();// you need to remove that elelment which is created before  
        })

    
    //await axios.get(Backend("get_hierarquia"))
    // .then(res=>{
    //   axios.get(Backend("get_download")).then(res=>{
    //     const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
    //     const url = window.URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     const fileName = 'Timesheet.xlsx'
    //     link.setAttribute('download', fileName);
    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();// you need to remove that elelment which is created before
    //   })
    // })


  }


  handleProjectSelect = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        cliente: e.target.value,
        idCliente: e.target.value,
        errors: {},
        idAprovador: e.target.options[e.target.selectedIndex].getAttribute(
          "value"
        ),
        idProjeto: e.target.options[e.target.selectedIndex].getAttribute(
          "data-key"
        )
      },
      this.buscaProjeto
    );
  };

  buscaProjeto = () => {
    const submitProjeto = {
      projeto: this.state.nomeProjeto
    };

    axios.post(Backend("cadastreProjeto_select2"), submitProjeto).then(res => {
      if (this.state.nomeProjeto === "") {
        this.setState({
          codigoDoProjeto: "",
          cliente: ""
        });
      } else {
        this.setState({
          codigoDoProjeto: res.data[0].codigo_projeto,
          cliente: res.data[0].cliente,
          idCliente: res.data[0].id_cliente,
        });
      }
    });
  };

  btnNovoProjetoClick = e => {
    this.setState({
      btnNovoProjeto: true,
      cliente: "",
      btnEditarProjeto: false,
      nomeProjeto: "",
      codigoDoProjeto: "",
      idProjeto: "",
      idCliente: "",
      idAprovador: "",
      codigoSubprojeto: "",
      nomeSubprojeto: "",
      idNatrueza: "",
      idEditarPrj: "",
      idEditarSubprj: "",
      errors: {}
    });
  };

  btnCancelarNovoProjeto = e => {
    this.setState({
      btnNovoProjeto: false,
      nomeProjeto: "",
      codigoDoProjeto: "",
      idCliente: "",
      errors: {},
      novoNomeProjeto: "",
      novoCodigoProjeto: "",
      cliente: "",
      idNatrueza: "",
      idAprovador: "",
      codigoSubprojeto: "",
      nomeSubprojeto: ""
    });
  };

  limparState = () => {
    this.setState({
      codigoDoProjeto: "",
      novoNomeProjeto: "",
      ativo: 1,
      idCliente: "",
      idProjeto: "",
      codigoSubprojeto: "",
      idAprovador: "",
      nomeSubprojeto: "",
      idNatrueza: "",
      nomeProjeto: "",
      novoCodigoProjeto: "",
      btnNovoProjeto: false,
      idEditarPrj: "",
      idEditarSubprj: "",
      errors: {}
    });
  };

  atualizarProjetoList = () => {
    axios.get(Backend("cadastreProjeto_select")).then(res => {
      this.setState({ projetoList: res.data });
    });
  }

  atualizarTabela = () => {
    this.setState({ loading: true, index:1, resultCount:20 });
    axios
      .get(Backend(`projeto_table/${1}`))
      .then(res => {
        this.setState({ projectListTable: res.data, loading: false });
      })
      .catch(err => console.log(err));
  };

  buscaEditar = () => {
    const editarProjeto = {
      idEditarPrj: this.state.idEditarPrj,
      idEditarSubprj: this.state.idEditarSubprj
    };

    axios
      .post(Backend("update_id"), editarProjeto)
      .then(res =>
        this.setState({
          cliente: res.data[0].nome_cliente,
          novoCodigoProjeto: res.data[0].codigo_projeto,
          novoNomeProjeto: res.data[0].nome_projeto,
          codigoSubprojeto: res.data[0].codigo_subprojeto,
          nomeSubprojeto: res.data[0].subprojeto,
          idNatrueza: res.data[0].id_natrueza,
          idAprovador: res.data[0].id_aprovador_horas,
          idCliente: res.data[0].id_cliente,
          editCodigoProjeto: res.data[0].codigo_projeto,
          editCodigoSubprojeto: res.data[0].codigo_subprojeto
        })
      )
      .catch(err => console.log(err));
  };

  editarPrj = (idPrj, idSubprj) => {
    this.setState(
      {
        idEditarPrj: idPrj,
        idEditarSubprj: idSubprj
      },
      this.buscaEditar
    );
  };

  salvarProjetos = e => {
    e.preventDefault();

    if (this.state.btnNovoProjeto) {
      const novoProjeto = {
        codigoProjeto: this.state.novoCodigoProjeto,
        nomeProjeto: this.state.novoNomeProjeto,
        ativo: this.state.ativo,
        idCliente: this.state.idCliente,
        codigoSubprojeto: this.state.codigoSubprojeto,
        idAprovador: this.state.idAprovador,
        nomeSubprojeto: this.state.nomeSubprojeto,
        idNatrueza: this.state.idNatrueza
      };

      axios
        .post(Backend("post_cadastre_projetos"), novoProjeto)
        .then(prj => {
          this.limparState();
          Swal.fire({
            title:"Dados salvos com sucesso",
            text:"Um novo projeto foi criado", 
            type:"success",
            confirmButtonColor: '#720425',
          })
          this.atualizarTabela();
          this.atualizarProjetoList();
        })
        .catch(err => this.setState({ errors: err.response.data }));
    } 
    
    else if (this.state.idEditarPrj && !this.state.btnNovoProjeto) {
      const editarProjeto = {
        idProjeto: this.state.idEditarPrj,
        idSubprojeto: this.state.idEditarSubprj,
        nomeCliente: this.state.novoNomeProjeto,
        idCliente: this.state.idCliente,
        codigoProjeto: this.state.novoCodigoProjeto,
        nomeProjeto: this.state.novoNomeProjeto,
        codigoSubprojeto: this.state.codigoSubprojeto,
        subprojeto: this.state.nomeSubprojeto,
        idNatrueza: this.state.idNatrueza,
        idAprovador: this.state.idAprovador,
        idAtivo: this.state.ativo,
        editCodigoProjeto: this.state.editCodigoProjeto,
        editCodigoSubprojeto: this.state.editCodigoSubprojeto
      };

      axios
        .post(Backend("post_update_project"), editarProjeto)
        .then(res => {
          this.limparState();
          Swal.fire({
            title:"Dados salvos com sucesso",
            text:"O projeto foi Editado", 
            type:"success",
            confirmButtonColor: '#720425',
          })
          this.atualizarTabela();
          this.atualizarProjetoList();
        })
        .catch(err => this.setState({ errors: err.response.data }));
    } 
    
    else if (!this.state.btnNovoProjeto) {
      const novoSubprojeto = {
        idProjeto: this.state.idProjeto,
        codigoSubprojeto: this.state.codigoSubprojeto,
        idAprovador: this.state.idAprovador,
        nomeSubprojeto: this.state.nomeSubprojeto,
        ativo: this.state.ativo,
        idNatrueza: this.state.idNatrueza
      };

      axios
        .post(Backend("post_cadastre_subprojeto"), novoSubprojeto)
        .then(prj => {
          this.limparState();
          Swal.fire({
            title:"Dados salvos com sucesso",
            text:"Um novo subprojeto foi criado", 
            type:"success",
            confirmButtonColor: '#720425',
          })
          this.atualizarTabela();
        })
        .catch(err => this.setState({ errors: err.response.data }));
    }
  };

  limparClick = e => {
    e.preventDefault();

    this.setState({
      codigoSubprojeto: "",
      nomeSubprojeto: "",
      idProjeto: "",
      idAprovador: "",
      errors: {},
      codigoDoProjeto: "",
      nomeProjeto: "",
      cliente: "",
      idCliente: "",
      ativo: 1,
      btnNovoProjeto: false,
      novoCodigoProjeto: "",
      novoNomeProjeto: "",
      idNatrueza: "",
      idEditarPrj: "",
      idEditarSubprj: ""
    });
  };

  setActive = (enable, idPrj, idSubprj) => {

    const editarAtivo = {
      idAtivo: enable,
      idProjeto: idPrj,
      idSubprojeto: idSubprj
    };

    axios
      .post(Backend("update_ativo"), editarAtivo)
      .then(res => {
        this.setState({index:1})
        this.atualizarTabela();
      })
      .catch(err => console.log(err));
  };

  carregarTabela = () => {
    const { index, projectListTable, btnfiltro, filtro } = this.state;

    if(btnfiltro)
    {
      axios.get(Backend(`filtrar_tabela_pesq/${index+1}/${filtro}`))
      .then(res=>{
        if(res.data.length === 0)
        {
          this.setState({resultCount:res.data.length})
        }

        else
        {
          this.setState({
            projectListTable: projectListTable.concat(res.data),
            index: index + 1,
            resultCount:res.data.length
          });
        }
      })
    }

    else
    {
      axios
      .get(Backend(`projeto_table/${index + 1}`))
      .then(res => {
          this.setState({
            projectListTable: projectListTable.concat(res.data),
            index: index + 1,
            resultCount:res.data.length
          });
      })
      .catch(err => console.log(err)); 
    }
  };

  filtrarClick = () => {
    this.setState({btnfiltro:true})
    const novoFiltro = {
      filtro:this.state.filtro.trim(),
      index:this.state.index,
    }

    axios.post(Backend("filtrar_tabela"), novoFiltro)
    .then(res=>{
      this.setState({projectListTable:[]})
      this.setState({
        projectListTable:res.data,
        resultCount:res.data.length
      })
    });
  }

  limparFiltro = () => {
    this.setState({
      filtro:"",
      index:1,
      resultCount:20,
      btnfiltro:false
    })
    this.atualizarTabela();
  }
  

  render() {
    let i=0;

    return (
      <div className="container animated fadeInRight">
        <h2 className="headingTitle">Cadastro de Projetos</h2>
        <form onSubmit={this.salvarProjetos}>
          <div className="row">
            <div className="form-group col-md-4">
              <div>
                <label htmlFor="Projeto">
                  <span className="obrigatorio">*</span>Projeto
                </label>
              </div>

              <div>
                {this.state.btnNovoProjeto === true ||
                this.state.idEditarPrj ? (
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.changeNovoProjeto}
                    value={this.state.novoNomeProjeto}
                  />
                ) : (
                  <select
                    className="form-control"
                    onChange={this.handleProjectSelect}
                    name="nomeProjeto"
                    value={this.state.nomeProjeto}
                  >
                    <option />
                    {this.state.projetoList.map(pl => (
                      <option key={i++} data-key={pl.id_projeto}>{pl.projeto}</option>
                    ))}
                  </select>
                )}
                <p className="required">{this.state.errors.nomeProjeto}</p>
              </div>
            </div>

            <div className="form-group col-md-4">
              <div>
                <label htmlFor="Projeto">
                  <span className="obrigatorio">*</span>Código do Projeto
                </label>
              </div>

              {this.state.btnNovoProjeto === true || this.state.idEditarPrj ? (
                <div>
                  <InputMask
                    mask="9999-999-99-9"
                    maskChar=""
                    type="text"
                    className="form-control mask"
                    name="novoCodigoProjeto"
                    onChange={this.changeNovoCodigoProjeto}
                    value={this.state.novoCodigoProjeto}
                  />
                </div>
              ) : (
                <div>
                  <input
                    type="text"
                    className="form-control readOnlyInput"
                    name="codigoDoProjeto"
                    onChange={this.changeCodigoProjeto}
                    value={this.state.codigoDoProjeto}
                    readOnly
                  />
                </div>
              )}
              <p className="required">{this.state.errors.codigoProjeto}</p>
            </div>

            <div className="form-group novoProjeto">
              {this.state.btnNovoProjeto === false ? (
                <button
                  type="button"
                  className="btn btn-novoProjeto btn-cadastro"
                  onClick={this.btnNovoProjetoClick}
                >
                  Novo Projeto
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-novoProjeto btn-cadastro"
                  onClick={this.btnCancelarNovoProjeto}
                >
                  {" "}
                  Cancelar
                </button>
              )}
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-4">
              <div>
                <label htmlFor="Projeto">
                  <span className="obrigatorio">*</span>Cliente
                </label>
              </div>

              <div>
                <select
                  className="form-control"
                  onChange={this.changeCliente}
                  value={this.state.idCliente}
                >
                  <option></option>
                  {this.state.clienteList.map(cliente => (
                    <option key={i++} value={cliente.id_cliente}>
                      {cliente.cliente}
                    </option>
                  ))}
                </select>
                <p className="required">{this.state.errors.idCliente}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-4">
              <div>
                <label htmlFor="Projeto">
                  <span className="obrigatorio">*</span>Nome do Subprojeto
                </label>
              </div>

              <div>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.changeNomeSubprojeto}
                  value={this.state.nomeSubprojeto}
                />
              </div>
              <p className="required">{this.state.errors.nomeSubprojeto}</p>
            </div>

            <div className="row">
              <div className="form-group col-md-4 codigoSub">
                <div>
                  <label htmlFor="Projeto">
                    <span className="obrigatorio">*</span>Código do Subprojeto
                  </label>
                </div>

                <div>
                  <InputMask
                    mask="9999-999-99-9"
                    maskChar=""
                    type="text"
                    className="form-control"
                    onChange={this.changeCodigoSubprojeto}
                    value={this.state.codigoSubprojeto}
                  />
                </div>
                <p className="required">{this.state.errors.codigoSubprojeto}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-4">
              <div>
                <label htmlFor="Projeto">
                  <span className="obrigatorio">*</span>Aprovador
                </label>
              </div>

              <select
                className="form-control"
                onChange={this.changeAprovador}
                value={this.state.idAprovador}
              >
                <option>{""}</option>
                {this.state.aprovadorList.map(apv => (
                  <option key={i++} value={apv.id_recurso}>{apv.recurso}</option>
                ))}
              </select>
              <p className="required">{this.state.errors.idAprovador}</p>
            </div>

            <div className="form-group col-md-2">
              <div>
                <label htmlFor="Projeto">
                  <span className="obrigatorio">*</span>Natureza
                </label>
              </div>

              <div>
                <select
                  className="form-control col-md-2"
                  onChange={this.changeNatrueza}
                  value={this.state.idNatrueza}
                >
                  <option></option>
                  {this.state.natruezaList.map(nat => (
                    <option key={i++} value={nat.id_natrueza}>{nat.natrueza}</option>
                  ))}
                </select>
              </div>
              {<p className="required">{this.state.errors.idNatrueza}</p>}
            </div>

            <div className="form-group col-md-2">
              <div>
                <label htmlFor="Projeto">
                  <span className="obrigatorio">*</span>Ativo
                </label>
              </div>

              <div>
                <select className="form-control" onChange={this.changeAtivo}>
                  <option data-key={1}>Ativo</option>
                  <option data-key={0}>Inativo</option>
                </select>
              </div>
            </div>
          </div>

          <div className="containerSalvarLimpar">
            <div className="row">
              <button
                onClick={this.salvarProjetos}
                className="btn btn-cadastro"
              >
                Salvar
              </button>
              <button onClick={this.limparClick} className="btn btn-cadastro">
                Limpar
              </button>
            </div>
          </div>
        </form>
        
          <div className="form-inline filtro">
              <label style={{marginRight:"10px"}}>Filtrar Tabela:</label>
              <input type="text" style={{marginRight:"10px"}} className="form-control" value={this.state.filtro} onChange={this.changeFiltro}/>
              <button className="btn btn-cadastro" onClick={this.filtrarClick} style={{marginRight:"10px"}}>Filtrar</button>
              <button className="btn btn-cadastro" onClick={this.limparFiltro}>Limpar Filtro</button>
              <button className="btn btn-cadastro" onClick={this.hierarquia}>Hierarquia Projetos</button>
          </div>

        <div className="table2 table-responsive">
          <table className="table table-bordered">
            <tbody>
            <tr>
              <th className="colunaCliente">Cliente</th>
              <th className="colunaCodPrj">Código Projeto</th>
              <th className="colunaPrj">Projeto</th>
              <th className="colunaCodSubprj">Código Subprojeto</th>
              <th className="colunaSubprjNat">SubProjeto - Natureza</th>
              <th className="colunaApv">Aprovador</th>
              <th className="colunaEdit">Editar</th>
              <th className="colunaAti">Ativação</th>
            </tr>
            </tbody>


            { !this.state.loading ? this.state.projectListTable.map(prj => (
              <tbody>
                <tr>
                  <td align="center">
                    {prj.nome_cliente}
                  </td>
                  <td align="center">
                    {prj.codigo_projeto}
                  </td>
                  <td align="center">
                    {prj.nome_projeto}
                  </td>
                  <td align="center">
                    {prj.codigo_subprojeto}
                  </td>
                  <td
                    align="center"
                   
                    style={{ padding: "0px"}}
                  >
                    {prj.natrueza === null
                      ? prj.subprojeto
                      : `${prj.subprojeto}-${prj.natrueza}`}
                  </td>
                  <td align="center">
                    {prj.recurso}
                  </td>
                  <td align="center">
                    <i
                      className="fa fa-pencil-square-o btn-editar"
                      onClick={this.editarPrj.bind(
                        this,
                        prj.id_projeto,
                        prj.id_subprojeto
                      )}
                      aria-hidden="true"
                      style={{marginTop:"10px", color:"gray"}}
                    />
                  </td>
                  <td align="center">
                    {prj.ativo === 1 ?
                      <label className="switch">
                        <input type="checkbox" checked/>
                        <span className="slider round"  onClick={this.setActive.bind(this, prj.ativo, prj.id_projeto, prj.id_subprojeto)}/>
                      </label>
                      :
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round" onClick={this.setActive.bind(this, prj.ativo, prj.id_projeto, prj.id_subprojeto)}/>
                      </label>
                    }
                  </td>
                </tr>
              </tbody>
            )) : null}
          </table>

          {this.state.resultCount === 0 ? <h2>Nenhum Resultado Encontrado</h2> : null }

          <div className="carregar-div">
            {this.state.resultCount < 20 ?
               (<button className="btn btn-carregar" onClick={this.carregarTabela} disabled>
                  Carregar Mais
                </button>)

            :

                (<button className="btn btn-carregar" onClick={this.carregarTabela}>
                    Carregar Mais
                  </button>)
            }
          </div>
        </div>
      </div>
    );
  }
}
