import React, { Component } from "react";
import "./CadastroFri.css";
import axios from "axios";
import Backend from "../../utils/backend";
import DayPicker from "react-day-picker";
import 'react-day-picker/lib/style.css';
import Swal from 'sweetalert2'
import moment from "moment";

const MONTHS = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const WEEKDAYS_LONG = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ];
  const WEEKDAYS_SHORT = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];

  
export default class CadastroFri extends Component {
  constructor() {
    super();
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
        filtro: "",        
        index: 1,
        btnfiltro: false,
        resultCount:20,
        selectedDay: new Date(),
        id_feriado: 0,
        cidade: null,
        motivo: null,
        util: true,
        btnEditar: false,
        selectedOption: 'Util',
        selectMonth: new Date(),
        errors: {},
        cidadeList: [],        
        feriadoTable: []
      }
}

componentDidMount() {

    axios.get(Backend("get_cidade"))
    .then(res => this.setState({ cidadeList: res.data }))    
    
    axios.get(Backend(`table_feriados/${1}`))
    .then(res => this.setState({ feriadoTable: res.data }))
}

atualizarTabela = () => {

    axios.get(Backend(`table_feriados/${1}`))
        .then(res => this.setState({ feriadoTable: res.data }))
        .catch(err => console.log(err))
}

limparState = () => {
  let now = new Date;
  this.setState({
    filtro: "",    
    index: 1,
    btnfiltro: false,
    resultCount:20,
    selectedDay: new Date(),
    id_feriado: 0,
    cidade: '',
    motivo: '',
    util: true,
    btnEditar: false,
    selectedOption: 'Util',
    selectMonth : new Date(now.getFullYear(), now.getMonth()),
    errors: {},    
  });
};

limparClick = e => {    
  let now = new Date;
  this.setState({
    filtro: "",    
    index: 1,
    btnfiltro: false,
    resultCount:20,
    selectedDay: new Date(),    
    selectMonth : new Date(now.getFullYear(), now.getMonth()),
    cidade: '',
    motivo: '',
    selectedOption: 'Util',
    util: true,
    btnEditar: false,
    errors: {},    
  });
};

salvarFeriados = e => {
   e.preventDefault(); 
   const novoFeriado = {
    dt_feriado: this.state.selectedDay,
    cidade: this.state.cidade,
    motivo: this.state.motivo,
    util: this.state.util,
    id_feriado: this.state.id_feriado
   }

   if (this.state.btnEditar) {
      axios
      .post(Backend("editarFeriados"), novoFeriado)
      .then(prj => {
        this.limparState();
        Swal.fire({
          title:"Dados salvos com sucesso",
          text:"Um novo feriado foi criado", 
          type:"success",
          confirmButtonColor: '#720425',
        })
        this.atualizarTabela();        
      })
      .catch(err => this.setState({ errors: err.response.data }));
    }
    else
    {
      axios
      .post(Backend("feriados"), novoFeriado)
      .then(prj => {
        this.limparState();
        Swal.fire({
          title:"Dados salvos com sucesso",
          text:"Um novo feriado foi criado", 
          type:"success",
          confirmButtonColor: '#720425',
        })
        this.atualizarTabela();        
      })
      .catch(err => this.setState({ errors: err.response.data }));
    }

  }

  editarClick = async (id) => {    
    const res = await axios.get(Backend(`feriados/${id}`))
    let editadata = new Date(`${res.data[0].dt_feriado} GMT-0300`)
    this.setState({
      selectedDay: editadata,
      cidade: res.data[0].id_cidade,
      motivo: res.data[0].motivo,
      util: res.data[0].util,
      selectedOption: (res.data[0].util === true) ? 'Util':'NUtil',
      selectMonth : new Date(editadata.getFullYear(), editadata.getMonth()),
      btnEditar: true,
      id_feriado: id
    })
  }
  
  handleDelete = (id) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: "Você não poderá reverter esta ação!",
      type: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
      focusConfirm: false,
      focusCancel: true,
      confirmButtonColor: '#720425',
      cancelButtonColor: '#3085d6',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim'
    }).then((result) => {
      if (result.value) {
        axios.delete(Backend(`feriados/${id}`))
        .then((response) => {
          Swal.fire({
            title: 'Apagado!',
            text: "O registro selecionado foi apagado.",
            type: 'success',
            confirmButtonColor: '#720425',
          })
          this.atualizarTabela();
        })
      }
    });
}

changeFiltro = e => this.setState({ filtro: e.target.value, index:1});

filtrarClick = () => {
    this.setState({btnfiltro:true})
    const novoFiltro = {
      filtro:this.state.filtro.trim(),
      index:this.state.index,
    }

    axios.post(Backend("filtrar_feriado"), novoFiltro)
    .then(res=>{
      this.setState({projectListTable:[]})
      this.setState({
        feriadoTable:res.data,
        resultCount:res.data.length
      })
    });
}

limparFiltro = (e) => {
    this.setState({ filtro: "", btnFiltrar: false, resultCount: 20, index: 1, feriadoTable: [] })
    this.atualizarTabela();
  }

handleDayClick(day, {selected}) {
    this.setState({ selectedDay: selected ? undefined : day, });    
}

onChangeMotivo = (e) => {
  this.setState({ motivo: e.target.value })  
}

onChangeCidade = e => this.setState({ cidade: e.target.value })


carregarTabela = () => {
  const { index, filtro, feriadoTable } = this.state;
  if (this.state.btnFiltrar) {
      axios.get(Backend(`filtrar_feriado_search/${filtro}/${index + 1}`))
          .then(res => this.setState({
              feriadoTable: feriadoTable.concat(res.data),
              index: index + 1,
              resultCount: res.data.length
          }))
  }
  else {
      axios.get(Backend(`table_feriados/${index + 1}`))
          .then(res => this.setState({
              feriadoTable: feriadoTable.concat(res.data),
              index: index + 1,
              resultCount: res.data.length
          }))
  }
}

handleOptionChange= (changeEvent) => {
  this.setState({ selectedOption: changeEvent.target.value, util: (changeEvent.target.value === 'Util') ? true: false });
}

render() {
    let i=0;

    return (
      <div className="container animated fadeInRight">
        <h2 className="headingTitle">Cadastro de Feriados Exceções</h2>
        <form>
          <div className="row">
            <div className="form-group col-md-3">
              <div>
                <label htmlFor="Projeto">
                  <span className="obrigatorio">*</span>Data do Feriado
                </label>
              </div>
              <div>                
                <DayPicker
                 selectedDays={this.state.selectedDay}                 
                 month={this.state.selectMonth}
                 onDayClick={this.handleDayClick}
                 months={MONTHS}
                 weekdaysLong={WEEKDAYS_LONG}
                 weekdaysShort={WEEKDAYS_SHORT}
                 />
              </div>
              <p className="required">{this.state.errors.selectedDay}</p>
            </div>
            <div className="form-group col-md-4">
                <div>
                    <label htmlFor="Projeto">
                    <span className="obrigatorio">*</span>Cidade
                    </label>
                </div>
                <div>
                    <select
                      className="form-control"
                      onChange={this.onChangeCidade}
                      value={this.state.cidade}
                      >
                      <option value=""></option>
                      {this.state.cidadeList.map(cidade => (
                          <option key={i++} value={cidade.id_cidade}>{cidade.cidade}</option>
                      ))}
                    </select>
                    <p className="required">{this.state.errors.cidade}</p>
                </div>
            </div> 
            <div className="form-group col-md-4">
                <div>
                    <label htmlFor="Projeto">
                    <span className="obrigatorio">*</span>Feriado
                    </label>
                </div>
                <div>
                  <label>
                   <input type="radio" value="Util" 
                      checked={this.state.selectedOption === 'Util'} 
                      onChange={this.handleOptionChange} />
                      Sim
                  </label>                  
                </div>
                <div>
                  <label>
                    <input type="radio" value="NUtil" 
                      checked={this.state.selectedOption === 'NUtil'} 
                      onChange={this.handleOptionChange} />
                      Não
                  </label>                  
                </div>               
                <p className="required">{this.state.errors.util}</p>
            </div>            
            <div className="form-group col-md-4 codigoSub">
              <div>
                <label htmlFor="Projeto">
                  <span className="obrigatorio">*</span>Motivo
                </label>
              </div>
              <div>
                <textarea id="motivo" name="motivo" rows="3" cols="100" className="form-control" aria-label="With textarea" onChange={this.onChangeMotivo} value={this.state.motivo}></textarea>
              </div>
              <p className="required">{this.state.errors.motivo}</p>
            </div> 
          </div>                        
          <div className="containerSalvarLimpar">
            <div className="row">
              <button
                onClick={this.salvarFeriados}
                className="btn btn-cadastro"
              >
                Salvar
              </button>
              <button onClick={this.limparClick} className="btn btn-cadastro">
                Limpar
              </button>
            </div>
          </div>
        </form>
        
        <div className="form-inline filtro">
            <label style={{marginRight:"10px"}}>Filtrar Tabela:</label>
            <input type="text" style={{marginRight:"10px"}} className="form-control" value={this.state.filtro} onChange={this.changeFiltro}/>
            <button className="btn btn-cadastro" onClick={this.filtrarClick} style={{marginRight:"10px"}}>Filtrar</button>
            <button className="btn btn-cadastro" onClick={this.limparFiltro}>Limpar Filtro</button>            
        </div>

        <div className="table2 table-responsive">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th className="colunaCliente">Data</th>
                <th className="colunaCodPrj">Cidade</th>
                <th className="colunaPrj">Motivo</th>
                <th className="colunaCodSubprj">Feriado</th>
                <th className="colunaEdit">Editar</th>
                <th className="colunaDelete">Deletar</th>
              </tr>
            </tbody>
            {!this.state.loading ?
              this.state.feriadoTable.map(feriado => (                 
              <tbody key={i++}>
                <tr>
                  <td align="center">
                    {feriado.dt_feriado}
                  </td>
                  <td align="center">
                    {feriado.cidade}
                  </td>
                  <td align="center">
                    {feriado.motivo}
                  </td>
                  <td align="center">
                    {feriado.util}
                  </td>                 
                  <td align="center">
                    <i onClick={() => this.editarClick(feriado.id_feriado)} className="fa fa-pencil-square-o btn-editar" data-tip="Editar Feriado" style={{ marginTop: "10px", color: "gray" }} aria-hidden="true"></i>
                  </td>
                  <td align="center">
                    <i onClick={() => this.handleDelete(feriado.id_feriado)} className="fa fa-trash btn-deletar" data-tip="Deletar Feriado" style={{ marginTop: "10px", color: "gray" }} aria-hidden="true"></i>
                  </td>
                </tr>
              </tbody>
            )) : null}
          </table>

          {this.state.resultCount === 0 ? <h2>Nenhum Resultado Encontrado</h2> : null }

          <div className="carregar-div">
            {this.state.resultCount < 20 ?
               (<button className="btn btn-carregar" onClick={this.carregarTabela} disabled>
                  Carregar Mais
                </button>)
            :
                (<button className="btn btn-carregar" onClick={this.carregarTabela}>
                    Carregar Mais
                  </button>)
            }
          </div>
        </div>
      </div>
    );
  }
}
