/* Menu superior */
/* Criado por Carlos Eduardo em 03/01/2019 */
/* Em caso de dúvidas: contato.carlos@outlook.com */

import React, { Component } from 'react';

import { logout } from "../../auth/auth";
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip'
import $ from 'jquery';

import jwt_decode from 'jwt-decode';
import Backend from '../../utils/backend';
import axios from 'axios';

import { getToken } from "../../auth/auth";

export default class Menu extends Component {

  toggleNavigation(e) {
    let smoothlyMenu = () => {
      if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
        $('#side-menu').hide();
        setTimeout(() => { $('#side-menu').fadeIn(400); }, 200);
      }
      else {
        $('#side-menu').hide();
        setTimeout(() => { $('#side-menu').fadeIn(400); }, 100);
      }
    }
    e.preventDefault();
    $("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }

  componentDidMount = async () => {
    if(getToken()){
      await this.setState({ user : jwt_decode(getToken()) })
      await axios.post(Backend(`has_notification`), {"user": this.state.user.id_recurso}, {
        headers: { 'Content-Type': 'application/json' }
      }).then(async (response) => {
        if(response.data > 0)
          document.getElementById('notificacao-top').style.display = "inline-flex";
        else
          document.getElementById('notificacao-top').style.display = "none";
      });
    }
    
  }


  render() {
    return (
      <div className="col-xs-12">
        <div className="row border-bottom">
          <ReactTooltip />
          <nav className="navbar navbar-static-top white-bg" role="navigation" style={{ marginBottom: 0 }}>
            <div className="navbar-header">
              <button className="navbar-minimalize minimalize-styl-2 btn btn-primary" data-tip="Minimizar/expandir menu" data-place="right" data-effect="solid" data-type="light" onClick={this.toggleNavigation}><i className="fa fa-bars"></i> </button>
            </div>
            <div className="float-right" style={{marginRight: 35}}>
              {/* <img alt="nlt-logo-admin" className="nlt-logo-admin" src={Logo} /> */}
              <ul className="nav navbar-top-links navbar-right">
                <li id="notificacao-top" data-tip="Notificações" data-place="bottom" data-effect="solid" data-type="light" >
                  <a href="#/home">
                    <i className="fa fa-bell" style={{color:'#ff4a4a'}} aria-hidden="true"></i>
                  </a>
                </li>
                <li data-tip="Log out">
                  <Link to="*" onClick={() => { logout(); }}>
                    <i className="fa fa-sign-out"></i> Log out
                </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}
