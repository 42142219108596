import React from 'react';

const TBodyExports = (props) => {

    const intervalo = props.intervalo;
    const horas = props.horas;

    let menor = null;
    let maior = null;
    let trava = false;
    
    return (
        <tbody className="text-center">            
            <tr style={{ backgroundColor: '#a10534', color: '#fff' }}>
                <td className="text-left">Hora de Entrada</td>

                {                    
                    intervalo.map((dia, i) => {
                        trava = false
                        menor = null
                        horas.map(element => {
                            let indice = element.data_apontamento.indexOf(dia.strDataAtual);
                            if(indice !== -1){
                                if(!menor || element.hora_entrada[indice] < menor){
                                    trava = true;
                                    menor = element.hora_entrada[indice];
                                }
                            }
                            return 0
                        })
                        if(trava){
                            return <td key={i}>{menor}</td>
                        }
                        else
                            return <td key={i}>--:--</td>
                    })
                }
            </tr>

            {
                horas.map((esforco, i) => {

                    return (
                        <tr key={i}>
                            <td className="text-left" style={{ whiteSpace: 'unset' }} >
                                {esforco.subprojeto}
                            </td>
                            {
                                intervalo.map((dia, index) => {
                                    let indice = esforco.data_apontamento.indexOf(dia.strDataAtual)
                                    if (indice !== -1) {
                                        return <td key={index}>{esforco.total[indice]}</td>
                                    } else {
                                        return <td key={index}></td>
                                    }
                                })
                            }
                        </tr>
                    )
                })
            }

            <tr style={{ backgroundColor: '#a10534', color: '#fff' }}>
                <td className="text-left">Hora de Saída</td>
                {
                    intervalo.map((dia, i) => {
                        trava = false
                        maior = null
                        horas.map(element => {
                            let indice = element.data_apontamento.indexOf(dia.strDataAtual);
                            if(indice !== -1){
                                if(!maior || element.hora_saida[indice] > maior){
                                    trava = true;
                                    maior = element.hora_saida[indice];
                                }
                            }
                            return 0;
                        })
                        if(trava)
                            return <td key={i}>{maior}</td>
                        else
                            return <td key={i}>--:--</td>
                    })
                }
            </tr>
        </tbody>
    )
}

export default TBodyExports;