import { SEND_COLABS, SEND_COLABS_FILTEREDS } from './actionType';

export const sendColaboratorsToStore = (colaboradores) => {

    return {
        type: SEND_COLABS,
        colaboradores
    }
}
export const sendFilteredsToStore = (colaboradoresFiltrados) => {

    return {
        type: SEND_COLABS_FILTEREDS,
        colaboradoresFiltrados
    }
}
export const resetColabs = () => {

    return {
        type: SEND_COLABS_FILTEREDS,
        colaboradores: [],
        colaboradoresFiltrados: [],
    }
}