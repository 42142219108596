import React from 'react';
import Modal from '../detalhado/modal';
import axios from 'axios';
import Backend from '../../utils/backend';
import Swal from 'sweetalert2'
import ReactTooltip from 'react-tooltip';

export default class TBodyApontamentos extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            apontamentos : this.props.apontamentos,
            modal : false,
            show : false,
        }
        this.callbackParent = this.props.callbackParent;
        this.functionParent = this.props.functionParent;
    }

    handleExcluir(id_timesheet) {
        axios.post(Backend(`exclusao_registro_timesheet`), { "id_timesheet": id_timesheet }, {
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            if(response){
                this.callbackParent();
            }else{
                console.log("Erro ao excluir registro");
            }
        });
    }

    showModal(id_timesheet){
        this.setState(prevState => ({
            modal: id_timesheet,
            show : !prevState.show,
        }))
    }


    handleSwal = (id_timesheet) => {
        this.functionParent()
        return Swal.fire({
            title: 'Você tem certeza?',
            text: "Você não poderá reverter esta ação!",
            type: 'warning',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
            focusConfirm: false,
            focusCancel: true,
            confirmButtonColor: '#720425',
            cancelButtonColor: '#3085d6',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.handleExcluir(id_timesheet);
                Swal.fire({
                    title: 'Apagado!',
                    text: "O registro selecionado foi apagado.",
                    type: 'success',
                    confirmButtonColor: '#720425',
                });
            }
        });
    }

    render(){
        
        return (
            <tbody className="text-center">

                {
                    this.state.apontamentos.map((element, i) => {
                        let cor  = element.id_status === 1 ? "#676a6c" :
                        element.id_status === 2 || element.id_status === 5 ? "#058700" : "red";

                        return(
                            <tr key={i} id={element.id_timesheet} style={{color: cor}} data-tip data-for={`tooltip${element.id_timesheet}`}>
                                <td>{element.data_apontamento}</td>
                                <td>{element.hora_entrada}</td>
                                <td>{element.hora_saida}</td>
                                <td>{element.id_projeto_comercial+" - "+element.subprojeto}</td>
                                <td>{element.descricao}</td>
                                {
                                    element.id_status === 2 || element.id_status === 5 ?
                                    <td>
                                        <button className="button-link desativado" style={{color:'#676a6c'}}>
                                            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </button>
                                        <span style={{color: '676a6c'}}>|</span>
                                        <button className="button-link desativado" style={{color:'#676a6c'}}>
                                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                                        </button>
                                    </td>
                                    :
                                    <td>
                                        {
                                            this.state.modal === element.id_timesheet ? 
                                        
                                            <Modal
                                                edit={true}
                                                show={this.state.show}
                                                id_timesheet={element.id_timesheet}
                                                callbackParent={() => this.callbackParent()}
                                            />
                                            :
                                            null
                                        }
                                        <button className="button-link" onClick={() => this.showModal(element.id_timesheet)}>
                                            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        </button>
                                        <span style={{color: '676a6c!important'}}>|</span>
                                        <button className="button-link" onClick={() => this.handleSwal(element.id_timesheet)}>
                                            <i className="fa fa-trash-o" aria-hidden="true"></i>
                                        </button>
                                        {
                                            element.id_status === 3 ?
                                            <ReactTooltip key={'tip'+i} id={`tooltip${element.id_timesheet}`} aria-haspopup='true' effect="solid">     
                                                <p>Rejeitado em: {element.dt_rejeicao}</p>
                                                <p>Motivo da rejeição: {element.descricao_rejeicao}</p>
                                                <p>Rejeitado por: {element.aprovador}</p>                               
                                            </ReactTooltip>
                                            : null
                                        }
                                    </td>
                                }
                            </tr>
                        )
                    })
                }
            </tbody>
        )
    }
}