import React from 'react';
// import axios from 'axios';
// import Backend from '../../../utils/backend';
import ReactTooltip from 'react-tooltip'

const preencheMotivo = func => {    
    func();
}

const TBodyApontamentos = (props) => {

    const { apontamentos, handleSelectedAll } = props;
    return (
        <tbody className="text-center">

            {
                apontamentos.map((element, i) => {
                    let cor  = element.id_status === 1 ? "#676a6c" :
                    element.id_status === 2 ? "#058700" : "red";
                    
                    return(
                        <tr key={i} style={{color: cor}} data-tip data-for={`tooltip${element.id_timesheet}`}>
                            <td>{
                                element.id_status === 1 ?
                                <input
                                    type="checkbox"
                                    id={`id${element.id_timesheet}`}
                                    className="checkbox-registro"
                                    value={element.id_timesheet}
                                    onClick={() => handleSelectedAll()}
                                    name="em-aprovacao"
                                /> :
                                element.id_status === 2 ?
                                <input
                                    type="checkbox"
                                    id={`id${element.id_timesheet}`}
                                    className="checkbox-registro"
                                    value={element.id_timesheet} 
                                    onClick={() => handleSelectedAll()}
                                    name="aprovado"
                                /> :
                                <>
                                    <ReactTooltip key={'tooltip'+i} id={`tooltip${element.id_timesheet}`} aria-haspopup='true' effect="solid">
                                        <p>Rejeitado em: {element.dt_rejeicao}</p>
                                        <p>Motivo da rejeição: {element.descricao_rejeicao}</p>
                                    </ReactTooltip>
                                    <input type="hidden" value={element.descricao_rejeicao} id={`motivo${element.id_timesheet}`}/>
                                    <input
                                        type="checkbox"
                                        id={`id${element.id_timesheet}`}
                                        className="checkbox-registro"
                                        value={element.id_timesheet}
                                        motivo={element.descricao_rejeicao}
                                        onClick={() => preencheMotivo(handleSelectedAll)}
                                        name="rejeitado"
                                    />
                                </>
                            }
                                
                            </td>
                            <td>{element.recurso}</td>
                            <td>{element.data_apontamento}</td>
                            <td>{element.hora_entrada}</td>
                            <td>{element.hora_saida}</td>
                            <td>{element.id_projeto_comercial+" - "+element.subprojeto}</td>
                            <td>{element.descricao}</td>
                        </tr>
                    )
                })
            }
        </tbody>
    )
}

export default TBodyApontamentos;