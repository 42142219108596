/* Componente de login */
/* Criado por Carlos Eduardo em 03/01/2019 */
/* Em caso de dúvidas: contato.carlos@outlook.com */

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import authAPI from "../../auth/authAPI";
import { login } from "../../auth/auth";
import { getToken } from "../../auth/auth";
import Title from '../../template/title';
import Logo from '../../assets/images/cellere-icon.png';
import Config from '../../config';
import './login.css';


class Login extends Component {

  handleSignIn = async (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    if (!username || !password) {
      this.setState({ error: "Preencha usuario e a senha para continuar" });
    } else {      
      try {
        const response = await authAPI.post("/login", { username, password });
        //const isUser = jwt_decode(response.data.level) === 'user';
        console.log(response)
        login(response.data.token, this.state.keep);
        this.setState({ user: true })
        this.setState({ redirect: true, auth: true });
        document.body.classList.remove('login-page');
      } catch (err) {
        console.log(err)
        this.setState({ error: "Houve um problema com o login, verifique suas credenciais." }); 
      }
    }
  }

  constructor() {
    super();
    document.body.classList.add('login-page');
    this.state = {
      username: '',
      password: '',
      error: '', 
      keep: false,
      user: false,
      auth: false };
  }

  componentDidMount() {
    fetch(Config('AuthURL'), { headers: { 'Authorization': `Bearer ${getToken()}` } })
      .then(resp => resp.json())
      .then(data => this.setState({ auth: data.success }));
  }


  render() {
    let errorMessage = '';

    if (this.state.error) {
      errorMessage = <div className="alert alert-danger animated fadeIn"> {this.state.error} </div>;
    }

    if (this.state.auth) {
      return <Redirect to='/loading' />
    }
    else {
      return (
        <div className="wrapper">
          <Title name="Login" />
          <div className="row animated fadeIn up-part">
            <div id="panel"
             className="col-md-6 col-xs-12 col-md-offset-4"
             >
              <form onSubmit={this.handleSignIn}>
                <div className="panel text-center loginscreen">
                  <div>
                    <div className="text-center">
                      <img className="login-logo" src={Logo} alt="logo" />
                    </div>
                    <h3>Bem-vindo</h3>
                    <br />
                    <p>
                      Para acessar o sistema, entre com seu usuário e senha abaixo.
                    </p>
                    <fieldset>
                      <div className="form-group">
                        <input
                          className="form-control cellere-input"
                          placeholder="Usuário"
                          name="username"
                          type="text"
                          autoFocus
                          onChange={e => this.setState({ username: e.target.value })} />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control cellere-input"
                          placeholder="Senha"
                          name="password"
                          type="password"
                          autoComplete="off"
                          onChange={e => this.setState({ password: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <span className="mytoogle">
                          <input
                            type="checkbox"
                            id="switch"
                            name="keep"
                            onChange={e => this.setState({ keep: !this.state.keep })}
                          />
                          <label htmlFor="switch">Toggle</label>
                          <span className="texttoggle"> Manter-me conectado </span>
                        </span>
                      </div>
                      <button
                        id="btn-login"
                        className="btn btn-primary block full-width m-b"
                        name="button"
                        value="login"
                        type="submit"> Login
                      </button>
                    </fieldset>
                    {errorMessage}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default Login;
