import React, { Component } from 'react'
import { getToken } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import { Link } from "react-router-dom"
import "./relatorios.css"

export default class Relatorios extends Component {
    constructor() {
        super()
        this.state = {
            user: jwt_decode(getToken())
        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="relatorios animated fadeInRight">

                    {this.state.user.acesso.indexOf(6) !== -1 ?

                        <Link to="/relatorio-horas-banco" className="box-relatorios">
                            <div>
                                <p>Banco de Horas</p>
                                <i className="fa fa-file-text-o fa-2x" aria-hidden="true"></i>
                            </div>
                        </Link>

                        : null}

                    {this.state.user.acesso.indexOf(7) !== -1 ?

                        <Link to="/relatorio-horas-projeto" className="box-relatorios">
                            <div>
                                <p>Horas gastas por Projeto</p>
                                <i className="fa fa-file-text-o fa-2x" aria-hidden="true"></i>
                            </div>
                        </Link>

                        : null}
                </div>
                <div className="relatorios animated fadeInRight">

                    {this.state.user.acesso.indexOf(9) !== -1 ?

                        <Link to="/relatorio-horas-planejadas" className="box-relatorios">
                            <div>
                                <p>Horas Planejadas X Realizadas</p>
                                <i className="fa fa-file-text-o fa-2x" aria-hidden="true"></i>
                            </div>
                        </Link>
                        : null}



                    {this.state.user.acesso.indexOf(3) !== -1 ||
                        this.state.user.acesso.indexOf(4) !== -1 ?

                        <Link to="/relatorios-protheus" className="box-relatorios">
                            <div>
                                <p>Protheus</p>
                                <i className="fa fa-file-text-o fa-2x" aria-hidden="true"></i>
                            </div>
                        </Link>
                        : null}
                </div>
            </React.Fragment>

        )
    }
}
