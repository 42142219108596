import React from 'react';
import Modal from '../detalhado/modal';
import { Button } from 'react-bootstrap';

export default class Cabecalho extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            modal : null,
            show : false,
            profile : this.props.profile,
            name : this.props.name,
        }

        this.callbackParent = () =>{
            this.setState({
                show: false
            })
            this.props.callbackParent();
        }
        // this.callbackParent = this.props.callbackParent;
        this.functionParent = this.props.functionParent;

        this.showModal = this.showModal.bind(this);
    }

    showModal(){
        this.setState({
            show : true,
        })
    }

    render(){
        
        return (
            <div className="row mt-5 mb-4">
                <div className="col-md-5 col-sm-5 col-4">
                    <p className="h5 text-muted"><span style={{fontWeight: 'bold'}}>Colaborador: </span>{this.state.name}</p>
                </div>
                <div className="col-md-5 col-sm-5 col-4">
                    <p className="h5 text-muted mr-5">
                        <span style={{fontWeight: 'bold'}}>Perfil: </span>
                        {this.state.profile}
                    </p>
                </div>
                <Button
                    type='button'
                    className='btn btn-sm'
                    data-toggle='modal'
                    data-target='.modal'
                    title='Apontamento detalhado'
                    onClick={this.showModal}
                    style={{
                        position: 'absolute',
                        right: '50px',
                        backgroundColor: '#720425',
                        color: '#fff'
                    }}>
                    <i className='fa fa-plus'></i>
                </Button>
                {/* <Modal
                    edit={false}
                    show={this.state.show}
                    // show={true}
                    id_timesheet={null}
                    callbackParent={() => this.callbackParent()}
                /> */}
                {
                    this.state.show  ? 
                
                    <Modal
                        edit={false}
                        // show={this.state.show}
                        show={true}
                        id_timesheet={null}
                        callbackParent={() => this.callbackParent()}
                    />
                    :
                    null
                }
            </div>
        )
    }
}