import React, { Component } from 'react';
import THeadApontamentosAdm from './theadApontamentosAdm';
import TBodyApontamentosAdm from './tbodyApontamentosAdm';
import ReactTooltip from 'react-tooltip';
import Backend from '../../../utils/backend';
import axios from 'axios';
import { getToken } from '../../../auth/auth';
import jwt_decode from 'jwt-decode';
import './tableApontamentosAdm.css';

export default class Table extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: jwt_decode(getToken()),
            apontamentos : this.props.apontamentos, 
            functionParent : this.props.functionParent, 
            stateCollapse : this.props.stateCollapse, 
            callbackParent : this.props.callbackParent, 
            handleSelectedAll : this.props.handleSelectedAll, 
            handleCheckboxClick : this.props.handleCheckboxClick,
            filtroNome : null,
            filtroProjeto : null,
            ordenar:""
        }
    }

    componentDidMount(){
        this.changeFiltro();
    }

    changeOrdenar = async (e) => {
        await this.setState({ordenar:e.target.value, adm:this.state.user.id_recurso})

        const ordenar = {
            ordenar:this.state.ordenar,
            adm:this.state.adm
        }

        axios.post(Backend("timesheet_apontamentos_adm_ordenar"), ordenar)
        .then(res=>this.setState({apontamentos:res.data}))
    }

    changeFiltroNome = async (e) =>{
        let filtroNome = e.target.value;
        if(filtroNome.length === 0)
            filtroNome = null;
        await this.setState({
            filtroNome
        })
        this.changeFiltro();        
    }

    changeFiltroProjeto = async (e) =>{
        let filtroProjeto = e.target.value;
        if(filtroProjeto.length === 0)
            filtroProjeto = null;
        await this.setState({
            filtroProjeto
        })
        this.changeFiltro();  
    }
    
    changeFiltro = () => {
        const filtro = {
            "adm" : this.state.user.id_recurso,
            "nome" : this.state.filtroNome,
            "projeto" : this.state.filtroProjeto
        }
        axios.post(Backend(`timesheet_apontamentos_adm`), filtro, {
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            this.setState({ apontamentos: response.data.apontamentos });
        });
    }

    render(){

        return(
            <div>
                <div className="row">
                    <div className="col-md-2 col-sm-2 col-2">
                        <input placeholder="Colaborador" type="text" onChange={this.changeFiltroNome} id="filtro-nome" className="form-control input-search" />
                    </div>
                    <div className="col-md-2 col-sm-2 col-2">
                        <input placeholder="Projeto" type="text" onChange={this.changeFiltroProjeto} id="filtro-projeto" className="form-control input-search" />
                    </div>

                    <div className="col-md-3 col-sm-2 col-2 orderby-div">
                        <label htmlFor="">Ordenar Por:</label>
                        <select className="form-control" onChange={this.changeOrdenar} value={this.state.ordenar}>
                            <option value=""></option>
                            <option value="az">Nome A-Z</option>
                            <option value="za">Nome Z-A</option>
                            <option value="asc">Data Crescente</option>
                            <option value="desc">Data Decrescente</option>
                        </select>
                    </div>
                </div>
                {
                    this.state.apontamentos.length === 0 ? 
                        <h2>Sem apontamentos para este período</h2> 
                    :
                        <div>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <div className="group">
                                        <div className="circulo em-aprovacao"> <span className="legenda">Em aprovação</span></div>
                                        <div className="circulo rejeitado"> <span className="legenda">Rejeitado <i className="fa fa-info-circle zoom15" data-tip data-for={`tooltip`} aria-hidden="true"></i></span></div>
                                    </div>
                                </div>
                            </div>
                
                            <div className="table-responsive col-md-11" style={{marginLeft:'-15px'}}>
                                <table className="table table-bordered" cellSpacing="0" cellPadding="0" id="lista">
                                    <THeadApontamentosAdm handleCheckboxClick={this.state.handleCheckboxClick}/>
                                    <TBodyApontamentosAdm
                                        functionParent={this.state.functionParent}
                                        stateCollapse={this.state.stateCollapse}
                                        callbackParent2={this.state.callbackParent}
                                        apontamentos={this.state.apontamentos}
                                        handleSelectedAll={this.state.handleSelectedAll}
                                    />
                                </table>
                            </div>
                
                            <ReactTooltip id={`tooltip`} aria-haspopup='true' effect="solid">
                                <p>Passe o mouse sobre o apontamento para ver mais detalhes sobre a rejeição</p>
                            </ReactTooltip>
                        </div>
                    }
            </div>
        )
    }
}