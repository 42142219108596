/* Arquivo base para o bundle */
/* Criado por Carlos Eduardo em 03/01/2019 */
/* Em caso de dúvidas: contato.carlos@outlook.com */

// import 'modules/bootstrap/dist/css/bootstrap.min.css'; //import do bootstrap 4, comentado por gerar conflito
import '../../node_modules/font-awesome/css/font-awesome.min.css';
import '../assets';

import React, { Component } from 'react';
import Routes from './routes';
import { Provider } from 'react-redux';

import store from '../store';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


toast.configure({
  autoClose: 3000,
  draggable: false,
  //etc you get the idea
});

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    )
  }
}

export default App;
