import React, { Component } from 'react';

import { getToken } from '../../../../auth/auth';
import jwt_decode from 'jwt-decode';

import { bindActionCreators } from 'redux';
import * as editProjectActions from '../../../../store/actions/editHoraProjeto';
import * as sendProjColActions from '../../../../store/actions/sendProjCol';
import { connect } from 'react-redux';

import Thead from './Thead';
import Tbody from './Tbody';
import './Table.css';

class Table extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: jwt_decode(getToken()),
            colaboradores: this.props.colaboradores,
            idProjeto: this.props.idProjeto,
            // filterColaboradores: this.props.colaboradores.filter((e, i) => i < 10),
            filtro: '',
            qtdFiltro: 10
        }
    }

    componentDidMount = () => {
        this.props.sendFilteredsToStore(this.state.colaboradores.filter((e, i) => i < 10))
    }

    changeSearch = async (e, more, clear) => {
        let filtro;
        if (clear) filtro = ''
        else if (more) filtro = this.state.filtro || '';
        else filtro = e.target.value.toLowerCase();
        const filtered = this.state.colaboradores.filter(e => {
            return e.recurso.toLowerCase().includes(filtro)
        })
        this.setState({
            // filterColaboradores: filtered.filter((e, i) => i < this.state.qtdFiltro),
            // qtdFiltro: 20,
            filtro,
        }, () => this.props.sendFilteredsToStore(filtered.filter((e, i) => i < this.state.qtdFiltro)))
    }

    carregarTabela = () => {
        let qtd = this.state.qtdFiltro + 10;
        this.setState({ qtdFiltro: qtd }, () => this.changeSearch(null, true))
    }

    render() {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-4 col-sm-12 col-12 pesquisar-div">
                        <input
                            placeholder="Pesquisar"
                            type="text"
                            onChange={this.changeSearch}
                            id="filtro-nome"
                            value={this.state.filtro}
                            className="form-control input-search" />
                        {
                            this.state.filtro !== '' ?
                                <div onClick={() => this.changeSearch(null, false, true)}>
                                    <i className="fa fa-times zoom-15" aria-hidden="true"></i>
                                </div> :
                                <i className="fa fa-search zoom-15" aria-hidden="true"></i>
                        }
                    </div>
                </div>
                <div>
                    <div className="table-responsive col-md-8" style={{ marginLeft: '-15px' }}>
                        <table
                            className="table"
                            cellSpacing="0" cellPadding="0" id="lista">
                            <Thead />
                            <Tbody
                                idProjeto={this.state.idProjeto}
                            />
                        </table>
                        <div className="load-more-prj">
                            <button className="btn btn-carregar"
                                onClick={this.carregarTabela}
                                disabled={this.state.qtdFiltro > this.props.colaboradores.length}
                            >
                                Carregar Mais
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    editHoraProjeto: state.editHoraProjeto,
    colaboradores: state.colaboradores.colaboradores,
    colaboradoresFiltrados: state.colaboradores.colaboradoresFiltrados,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ ...editProjectActions, ...sendProjColActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Table);