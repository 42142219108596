/* Serviços de autenticação */
/* Criado por Carlos Eduardo em 04/01/2019 */
/*Métodos para niveis de usuários adiconados por Leandro Paranhos 20/03/2019 */

export const TOKEN_KEY = "@timesheet-token"; //nome do token
export const LEVEL_USER = "@level-user"
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null || sessionStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY) || sessionStorage.getItem(TOKEN_KEY);
export const getlevelUser = () => localStorage.getItem(LEVEL_USER) || sessionStorage.getItem(LEVEL_USER);
export const login =  (token, keep = false) => {
  if(keep){
    localStorage.setItem(TOKEN_KEY, token);  
  }
  else {
    sessionStorage.setItem(TOKEN_KEY, token);
  }
  window.location.reload();
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(TOKEN_KEY);
  window.location.reload();
};
