import React, { Component } from 'react';
import { getToken } from '../../auth/auth';
import jwt_decode from 'jwt-decode';

import './loading.css'
import { Redirect } from 'react-router-dom';
export default class Loading extends Component {

    constructor() {
        super();
        this.state = {
            user: jwt_decode(getToken()),
            redirect: false,
            rediretFor: ''
        };
      }

    // state = {
    //     user: getToken(), redirect: false, rediretFor: ''
    // }

    componentDidMount() {
        
        setTimeout(() => {
            if(this.state.user.acesso.indexOf(1) !== -1)
                this.setState({ rediretFor: '/home', redirect: true })
            else if(this.state.user.acesso.indexOf(2) !== -1)
                this.setState({ rediretFor: '/aprovacao', redirect: true })
            else if(this.state.user.acesso.indexOf(5) !== -1 || this.state.user.acesso.indexOf(8) !== -1)
                this.setState({ rediretFor: '/cadastro', redirect: true })
            else if(this.state.user.acesso.indexOf(3) !== -1 || this.state.user.acesso.indexOf(4) !== -1)
                this.setState({ rediretFor: '/relatorios-protheus', redirect: true })
            else if(this.state.user.acesso.indexOf(6) !== -1 || this.state.user.acesso.indexOf(7) !== -1)
                this.setState({ rediretFor: '/relatorios', redirect: true })
            else
            this.setState({ rediretFor: '/login', redirect: true })
        }, 1200)
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.rediretFor} />
        }
        else {
            return (
                <div >
                    <div className="cssload-loader" style={{ zoom: '3', marginTop: 22 }}>
                        <div className="cssload-inner cssload-one"></div>
                        <div className="cssload-inner cssload-two"></div>
                        <div className="cssload-inner cssload-three"></div>
                    </div>
                    <div className="row col-md-12" style={{ marginTop: 10, textAlign: 'center' }}>
                        <h2 style={{ fontWeight: 'bold' }}>Seja bem vindo, estamos construindo seu ambiente !</h2>
                    </div>
                </div>
            )
        }
    }
}
