import React from 'react';

const THead = () => {
    return (
        <>
            <thead>
                <tr>
                    <th className="th-black text-center col">Colaborador</th>
                    <th className="th-black text-center col">Horas realizadas</th>
                    <th className="th-black text-center col">Saldo banco de horas</th>
                </tr>
            </thead>
        </>
    )
}

export default THead;