/* Arquivo de configuracao de rotas */

import React, { Component } from 'react';
import { Redirect, Route, Switch, HashRouter } from 'react-router-dom';

import { getToken } from "../auth/auth";
import Config from '../config';
import jwt_decode from 'jwt-decode';

import Home from '../pages/home/home';
import Login from '../pages/login/login';
import Loading from '../pages/login/loading';
// import MenuAdm from '../template/menuAdm';
import MenuUser from '../template/menuUser';
import Aprovacao from '../pages/aprovacaoHoras/aprovacao';
import RelatoriosProtheus from '../pages/relatoriosProtheus/relatorios';
import RelatorioHorasBanco from '../pages/relatoriosHoras/relatorioHorasBanco';
import RelatorioHorasProjeto from '../pages/relatoriosHoras/relatorioHorasProjeto';
import RelatorioHorasPlanejadas from '../pages/relatoriosHoras/relatorioHorasPlanejadas';
import CadastroPRJ from '../pages/exports/CadastroPRJ';
import Cadastro from '../pages/exports/Cadastro';
import CadastroCol from '../pages/exports/CadastroCol';
import CadastroFri from '../pages/exports/CadastroFri';
import Relatorios from '../pages/relatoriosHoras/relatorios';
import GerenciamentoHoras from '../pages/gerencamentoHoras/gerenciamentoHoras';
import EditarHorasProjeto from '../pages/gerencamentoHoras/editarHoraProjeto/editarHoraProjeto';



class Routes extends Component {
  componentDidMount = async () => {
    fetch(Config('AuthURL'), { headers: { 'Authorization': `Bearer ${getToken()}` } })
      .then(resp => resp.json())
      .then(data => this.setState({ success: data.success }));

    // if(getToken()){
    //   await this.setState({ user : jwt_decode(getToken()) })
    //   // await axios.post(Backend(`has_notification`), {"user": this.state.user.id_recurso}, {
    //   //   headers: { 'Content-Type': 'application/json' }
    //   // }).then(async (response) => {
    //   //   await this.setState({ hasNotification : response.data }) ;
    //   // });

    //   // await axios.post(Backend(`has_notification_adm`), {"user": this.state.user.id_recurso}, {
    //   //   headers: { 'Content-Type': 'application/json' }
    //   // }).then(async (response) => {
    //   //   await this.setState({ hasNotificationAdm : response.data }) ;
    //   // });
    // }
  }

  constructor() {
    super();
    this.state = {
      success: true,
    };
  }

  render() {
    const PrivateRouteUser = ({ access, component: Component, ...rest }) => (
      <Route

        {...rest}
        render={props => {
          return (getToken() && access.some(r => jwt_decode(getToken()).acesso.includes(r))) ? (
            <MenuUser>
              <Component {...props} />
            </MenuUser>
          ) : (
              <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            )
        }}
      />
    );

    // const PrivateRouteAdm = ({ component: Component, ...rest }) => (
    //   <Route
    //     {...rest}
    //     render={props =>
    //       (this.state.user && this.state.user.id_grupo === 1 ) ? (
    //         <MenuAdm
    //           hasNotification={this.state.hasNotification}
    //           hasNotificationAdm={this.state.hasNotificationAdm}
    //         >
    //           <Component {...props} />
    //         </MenuAdm>
    //       ) : (
    //           <Redirect to={{ pathname: "/", state: { from: props.location } }} />
    //         )
    //     }
    //   />
    // );
    return (
      <HashRouter>
        <Switch>
          <PrivateRouteUser access={[1]} exact path="/home" component={Home} />
          <PrivateRouteUser access={[2]} exact path="/aprovacao" component={Aprovacao} />
          <PrivateRouteUser access={[3, 4]} exact path="/relatorios-protheus" component={RelatoriosProtheus} />
          <PrivateRouteUser access={[5, 8, 10]} exact path="/cadastro" component={Cadastro} />
          <PrivateRouteUser access={[5]} exact path="/cadastro_projetosADM" component={CadastroPRJ} />
          <PrivateRouteUser access={[8]} exact path="/cadastro_colaborador" component={CadastroCol} />
          <PrivateRouteUser access={[10]} exact path="/cadastro_feriado" component={CadastroFri} />
          <PrivateRouteUser access={[3, 4, 6, 7, 9]} exact path="/relatorios" component={Relatorios} />
          <PrivateRouteUser access={[6]} exact path="/relatorio-horas-banco" component={RelatorioHorasBanco} />
          <PrivateRouteUser access={[7]} exact path="/relatorio-horas-projeto" component={RelatorioHorasProjeto} />
          <PrivateRouteUser access={[9]} exact path="/relatorio-horas-planejadas" component={RelatorioHorasPlanejadas} />
          <PrivateRouteUser access={[9]} exact path="/gerenciamento-horas" component={GerenciamentoHoras} />
          <PrivateRouteUser access={[9]} exact path="/editar-horas-projeto" component={EditarHorasProjeto} />
          {/* <PrivateRouteUser exact path="/relatorio_horas_projeto" component={CadastroCol} /> */}
          {/* <PrivateRouteUser exact path="/importarUser" component={Imports} />
          <PrivateRouteUser exact path="/resultadosuser" component={Resultado} />
          <PrivateRouteUser exact path="/exportarUser" component={Exports} /> */}
          {/* <PrivateRouteUser exact path="/home" component={Home} />
          <PrivateRouteUser exact path="/aprovacao" component={Aprovacao} /> */}
          {/* <PrivateRouteUser exact path="/relatorios" component={Relatorios} /> */}
          {/*   <PrivateRouteAdm exact path="/exportar" component={Exports} />
          <PrivateRouteAdm exact path="/resultados" component={Resultado} />
          <PrivateRouteAdm exact path="/arquivosupload" component={ArquivosUpload} />
          <PrivateRouteAdm exact path="/logsistema" component={LogSistema} />
          <PrivateRouteAdm exact path="/configuracoes" component={Settings} />
          <PrivateRouteAdm exact path="/settings/users" component={Users} />
          <PrivateRouteAdm exact path="/settings/resultados" component={Resultados} />
          <PrivateRouteAdm exact path="/settings/resultados/add" component={ResultadoAdd} />
          <PrivateRouteAdm exact path="/settings/resultados/edit/:id" component={ResultadoEdit} />
          <PrivateRouteAdm exact path="/settings/mascaras" component={Mascaras} />
          <PrivateRouteAdm exact path="/settings/status" component={Status} /> */}
          <Route exact path="/loading" component={Loading} />
          <Route exact path="/login" component={Login} />

          <Redirect from='*' to='/login' />
        </Switch>
      </HashRouter>
    )
  }
}

export default Routes;
