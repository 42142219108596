import React from 'react';
import THeadExports from './theadE';
import TBodyExports from './tbody-fixed';
import TFooterExports from './tfooter-fixed';
import {ProjetoProvider} from "./ProjectContext";
import './table.css';

const Table = (props) => {
    return (
        <div className="table-responsive  col-md-11" style={{marginLeft:'-15px'}}>
            <table className="table-home table table-bordered table-striped" cellSpacing="0" cellPadding="0">
                <ProjetoProvider>
                    <THeadExports intervalo={props.intervalo} />
                    <TBodyExports horas={props.horas} intervalo={props.intervalo}/>
                    <TFooterExports intervalo={props.intervalo} total={props.total} />
                </ProjetoProvider>
            </table>
        </div>
    )
}
export default Table;