
import React, { Component } from 'react';
import Title from '../../template/title';
import Backend from '../../utils/backend';
import axios from 'axios';
import { getToken, isAuthenticated } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import TableApontamentosAdm from './tableApontamentos/tableApontamentosAdm';
import Swal from 'sweetalert2'
import "./aprovacao.css";

export default class Home extends Component {

  constructor() {
    super();
    if(!isAuthenticated()) window.location.href = "";
    this.state = { 
      user: jwt_decode(getToken()),
      apontamentos : [],
      collapse: false,
      motivo : null,
      loading : false
    };
    this.toggle = this.toggle.bind(this);
    this.handleSelectedAll = this.handleSelectedAll.bind(this);
    this.verificaCheckboxes = this.verificaCheckboxes.bind(this);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  handleProjeto(id_recurso){
    return { "adm": id_recurso }
  }

  handleBusca = () => {
    this.setState({loading : true})
    axios.post(Backend(`timesheet_apontamentos_adm`), { "adm": this.state.user.id_recurso }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      this.setState({ apontamentos: response.data.apontamentos, loading: false });
    });
  }

  // componentDidMount = () => {    
  //   this.handleBusca();
  // }

  verificaCheckboxes(boolean, isArray = null, tipo = null){
    const checkboxes = document.getElementsByClassName('checkbox-registro');
    let value = {
      boolean : !boolean,
      quantidade : 0,
      motivo : null
    }
    let array = null;

    if (isArray) array = [];

    for(let checkbox in checkboxes){
      if(checkboxes[checkbox].checked !== undefined){
        if( checkboxes[checkbox].checked === boolean){
          if(isArray) array.push(checkboxes[checkbox].value)
          else if(tipo !== null){
            if(checkboxes[checkbox].name !== tipo) {
              value.boolean = boolean;
              value.quantidade++;
            }
          }
          else{
            value.boolean = boolean;
            value.quantidade++;
          }
        }
      }
    }
    return array || value;
  } 

  handleAprovar() {
    const array_id_timesheet = this.verificaCheckboxes(true, true);
    document.getElementById('checkAll').click()
    document.getElementById('checkAll').click()

    axios.post(Backend(`aprovar_registro_timesheet_adm`), { "array_id_timesheet": array_id_timesheet }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      if(response) {
        this.handleBusca();
      }
      else
        console.log("Erro ao aprovar o registro");
    });
  }

  handleRejeitar(motivo, data) {
    const array_id_timesheet = this.verificaCheckboxes(true, true);
    document.getElementById('checkAll').click()
    document.getElementById('checkAll').click()
    axios.post(Backend(`rejeitar_registro_timesheet_adm`), { 
      "array_id_timesheet": array_id_timesheet,
      "motivo": motivo,
      "data" : data
     }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      if(response) {
        this.handleBusca();
      }
      else console.log("Erro ao rejeitar o registro");
    });
  }

  handleEditar(motivo, data) {
    const array_id_timesheet = this.verificaCheckboxes(true, true);
    document.getElementById('checkAll').click()
    document.getElementById('checkAll').click()
    axios.post(Backend(`editar_registro_timesheet_adm`), { 
      "array_id_timesheet": array_id_timesheet,
      "motivo": motivo,
      "data" : data
     }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      if(response) {
        this.handleBusca();
      }
      else console.log("Erro ao editar o registro");
    });
  }

  swalErro() {
    Swal.fire({
      title: 'Erro!',
      text: "Ocorreu um erro, informe o suporte.",
      type: 'error',
      confirmButtonColor: '#720425',
    });
  }

  handleSelectedAll = () => {
    // Verifica se todos os checkboxes estão selecionados ou não
    document.getElementById('checkAll').checked = this.verificaCheckboxes(false).boolean;
    document.getElementById('botao-rejeitar').disabled = this.verificaCheckboxes(true, null, 'em-aprovacao').boolean;
    document.getElementById('botao-editar').disabled = this.verificaCheckboxes(true, null, 'rejeitado').boolean;

    this.motivoSelect()
    if(((document.getElementById('botao-rejeitar').disabled === true)
    && (document.getElementById('botao-editar').disabled === true))
    || ((document.getElementById('botao-rejeitar').disabled === false)
    && (document.getElementById('botao-editar').disabled === false))){
      document.getElementById('motivo').value = '';
    }
  }

  motivoSelect = () => {
    let motivo = null;
    const selecionados = this.verificaCheckboxes(true, true);
    if(selecionados.length === 1){
      if(document.getElementById(`id${selecionados[0]}`).name === 'rejeitado'){
        document.getElementById('motivo').value = document.getElementById(`motivo${selecionados[0]}`).value;
      }
    }
    else if(selecionados.length > 1){
      selecionados.map(element => {
        if(document.getElementById(`id${element}`).name === 'rejeitado'){
          if(motivo === null) motivo = document.getElementById(`motivo${element}`).value;
          else if( document.getElementById(`motivo${element}`).value === motivo){
            document.getElementById('motivo').value = motivo;
          }
          else{
            document.getElementById('motivo').value = '';
          }
        }
        return 0;
      })
    }
  }

  componenteMotivo = () =>{ 
    return (
      <>
        <label htmlFor="motivo" className="text15">Motivo da rejeição:</label>
        <textarea id="motivo" className="form-control mb-3" rows="3"></textarea>
      </>
    )
  }

  handleCheckboxClick = () => {
    const checkboxes = document.getElementsByClassName('checkbox-registro');
    const checked = document.getElementById('checkAll').checked;
  
    for(let checkbox in checkboxes)
      if(checkboxes[checkbox].checked !== undefined)
        checkboxes[checkbox].checked = checked;
    this.handleSelectedAll()
  }

  handleSwal = (tipo) => {
    const algum_selecionado = this.verificaCheckboxes(true).boolean;
    const motivo = document.getElementById('motivo').value;
    if(algum_selecionado){
      if(motivo.trim().length === 0 && tipo !== 1){
        Swal.fire({
          title: 'Digite o motivo da rejeição!', text: "Digite o motivo da rejeição!", 
          type: 'warning', confirmButtonColor: '#720425'
        });
      }else{
        return Swal.fire({
          title: 'Você tem certeza?', text: "Você não poderá reverter esta ação!",
          type: 'warning', showCancelButton: true, showCloseButton: true,
          reverseButtons: true, confirmButtonColor: '#720425', cancelButtonColor: '#3085d6',
          cancelButtonText: 'Não', confirmButtonText: 'Sim'
        }).then((result) => {
          if (result.value) {
            const data = new Date();
            if(tipo === 1){          
              this.handleAprovar();
              Swal.fire({
                title: 'Aprovado!', text: "Registro(s) aprovado(s).",
                type: 'success', confirmButtonColor: '#720425',
              });
            }
            else if(tipo === 2){
              if(!this.handleRejeitar(motivo, data)){
                Swal.fire({
                  title: 'Rejeitado!', text: "Registro(s) rejeitado(s).",
                  type: 'success', confirmButtonColor: '#720425',
                });
              }
              else this.swalErro();
            }else if(tipo === 3){
              this.handleEditar(motivo, data);
              Swal.fire({
                title: 'Editado!', text: "Registro(s) editado(s).",
                type: 'success', confirmButtonColor: '#720425',
              });
            }
          }          
        });
      }      
    }else{
      Swal.fire({
        title: 'Selecione o registro', text: "Nenhum registro selecionado.",
        type: 'warning', confirmButtonColor: '#720425',
      });
    }    
  }

  render() {
    
    return (
      <div>
        <div className="animated fadeInRight home">
          <Title name="Detalhado" />
          <div className="container">
            <div className="row">
              <div className="col-md-7 mt15" >
                {this.componenteMotivo()}
                <button type="button" id="botao-aprovar" onClick={() => this.handleSwal(1)} className="btn btn-aprovar mt15 mr15">Aprovar</button>
                <button type="button" id="botao-rejeitar" onClick={() => this.handleSwal(2)} className="btn btn-danger mt15 mr15">Rejeitar</button>
                <button type="button" id="botao-editar" onClick={() => this.handleSwal(3)} className="btn btn-info mt15 mr15">Editar</button>
              </div>              
            </div>

            <div className="row mt-5">
              <h2 style={{marginLeft:'15px'}}> Detalhe do apontamento </h2>
              <br />
            </div>
            {
              !this.state.loading ? 
              <TableApontamentosAdm
                functionParent={() => this.toggle()}
                stateCollapse={this.state.collapse}
                callbackParent={() => this.handleFiltrar()}
                handleSelectedAll={() => this.handleSelectedAll()}
                handleCheckboxClick={() => this.handleCheckboxClick()}
                apontamentos={this.state.apontamentos}
              />
            : null
            }
            
          
          </div>
        </div>
      </div>
    )
  }
}
