import React from 'react';

const THeadApontamentos = (props) => {

    const {handleCheckboxClick} = props;
    return (
        <>
            <thead>
                <tr>
                    <th className="th-black text-center fixo0"><input 
                        onClick={() => handleCheckboxClick()}
                        id="checkAll"
                        type="checkbox"
                    /></th>
                    <th className="th-black text-center fixo2">Nome</th>
                    <th className="th-black text-center fixo1">Data</th>
                    <th className="th-black text-center fixo1">Hora inicial</th>
                    <th className="th-black text-center fixo1">Hora final</th>
                    <th className="th-black text-center fixo2">Projeto</th>
                    <th className="th-black text-center fixo2">Descrição</th>
                </tr>
            </thead>
        </>
    )
}

export default THeadApontamentos;