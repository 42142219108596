import React from 'react';


const TBody = (props) => {

    const { macro } = props

    return (
        <tbody className="text-center">

            {
                macro.map( (element, i) => {
                    return (
                        <tr key={i}>
                            <td>{element.nome}</td>
                            <td>{element.horas_trabalhadas}</td>
                            <td>{element.banco_horas}</td>
                        </tr>
                    )
                })
            }
            
        </tbody>
    )
}

export default TBody;