import React, { Component } from 'react';

export default class Footer extends Component{


    render(){
        return(
            <div className = "footer" >
            <div>
                 2019 &copy; <strong>Timesheet by Cellere  </strong> - Todos os diretos reservados
            </div>
        </div>
        );
    }
}