import React from 'react';
import THeadApontamentos from './theadApontamentos';
import TBodyApontamentos from './tbodyApontamentos';
import './tableApontamentos.css'

const Table = (props) => {
    const { apontamentos, functionParent, stateCollapse, callbackParent, loading } = props;
    
    if(apontamentos.length === 0) return <h2>Sem apontamentos para este período</h2>;
    return (
        <div>
            <div className="row mb-3">
                <div className="group">
                    <div className="circulo aprovado"><span className="legenda" >Aprovado</span></div>
                    <div className="circulo em-aprovacao"> <span className="legenda">Em aprovação</span></div>
                    <div className="circulo rejeitado"> <span className="legenda">Rejeitado</span></div>
                </div>
            </div>

            <div className="table-responsive col-md-11" style={{marginLeft:'-15px'}}>
                <table className="table table-bordered" cellSpacing="0" cellPadding="0">
                 
                        <THeadApontamentos/>
                        { !loading ? <TBodyApontamentos
                            functionParent={functionParent}
                            stateCollapse={stateCollapse}
                            callbackParent={callbackParent}
                            apontamentos={apontamentos}
                        /> : null}
                </table>
            </div>
        </div>
        
    )
}
export default Table;