import React, { Component } from 'react';
import  "./cadastro.css";
import {Link} from "react-router-dom";
import axios from "axios";
import { getToken } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import Backend from "../../utils/backend";




export default class Cadastro extends Component {

    constructor()
    {
        super()

        this.state = {
            user: jwt_decode(getToken()),
            permList:[]
        }
    }

    componentDidMount()
    {
        axios.get(Backend(`get_permissao/${this.state.user.id_recurso}`))
        .then(res=>this.setState({permList:res.data}))
    }

    render() {

        const {permList} = this.state;
        return (
            <div>
                <div className="cadastro animated fadeInRight">
                    {permList.includes(5) ? 
                        <Link to="/cadastro_projetosADM" className="box">
                            <div>
                                <p>Cadastro de Projetos</p>
                                <i className="fa fa-file-text-o fa-2x" aria-hidden="true"></i>
                            </div>
                        </Link> : null
                    }

                    {permList.includes(8) ?
                        <Link to="/cadastro_colaborador" className="box">
                            <div>
                                <p>Cadastro de Colaboradores</p>
                                <i className="fa fa-users fa-2x" aria-hidden="true"></i>
                            </div>
                        </Link> : null
                    }

                    {permList.includes(10) ?
                        <Link to="/cadastro_feriado" className="box">
                            <div>
                                <p>Cadastro de Feriados (Exceções)</p>
                                <i className="fa fa-calendar-times-o fa-2x" aria-hidden="true"></i>
                            </div>
                        </Link> : null
                    }                 
                </div>
            </div>
        )
    }
}
