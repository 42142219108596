import React, { Component } from 'react';
import Title from '../../template/title';
import Backend from '../../utils/backend';
import axios from 'axios';
import { getToken } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import DatePicker, { registerLocale } from 'react-datepicker';
import pt_BR from "date-fns/locale/pt-BR";
import Swal from 'sweetalert2';
import Table from './table/table';
import "react-datepicker/dist/react-datepicker.css";
import "./relatorios.css"
registerLocale("pt-BR", pt_BR);
const moment = require('moment');

export default class Relatorios extends Component{

  pad = (number, length) => {   
    let str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }       
    return str;    
  }

  componentDidMount = () => {
    this.buscarColaboradores(null, this.state.id_grupo, this.state.id_empresa);
    this.buscarGrupos(this.state.id_colaborador, null, this.state.id_empresa); 
    this.buscarEmpresas(this.state.id_colaborador, this.state.id_grupo, null);
    this.buscarTiposRelatorios();
    this.buscarRelatorios();
  }

  constructor(){
    super()
    this.state = { 
      user: jwt_decode(getToken()),
      startDate: new Date(),
      endDate: new Date(),
      colaboradores: [],
      id_colaborador: null,
      grupos: [],
      id_grupo: null,
      tipos_relatorios: [],
      id_tipo_relatorio: "1",
      txtMessage: null,
      txtStatus: 0,
      csv2Message: null,
      csv2Status: 0,
      id_gerado: null,
      loading: false,
      relatorios : [],
      empresaList:[],
      id_empresa:null
    };
    this.handleChangeGrupo = this.handleChangeGrupo.bind(this)
  }

  buscarTiposRelatorios = () =>{
    axios.post(Backend(`tipos_relatorios`), { tipo: 1 }).then((response) => {
      this.setState({ tipos_relatorios: response.data });
    });
  }

  handleChangeDate(date, periodo) {
    if(periodo === 1) this.setState({ startDate: date });
    else this.setState({ endDate: date });
  }

  mudarTipoRelatorio = e => {
    this.setState({
      id_tipo_relatorio: e.target.value
    })
  }

  limparCampos = async () => {
    await this.setState({
      startDate: new Date(),
      endDate: new Date(),
      colaboradores: [],
      id_colaborador: null,
      id_tipo_relatorio: "1",
      grupos: [],
      id_grupo: null,
      empresaList: [],
      id_empresa: null,
      txtMessage: null,
      csv2Message: null,
      csv2Status: 0,
      txtStatus: 0,
    })
    this.buscarColaboradores();
    this.buscarGrupos();
    this.buscarEmpresas();
    document.getElementById('tipo').value = "1";
  }

  buscarColaboradores = (id_colaborador = null, id_grupo = null, id_empresa = null) => {
    axios.post(Backend(`colaboradores_ativos`), {
      id_colaborador,
      id_grupo,
      id_empresa,
      id_gestor: this.state.user.id_recurso 
    }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      this.setState({
        colaboradores : response.data
      });
      if(response.data.length === 1){
        this.setState({
          id_colaborador : response.data[0].id_recurso
        });
      }else{
        this.setState({
          id_colaborador
        });
      }
    });
  }

  buscarGrupos = (id_colaborador = null, id_grupo = null, id_empresa = null) => {
    axios.post(Backend(`grupos`), {
      id_colaborador,
      id_grupo,
      id_empresa,
      id_gestor: this.state.user.id_recurso
    }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      this.setState({
        grupos : response.data
      });
      if(response.data.length === 1){
        this.setState({
          id_grupo : response.data[0].id_grupo
        });
      }else{
        this.setState({
          id_grupo
        });
      }
    });
  }

  buscarEmpresas = (id_colaborador = null, id_grupo = null, id_empresa = null) => {
    axios.post(Backend(`get_empresa`), {
      id_colaborador,
      id_grupo,
      id_empresa,
      id_gestor: this.state.user.id_recurso 
    }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      this.setState({
        empresaList : response.data,
      });
      if(response.data.length === 1){
        this.setState({
          id_empresa : response.data[0].id_empresa
        });
      }else{
        this.setState({
          id_empresa
        });
      }
    });
  }

  buscarRelatorios = () => {
    this.setState({loading: true});
    axios.post(Backend(`relatorios`), { tipo: 1 }).then((response) => {
      this.setState({
        relatorios : response.data,
        loading : false
      });
    });
  }

  handleChangeEmpresa = (e) => {
    let id_empresa = e.target.value;
    if(id_empresa === 'todos'){
      this.buscarColaboradores();
      this.buscarGrupos();
      this.buscarEmpresas();
      this.setState({
        id_grupo: null,
        id_colaborador: null,
        id_empresa: null
      })
    }
    else{
      this.buscarColaboradores(this.state.id_colaborador, this.state.id_grupo, id_empresa);
      this.buscarGrupos(this.state.id_colaborador, this.state.id_grupo, id_empresa);
      this.buscarEmpresas(this.state.id_colaborador, this.state.id_grupo, id_empresa);
    }
  }

  handleChangeGrupo = (e) => {
    let id_grupo = e.target.value;
    if(id_grupo === 'todos'){
      this.buscarColaboradores();
      this.buscarGrupos();
      this.buscarEmpresas();
      this.setState({
        id_grupo: null,
        id_colaborador: null,
        id_empresa: null
      })
    }
    else{
      this.buscarColaboradores(this.state.id_colaborador, id_grupo, this.state.id_empresa);
      this.buscarGrupos(this.state.id_colaborador, id_grupo, this.state.id_empresa);
      this.buscarEmpresas(this.state.id_colaborador, id_grupo, this.state.id_empresa);
    }
  }

  handleChangeColaborador = (e) => {
    let id_colaborador = e.target.value;
    if(id_colaborador === 'todos'){
      this.buscarColaboradores();
      this.buscarGrupos();
      this.buscarEmpresas();
      this.setState({
        id_grupo: null,
        id_colaborador: null,
        id_empresa: null
      })
    }
    else{
      this.buscarColaboradores(id_colaborador, this.state.id_grupo, this.state.id_empresa);
      this.buscarGrupos(id_colaborador, this.state.id_grupo, this.state.id_empresa);
      this.buscarEmpresas(id_colaborador, this.state.id_grupo, this.state.id_empresa);
    }
  }

  gerarRelatorios = async () => {
    let trava = true;
    await this.setState({
      txtMessage: null,
      csv2Message: null,
      txtStatus: 0,
      csv2Status: 0,
    })

    const dadosRelatorio = {
      id_colaborador : this.state.id_colaborador,
      id_grupo : this.state.id_grupo,
      start : moment(this.state.startDate).format("YYYY-MM-DD"),
      end : moment(this.state.endDate).format("YYYY-MM-DD"),
      id_solicitante : this.state.user.id_recurso,
      id_gerado: this.state.id_gerado,
      id_empresa: this.state.id_empresa
    }
    const tipo = this.state.id_tipo_relatorio

    if(dadosRelatorio.start > dadosRelatorio.end){
      Swal.fire({
        title:"Atenção",
        text: "Data inicial não pode ser posterior a data final",
        type: "warning", confirmButtonColor: '#720425',
      });
    }
    else if(dadosRelatorio.start < '2019-09-16'){
      Swal.fire({
        title:"Atenção",
        text: "Data inicial não pode ser anterior ao dia 16/09/2019",
        type: "warning", confirmButtonColor: '#720425',
      });
    }
    else{

      await axios.post(Backend(`verificar_situacao_registros`), dadosRelatorio, {
        headers: { 'Content-Type': 'application/json' }
      }).then(async (response) => {
        if(response.data){
          await Swal.fire({
            title : 'Atenção', text : `Existe 1 ou mais registros que estão Em Aprovação ou Rejeitados. 
            Deseja continuar?`,
            type : 'warning', showCancelButton: true, showCloseButton: true,
            reverseButtons: true, confirmButtonColor: '#720425', cancelButtonColor: '#3085d6',
            cancelButtonText: 'Não', confirmButtonText: 'Sim'
          }).then((response) => {
            if (!response.value) {
              trava = false;
            }
          });
        }
      });
      
      if(trava){

        await axios.post(Backend(`novaLinhaTbl_relatorio_gerados`), dadosRelatorio, {
          headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
          this.setState({ 
            id_gerado: response.data.id_gerado
          });
          dadosRelatorio.id_gerado = response.data.id_gerado;
        });
        
        if(tipo === "1" || tipo === "2"){
          await Swal.fire({
            title : 'Você tem certeza?', text : `Atenção, se for gerado o relatório de
            Apontamentos dos colaboradores Entrada/Saída os registros contidos no
            relatórios serão marcados como concluídos e não estarão disponíveis
            em um futuro relatório de Apontamentos dos colaboradores Entrada/Saída.
            Deseja gerar o relatório txt?`,
            type : 'warning', showCancelButton: true, showCloseButton: true,
            reverseButtons: true, confirmButtonColor: '#720425', cancelButtonColor: '#3085d6',
            cancelButtonText: 'Não', confirmButtonText: 'Sim'
          }).then(async (result) => {
            if (result.value) {
              await axios.post(Backend(`gerar_relatorio_txt`), dadosRelatorio, {
                headers: { 'Content-Type': 'application/json' }
              }).then((response) => {
                this.setState({ 
                  txtMessage: response.data.txtMessage,
                  txtStatus: response.data.txtStatus
                });
              });
            }
          })
        }
        if(tipo === "1" || tipo === "4"){
          await axios.post(Backend(`gerar_relatorio_csv2`), dadosRelatorio, {
            headers: { 'Content-Type': 'application/json' }
          }).then((response) => {
            this.setState({
              csv2Message: response.data.csv2Message,
              csv2Status: response.data.csv2Status
            });
          });
        }
      }
      
      // Validações para o último Sweet Alert
      let text = '';
      let title = 'Relatório(s) não gerado(s)';
      let type = 'warning';
      const txtStatus = this.state.txtStatus;
      const csv2Status = this.state.csv2Status;
      
      if(txtStatus === 2 && csv2Status === 0){
        title = 'Relatório não gerado!';
        type = 'warning';
        text = `${this.state.txtMessage}`;
      }
      else if(txtStatus === 2 && (csv2Status === 1)){
        title = 'Sucesso!';
        type = 'success';
        text = `${this.state.txtMessage}\n
        ${this.state.csv2Message}`;
      }
      else if(txtStatus === 1 && csv2Status === 0){
        title = 'Sucesso!';
        type = 'success';
        text = `${this.state.txtMessage}`
      } 
      else if(txtStatus === 1 && (csv2Status === 1)){
        title = 'Sucesso!';
        type = 'success';
        text += `${this.state.txtMessage}\n
        ${this.state.csv2Message}\n`;
      }
      else if(txtStatus === 0 && (csv2Status === 1)){
        title = 'Sucesso!';
        type = 'success';
        text = `${this.state.csv2Message}`
      }

      await Swal.fire({
        title, text,
        type, confirmButtonColor: '#720425',
      });

      this.buscarRelatorios();
    }
  }

  render(){
    return (
      <>
        <div className="animated fadeInRight home">
          <Title name="Relatórios" />
          <div className="container">
            <div className="row">
              <h2 style={{marginLeft:'15px'}}> Relatórios administrativos - Protheus  </h2>
              <br />
            </div>

            {this.state.user.acesso.indexOf(3) !== -1? 
            <>
            <div className="row" style={{marginLeft: "0px", marginBottom: "20px"}}>
              <label className="mr15" htmlFor="inicio"><span className="astericoVermelho">*</span>Data inicial: </label>
              <DatePicker
                id="inicio" 
                onChange={e => this.handleChangeDate(e, 1)}
                selected={this.state.startDate}
                maxDate={this.state.endDate}
                minDate='2019-09-16'
                locale='pt-BR'
                dateFormat="dd/MM/yyyy"
                todayButton={"Hoje"}
                className="mr15"
              />
              <label className="mr15" htmlFor="fim"><span className="astericoVermelho">*</span>Data final: </label>
              <DatePicker
                id="fim" 
                onChange={e => this.handleChangeDate(e, 2)}
                selected={this.state.endDate}
                minDate={this.state.startDate}
                locale='pt-BR'
                dateFormat="dd/MM/yyyy"
                todayButton={"Hoje"}
                className="mr15"
              />
            </div>
            <div className="row" style={{marginLeft: "0px", marginBottom: "20px"}}>
              <label className="mr15" htmlFor="grupo">Centro de custo: </label>
              <select id="grupo" 
              value={this.state.id_grupo || "todos"} 
              onChange={this.handleChangeGrupo} className="p15 mr25">
                {/* {
                  this.state.grupos.length > 1 ? <option value="todos"></option>: null
                } */}
                <option value="todos">Todos</option>
                {
                  this.state.grupos.map((element, i) => {                   
                    return(
                      <option key={i} value={element.id_grupo}>{element.grupo}</option>
                    )
                  })
                }
              </select>
              <label className="mr15" htmlFor="recurso">Colaborador: </label>
              <select id="recurso"
              value={this.state.id_colaborador || 'todos'}
              onChange={this.handleChangeColaborador} className="p15 mr25">
                <option value='todos'>Todos</option>
                {
                  this.state.colaboradores.map((element, i) => {
                    return(
                      <option key={i} value={element.id_recurso}>{this.pad(element.matricula, 12) + " - "+ element.recurso}</option>
                    )
                  })
                }
              </select>
            </div>

            <div className="row" style={{marginLeft: "0px", marginBottom: "20px"}}>
            <label className="mr15" htmlFor="tipo">Empresa: </label>
              <select id="tipo" style={{width:"160px"}}
              onChange={this.handleChangeEmpresa}
              value={this.state.id_empresa || "todos"} className="p15 mr25">
                <option value="todos">Todas</option>
                {
                  this.state.empresaList.map(emp=>(
                    <option value={emp.id_empresa}>{emp.empresa}</option>
                  ))
                }
              </select>

              <label className="mr15" htmlFor="tipo">Relatórios: </label>
              <select id="tipo" onChange={this.mudarTipoRelatorio} className="p15 mr25">
                <option value="1">Todos</option>
              {
                this.state.tipos_relatorios.map((element, i) => {
                  return(
                    <option key={i} value={element.id_relatorio}>{element.nome_relatorio}</option>
                  )
                })
              }
              </select>
            </div>
            <div className="row" style={{marginLeft: "0px", marginBottom: "20px"}}>
              <button onClick={this.limparCampos} className="btn mr15 btn-danger">Limpar filtro</button>
              <button onClick={this.gerarRelatorios} className="btn mr15 btn-gerar">Gerar relatório</button>
            </div>
            <div className="row">
              <h2 style={{marginLeft:'15px'}}> Relatórios </h2>
              <br />
            </div>
            </>:null}
            <Table relatorios={this.state.relatorios} />
         
          </div>
        </div>
      </>
    )
  }
}