import React, {useState, createContext} from "react";
export const ProjetoContext = createContext();

export const ProjetoProvider = (props) => {
    const [projetos, setProjetos] = useState([
        {id:1, horaEntrada:"12:00", horaSaida:"20:00", projetoA:"2:00", projetoB:"2:00", projetoC:"4:00", total:"8:00", data:"08/04/2019"},
        {id:2, horaEntrada:"10:00", horaSaida:"18:00", projetoA:"3:00", projetoB:"5:00", projetoC:"0:00", total:"8:00", data:"09/04/2019"},
        {id:3, horaEntrada:"12:00", horaSaida:"20:00", projetoA:"2:00", projetoB:"2:00", projetoC:"4:00", total:"8:00", data:"10/04/2019"},
        {id:4, horaEntrada:"9:00", horaSaida:"17:00", projetoA:"4:00", projetoB:"4:00", projetoC:"0:00", total:"8:00", data:"11/04/2019"},
        {id:5, horaEntrada:"12:00", horaSaida:"20:00", projetoA:"1:30", projetoB:"1:30", projetoC:"5:00", total:"8:00", data:"12/04/2019"},
        {id:6, horaEntrada:"12:00", horaSaida:"20:00", projetoA:"1:00", projetoB:"1:00", projetoC:"6:00", total:"8:00", data:"13/04/2019"},
        {id:7, horaEntrada:"8:00", horaSaida:"13:00", projetoA:"2:00", projetoB:"3:00", projetoC:"0:00", total:"5:00", data:"14/04/2019"}
    ]);

    return(
        <ProjetoContext.Provider value={[projetos, setProjetos]}>
            {props.children}
        </ProjetoContext.Provider>
            
    )
}