
import React, { Component } from 'react';

import Title from '../../template/title';
import Backend from '../../utils/backend';
import axios from 'axios';
import { getToken, isAuthenticated } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import Table from './tableApontamentos/Table';
import "./gerenciamentoHoras.css";



class Gerenciamento extends Component {

  constructor(props) {
    super(props);
    if (!isAuthenticated()) window.location.href = "";
    this.state = {
      user: jwt_decode(getToken()),
      projetos: [],
      loading: false,
      aba: 'projetos',
    };
  }

  componentDidMount = () => {
    this.handleBusca();
  }

  handleBusca = () => {
    this.setState({ loading: true })
    axios.get(Backend(`projetos_gerenciamento_horas`))
      .then((response) => {
        this.setState({ projetos: response.data.projetos, loading: false });
      });
  }

  changeAba = aba => {
    this.setState({ aba })
  }

  render() {

    return (
      <React.Fragment>
        <div className="animated fadeInRight home">
          <Title name="Gerenciamento de horas" />
          <div className="container">

            <div className="row mt-5">
              <h2 style={{ marginLeft: '15px' }}> Gerenciamento de horas </h2>
              <br />
            </div>

            <div className="row">
              <div className="col-md-7 mt15" >
                <p className="h5 font-weight-bold">
                  <button onClick={() => this.changeAba('projetos')} className={`link-aba aba-esquerda ${this.state.aba === 'projetos' ? 'aba-active' : ''}`}>Projetos</button>
                  |
                  <button onClick={() => this.changeAba('acompanhamento')} className={`link-aba aba-direita ${this.state.aba === 'acompanhamento' ? 'aba-active' : ''}`}>Acompanhamento</button>
                </p>
              </div>
            </div>
            <hr className="mt-0" />
            <br />

            {this.state.aba === 'projetos' ?

              !this.state.loading ?
                <React.Fragment>
                  <p className="h4 mt-0" style={{ fontWeight: 'bold', marginBottom: '20px' }}>Projetos</p>
                  <Table projetos={this.state.projetos} />
                </React.Fragment>
                : null
              :
              <iframe title="Dashboard" width="933" height="700" src="https://app.powerbi.com/view?r=eyJrIjoiMGNlOWNiZmUtZDg5Yy00ODMzLTg4YWYtNWMxZGYyMWI5YTEzIiwidCI6IjNlZjMyN2Q5LTY1ZTgtNDkxZC1iOGQzLTRhYWQyZmE0Yzc3NSJ9" frameborder="0" allowFullScreen="true"></iframe>
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}


export default Gerenciamento;