/* Arquivo para facilitar importacao dos dois menus */
/* Criado por Carlos Eduardo em 03/01/2019 */
/* Em caso de dúvidas: contato.carlos@outlook.com */

import React, { Component } from 'react';
import MenuLeft from './menuLeft';
import MenuTop from './menuTop';
import Footer from '../footer';

export default class Menu extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      activeIndex: 0,
    };
  }
  
  render() {
    return(
      <div id="wrapper">
        <MenuLeft />
        <div id="page-wrapper">
          <MenuTop/>
          <br />
          <br />
          <br />
          <br />
          {this.props.children}
          <Footer />
        </div>
      </div>
    )
  }
}
