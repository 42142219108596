/* Componente para facilitar a manipulação de titulo nos componentes */
/* Criado por Carlos Eduardo em 07/01/2019 */
/* Em caso de dúvidas: contato.carlos@outlook.com */
/* ex: <Title title='HomePage' /> */

import React from 'react';
import Helmet from 'react-helmet';

export default (props) => {
  return (
    <Helmet>
      <title>Timesheet - {props.name}</title>
    </Helmet>
  )
}
