import React from 'react';


const TFooterApontamentos = (props) => {

    const intervalo = props.intervalo;
    const total = props.total;
    let trava = false;
    let hr_trab = null;

    return (
        <tfoot className="text-center">
            <tr style={{backgroundColor:'#940530', color:'#fff', fontWeight: 'bold'}}>
                <td className="text-left">Total</td>
                {
                    intervalo.map((dia, i) => {
                        total.map(element => {
                            let indice = element.data_apontamento.indexOf(dia.strDataAtual);
                            if(! trava && indice !== -1) {
                                trava = true;
                                hr_trab = element.horas_trabalhadas;
                            }
                            return 0
                        })
                        if(trava) {
                            trava = false
                            return <td key={i}>{hr_trab}</td>
                        }
                        else{
                            return <td key={i}> --:-- </td>
                        }    
                    })
                }
            </tr>
        </tfoot>
    )
}

export default TFooterApontamentos;