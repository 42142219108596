import React from 'react';

const THeadExports = (props) => {
    
    const intervalo = props.intervalo;

    return (
        <thead>
            <tr>
                <th className="th-black text-center fixo">Projeto</th>
                {
                    intervalo.map((dia, i) => {
                        let d = new Date(dia.objDataAtual);
                        d = d.getDay();
                        let d_;
                        switch (d){
                            case 1:
                                d_ = "seg";
                                break;
                            case 2:
                                d_ = "ter";
                                break;
                            case 3:
                                d_ = "qua";
                                break;
                            case 4:
                                d_ = "qui";
                                break;
                            case 5:
                                d_ = "sex";
                                break;
                            case 6:
                                d_ = "sab";
                                break;
                            case 0:
                            default:
                                d_ = "dom";
                                break;
                        }
                        return(
                            <th key={i}className="th-black text-center fixoHora">{dia.strDataAtual}<br />{d_}</th>
                        )
                    })
                }              
            </tr>
        </thead>
    )
}

export default THeadExports;