/* Menu esquerdo */
/* Criado por Carlos Eduardo em 03/01/2019 */
/* Em caso de dúvidas: contato.carlos@outlook.com */

/* Adicionado redirecionamento para a tela de Consulta de Cdrs - Leandro Paranhos 17/01/2019 */


import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/cellere-logo.png';
import Icon from '../../assets/images/cellere-icon.png';

import jwt_decode from 'jwt-decode';
import Backend from '../../utils/backend';
import axios from 'axios';

import { getToken } from "../../auth/auth";
import './menu.css';

export default class Menu extends Component {
  constructor() {
    super();
    this.state = {
      activeIndex: 0,
      user: null
    };
  }
  componentDidMount = async () => {
    let currentRoute = window.location.hash.slice(2).split('/')[0];
    switch (currentRoute) {
      case 'aprovacao':
        this.setState({ activeIndex: 1 });
        break;
      case 'relatorios-protheus':
        this.setState({ activeIndex: 2 });
        break;
      case 'cadastro':
        this.setState({ activeIndex: 3 });
        break;
      case 'relatorios':
        this.setState({ activeIndex: 4 });
        break;
      case 'gerenciamento-horas':
        this.setState({ activeIndex: 5 });
        break;
      case 'home':
      default:
        this.setState({ activeIndex: 0 });
        break;
    }

    if (getToken()) {
      await this.setState({ user: jwt_decode(getToken()) })
      if (this.state.user.acesso.indexOf(1) !== -1) {
        await axios.post(Backend(`has_notification`), { "user": this.state.user.id_recurso }, {
          headers: { 'Content-Type': 'application/json' }
        }).then(async (response) => {
          if (response.data > 0)
            document.getElementById('notificacao-left').style.display = "inline-flex";
          else
            document.getElementById('notificacao-left').style.display = "none";
        });
      }


      if (this.state.user.acesso.indexOf(2) !== -1) {
        await axios.post(Backend(`has_notification_adm`), { "user": this.state.user.id_recurso }, {
          headers: { 'Content-Type': 'application/json' }
        }).then(async (response) => {
          await this.setState({ hasNotificationAdm: response.data });
          if (response.data > 0)
            document.getElementById('notificacao-adm-left').style.display = "inline-flex";
          else
            document.getElementById('notificacao-adm-left').style.display = "none";
        });
      }
    }
  }

  render() {
    let menus;
    menus = this.state.user !== null ?
      <ul className="nav metismenu" id="side-menu">
        <li className="nav-header">
          <span className="welcome-logo">
            <span> <img className="logo-full" alt="logo" src={Logo} /> </span>
            <center className="clear block m-t-xs"> <strong className="font-bold"> Bem vindo ! </strong> </center>
          </span>
          <div className="logo-element"> <img className="logo-icon" alt="logo-icon" src={Icon} /> </div>
        </li>
        {this.state.user.acesso.indexOf(1) !== -1 ?
          <li id='userApenas' className={this.state.activeIndex === 0 ? 'active' : ''} onClick={() => this.setState({ activeIndex: 0 })}>
            <a href="#/home">
              <i className="fa fa-clock-o" aria-hidden="true" data-tip="Detalhado" data-place="right" data-effect="solid" data-type="light"></i>
              <span className="nav-label"> Apontamento detalhado </span>
              <i id="notificacao-left" style={{ color: "#ff4a4a", marginLeft: "2px", zoom: "1.3" }} className="fa fa-exclamation" aria-hidden="true"></i>
            </a>
          </li>
          : null}

        {this.state.user.acesso.indexOf(2) !== -1 ?
          <li className={this.state.activeIndex === 1 ? 'active' : ''} onClick={() => this.setState({ activeIndex: 1 })}>
            <Link to="/aprovacao" >
              <i className="fa fa-check" data-tip="Aprovação de horas" data-place="right" data-effect="solid" data-type="light"></i>
              <span className="nav-label">  Aprovação de horas </span>
              <i id="notificacao-adm-left" style={{ color: "#ff4a4a", marginLeft: "2px", zoom: "1.3" }} className="fa fa-exclamation" aria-hidden="true"></i>
            </Link>
          </li>
          : null}

        {this.state.user.acesso.indexOf(5) !== -1 || this.state.user.acesso.indexOf(8) !== -1 || this.state.user.acesso.indexOf(10) !== -1 ?
          <li className={this.state.activeIndex === 3 ? 'active' : ''} onClick={() => this.setState({ activeIndex: 3 })}>
            <Link to="/cadastro" >
              <i className="fa fa-plus" data-tip="Cadastros" data-place="right" data-effect="solid" data-type="light"></i>
              <span className="nav-label"> Cadastros </span>
            </Link>
          </li>
          : null}

        {
          // this.state.user.acesso.indexOf(3) !== -1 ||
          // this.state.user.acesso.indexOf(4) !== -1 ?
          // <li className={this.state.activeIndex === 2 ? 'active' : ''} onClick={() => this.setState({ activeIndex: 2 })}>
          //   <Link to="/relatorios-protheus" >
          //     <i className="fa fa-file-text-o" data-tip="Gerar relatórios Protheus" data-place="right" data-effect="solid" data-type="light"></i>
          //     <span className="nav-label"> Relatórios Protheus </span>
          //   </Link>
          // </li>
          // : null
        }

        {this.state.user.acesso.indexOf(3) !== -1 ||
          this.state.user.acesso.indexOf(4) !== -1 ||
          this.state.user.acesso.indexOf(6) !== -1 ||
          this.state.user.acesso.indexOf(7) !== -1 ?
          <li className={this.state.activeIndex === 4 ? 'active' : ''} onClick={() => this.setState({ activeIndex: 4 })}>
            <Link to="/relatorios" >
              <i className="fa fa-file-text-o" data-tip="Gerar relatórios" data-place="right" data-effect="solid" data-type="light"></i>
              <span className="nav-label"> Relatórios </span>
            </Link>
          </li>
          : null}

        {this.state.user.acesso.indexOf(9) !== -1 ?
          <li className={this.state.activeIndex === 5 ? 'active' : ''} onClick={() => this.setState({ activeIndex: 5 })}>
            <Link to="/gerenciamento-horas" >
              <i className="fa fa-cog" data-tip="Gerenciamento de horas" data-place="right" data-effect="solid" data-type="light"></i>
              <span className="nav-label"> Gerenciamento de horas </span>
            </Link>
          </li>
          : null}
        {/* <li className={this.state.activeIndex === 2 ? 'active' : ''} onClick={() => this.setState({ activeIndex: 2 })}>
          <Link to="/relatorios" >
            <i className="fa fa-file-text-o" data-tip="Gerar relatórios" data-place="right" data-effect="solid" data-type="light"></i>
            <span className="nav-label"> Relatórios Protheus </span>
          </Link>
        </li> */}
        {/*<li className={this.state.activeIndex === 3 ? 'active' : ''} onClick={() => this.setState({ activeIndex: 3 })}>
          <Link to="/exportar" > <i className="fa fa-download" data-tip="Exportar arquivos" data-place="right" data-effect="solid" data-type="light"></i> <span className="nav-label">  Exportar aquivos </span> </Link>
        </li>
        <li className={this.state.activeIndex === 4 ? 'active' : ''} onClick={() => this.setState({ activeIndex: 4 })}>
          <Link to="/arquivosupload" > <i className="fa fa-folder-open" data-tip="Consulta Arquivos" data-place="right" data-effect="solid" data-type="light"></i> <span className="nav-label">  Consulta Arquivos </span> </Link>
        </li>
        <li className={this.state.activeIndex === 5 ? 'active' : ''} onClick={() => this.setState({ activeIndex: 5 })}>
          <Link to="/logsistema" > <i className="fa fa-pencil-square" data-tip="Log do Sistema" data-place="right" data-effect="solid" data-type="light"></i> <span className="nav-label">  Log do Sistema </span> </Link>
        </li>
        <li className={this.state.activeIndex === 6 ? 'active' : ''} onClick={() => this.setState({ activeIndex: 6 })}>
          <Link to="/configuracoes" > <i className="fa fa-cog" data-tip="Configurações" data-place="right" data-effect="solid" data-type="light"></i> <span className="nav-label">  Configurações </span> </Link>
        </li> */}
      </ul>
      : null
    return (
      <nav className="navbar-default navbar-static-side" role="navigation">
        <ReactTooltip />
        <div className="sidebar-collapse">
          {menus}
        </div>
      </nav>
    )
  }
}
