import React, { Component } from 'react';
import Title from '../../template/title';
import Backend from '../../utils/backend';
import axios from 'axios';
import { getToken } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import "./relatorios.css"

export default class RelatoriosHorasPlanejadas extends Component {

    constructor() {
        super()
        this.state = {
            user: jwt_decode(getToken()),
            subprojetos: [],
            subprojetosFiltrados: [],
            id_subprojeto: null,
            aprovadores: [],
            aprovadoresFiltrados: [],
            id_aprovador: null,
            loading: false,
        };
    }

    limparCampos = () => {
        this.setState({
            subprojetosFiltrados: this.state.subprojetos,
            id_subprojeto: null,
            aprovadoresFiltrados: this.state.aprovadores,
            id_aprovador: null,
        })
    }

    componentDidMount = () => {
        axios.get(Backend(`buscaAprovadoresESubprojeto`)).then((response) => {
            this.setState({
                subprojetos: response.data.subprojetos,
                aprovadores: response.data.aprovadores,
                subprojetosFiltrados: response.data.subprojetos,
                aprovadoresFiltrados: response.data.aprovadores
            })
        });
    }

    handleChangeSubprojeto = (e) => {
        let value = e.target.value;
        if (value === 'todos') {
            value = null;
            this.setState({
                id_subprojeto: value,
                id_aprovador: value,
                subprojetosFiltrados: this.state.subprojetos,
                aprovadoresFiltrados: this.state.aprovadores
            })
        } else {
            value = parseInt(value);
            const aprovadoresFiltrados = this.state.aprovadores.filter(e => e.id_subprojetos.includes(value))
            this.setState({
                id_subprojeto: value,
                aprovadoresFiltrados,
                id_aprovador: aprovadoresFiltrados[0].id_recurso
            })
        }
    }

    handleChangeAprovador = (e) => {
        let value = e.target.value;
        if (value === 'todos') {
            value = null
            this.setState({
                id_subprojeto: value,
                id_aprovador: value,
                subprojetosFiltrados: this.state.subprojetos,
                aprovadoresFiltrados: this.state.aprovadores
            })
        } else {
            value = parseInt(value);
            this.setState({
                id_aprovador: value,
                subprojetosFiltrados: this.state.subprojetos.filter(e => e.id_aprovador_horas === value),
            })
        }
    }

    gerarRelatorios = () => {

        const dadosRelatorio = {
            id_aprovador: this.state.id_aprovador,
            id_subprojeto: this.state.id_subprojeto
        }
        axios.post(Backend(`relatorioHorasPlanejadasXRealizadas`), dadosRelatorio, {
            headers: { 'Content-Type': 'application/json' }
        }).then((response) => {
            if (response.data.status === false) {
                console.log('Erro data false linha 97')
            } else {
                const url = window.URL.createObjectURL(new Blob(["\ufeff", response.data]));
                const link = document.createElement('a');
                link.href = url;
                const fileName = `HorasPlanejadasRealizadas_${moment(new Date()).format('YYYYMMDDHHmmss')}.csv`
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();// you need to remove that elelment which is created before
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="animated fadeInRight home">
                    <Title name="Relatórios" />
                    <div className="container">
                        <div className="row">
                            <h2 style={{ marginLeft: '15px' }}> Relatórios de Horas Planejadas X Realizadas  </h2>
                            <br />
                        </div>
                        <div className="row" style={{ marginLeft: "0px", marginBottom: "20px" }}>
                            <label className="mr15" htmlFor="grupo">Projeto: </label>
                            <select id="projeto"
                                value={this.state.id_subprojeto || "todos"}
                                onChange={this.handleChangeSubprojeto} className="p15 mr25">
                                <option value="todos">Todos</option>
                                {
                                    this.state.subprojetosFiltrados.map(element => {
                                        return (
                                            <option key={element.id_subprojeto} value={element.id_subprojeto}>{element.id_subprojeto_comercial} - {element.subprojeto}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div className="row" style={{ marginLeft: "0px", marginBottom: "20px" }}>
                            <label className="mr15" htmlFor="recurso">Aprovador: </label>
                            <select id="aprovador"
                                value={this.state.id_aprovador || 'todos'}
                                onChange={this.handleChangeAprovador} className="p15 mr25">
                                <option value='todos'>Todos</option>
                                {
                                    this.state.aprovadoresFiltrados.map(element => {
                                        return (
                                            <option key={element.id_recurso} value={element.id_recurso}>{element.recurso}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="row" style={{ marginLeft: "0px", marginBottom: "20px" }}>
                            <button onClick={this.limparCampos} className="btn mr15 btn-danger">Limpar filtro</button>
                            <button onClick={this.gerarRelatorios} className="btn mr15 btn-gerar">Gerar relatório</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}