import React, { Component } from 'react'
import "./CadastroCol.css"
import axios from "axios";
import Backend from "../../utils/backend";
import Swal from "sweetalert2"

export default class CadastroCol extends Component {

    constructor() {
        super()

        this.state = {
            errors: {},
            colTable: [],
            grupoList: [],
            perfilList: [],
            idFunc: [],
            colaborador: "",
            email: "",
            login: "",
            matricula: "",
            grupo: "",
            grupoOriginal: "",
            perfil: "",
            perfilOriginal: "",
            noc: 0,
            apontHoras: 1,
            btnEditar: false,
            ativo: 1,
            loading: false,
            idRecurso: null,
            matriculaOriginal: "",
            index: 1,
            resultCount: 20,
            filtro: "",
            btnFiltrar: false,
            todos: false,
            apontDetalhado: null,
            apvHoras: null,
            relatoriosGerar: null,
            relatoriosListar: null,
            cadastroProjetos: null,
            cadastroColPerm: null,
            cadastroFeriado: null,
            cidadeList: [],
            cidade: null,
            empresaList: [],
            empresa: null,
            relHoras: null,
            relHorasPorPrj: null,
            gerenciamentoHoras: null
        }
    }

    componentDidMount() {
        axios.get(Backend("get_grupo"))
            .then(res => this.setState({ grupoList: res.data }))

        axios.get(Backend("get_perfis"))
            .then(res => this.setState({ perfilList: res.data }))

        axios.get(Backend(`table_col/${1}`))
            .then(res => this.setState({ colTable: res.data }))

        axios.get(Backend("get_cidade"))
            .then(res => this.setState({ cidadeList: res.data }))

        axios.get(Backend("get_empresa_col"))
            .then(res => this.setState({ empresaList: res.data }))
    }

    atualizarTabela = () => {
        axios.get(Backend(`table_col/${1}`))
            .then(res => this.setState({ colTable: res.data }))
            .catch(err => console.log(err))
    }

    changeCol = (e) => this.setState({ colaborador: e.target.value })
    changeEmail = (e) => this.setState({ email: e.target.value.toLowerCase() })
    changeMatricula = (e) => this.setState({ matricula: e.target.value })
    changeLogin = (e) => this.setState({ login: e.target.value })
    changeGrupo = (e) => this.setState({ grupo: e.target.value })
    changePerfil = (e) => this.setState({ perfil: e.target.value })
    changeNOC = (e) => this.setState({ noc: this.state.noc === 0 ? 1 : 0 })
    changeApontHoras = (e) => this.setState({ apontHoras: this.state.apontHoras === 0 ? 1 : 0 })
    changeFiltro = (e) => this.setState({ filtro: e.target.value })
    changeApontDetalhado = (e) => this.setState({ apontDetalhado: this.state.apontDetalhado === null ? 1 : null, todos: false })
    changeApvHoras = (e) => this.setState({ apvHoras: this.state.apvHoras === null ? 2 : null, todos: false })
    changeRelatoriosGerar = (e) => this.setState({ relatoriosGerar: this.state.relatoriosGerar === null ? 3 : null, todos: false })
    changeRelatoriosListar = (e) => this.setState({ relatoriosListar: this.state.relatoriosListar === null ? 4 : null, todos: false })
    changeCadastroProjeto = (e) => this.setState({ cadastroProjetos: this.state.cadastroProjetos === null ? 5 : null, todos: false })
    changeCadastroColPerm = (e) => this.setState({ cadastroColPerm: this.state.cadastroColPerm === null ? 8 : null, todos: false })
    changeCadastroFeriado = (e) => this.setState({ cadastroFeriado: this.state.cadastroFeriado === null ? 10 : null, todos: false })
    changeRelHoras = (e) => this.setState({ relHoras: this.state.relHoras === null ? 6 : null, todos: false })
    changeRelHorasPorPrj = (e) => this.setState({ relHorasPorPrj: this.state.relHorasPorPrj === null ? 7 : null, todos: false })
    changeGerenciamentoHoras = (e) => this.setState({ gerenciamentoHoras: this.state.gerenciamentoHoras === null ? 9 : null, todos: false })
    changeCidade = (e) => this.setState({ cidade: e.target.value })
    changeEmpresa = e => this.setState({ empresa: e.target.value })

    changeTodos = (e) => {

        if (this.state.todos) {
            this.setState({
                todos: false,
                apontDetalhado: null,
                apvHoras: null,
                relatoriosGerar: null,
                relatoriosListar: null,
                cadastroProjetos: null,
                cadastroColPerm: null,
                cadastroFeriado: null,
                relHoras: null,
                relHorasPorPrj: null,
                gerenciamentoHoras: null
            })
        }

        else {
            this.setState({
                todos: true,
                apontDetalhado: 1,
                apvHoras: 2,
                relatoriosGerar: 3,
                relatoriosListar: 4,
                cadastroProjetos: 5,
                relHoras: 6,
                relHorasPorPrj: 7,
                cadastroColPerm: 8,
                gerenciamentoHoras: 9,
                cadastroFeriado:10
            })
        }
    }

    limparForm = () => {
        this.setState({
            colaborador: "",
            email: "",
            matricula: "",
            login: "",
            grupo: "",
            perfil: "",
            noc: 0,
            apontHoras: 1,
            btnEditar: false,
            todos: false,
            apontDetalhado: null,
            apvHoras: null,
            relatoriosGerar: null,
            relatoriosListar: null,
            cadastroProjetos: null,
            cadastroColPerm: null,
            cadastroFeriado: null,
            relHorasPorPrj: null,
            gerenciamentoHoras: null,
            relHoras: null,
            errors: {},
            empresa: "",
            cidade: ""
        })
    }

    editarClick = async (id) => {
        await this.setState({ todos: false, btnEditar: true, idRecurso: id, grupoOriginal: null, perfilOriginal: null, apontDetalhado: null, apvHoras: null, relatoriosGerar: null, relatoriosListar: null, cadastroProjetos: null, cadastroColPerm: null, cadastroFeriado: null })
        const res = await axios.post(Backend("update_col_btn"), { idRecurso: this.state.idRecurso })

        this.setState({
            colaborador: res.data.result[0].recurso,
            email: res.data.result[0].email,
            login: res.data.result[0].login,
            matricula: res.data.result[0].matricula,
            grupoOriginal: res.data.result[0].id_grupo,
            grupo: res.data.result[0].id_grupo,
            perfilOriginal: res.data.result[0].id_perfil,
            perfil: res.data.result[0].id_perfil,
            noc: res.data.result[0].noc,
            apontHoras: res.data.result[0].apont_horas,
            matriculaOriginal: res.data.result[0].matricula,
            loginOriginal: res.data.result[0].login,
            emailOriginal: res.data.result[0].email,
            idFunc: res.data.result2,
            apontDetalhado2: res.data.result2[0],
            cidade: res.data.result[0].id_cidade,
            empresa: res.data.result[0].id_empresa
        })

        if (this.state.idFunc.includes(1)) this.setState({ apontDetalhado: 1 })
        if (this.state.idFunc.includes(2)) this.setState({ apvHoras: 2 })
        if (this.state.idFunc.includes(3)) this.setState({ relatoriosGerar: 3 })
        if (this.state.idFunc.includes(4)) this.setState({ relatoriosListar: 4 })
        if (this.state.idFunc.includes(5)) this.setState({ cadastroProjetos: 5 })
        if (this.state.idFunc.includes(6)) this.setState({ relHoras: 6 })
        if (this.state.idFunc.includes(7)) this.setState({ relHorasPorPrj: 7 })
        if (this.state.idFunc.includes(8)) this.setState({ cadastroColPerm: 8 })
        if (this.state.idFunc.includes(9)) this.setState({ gerenciamentoHoras: 9 })
        if (this.state.idFunc.includes(10)) this.setState({ cadastroFeriado: 10 })

        if (this.state.idFunc.length === 9) {
            this.setState({ todos: true })
        }
    }

    myXOR = (x, y) => {
        return ((x || y) && !(x && y)) || !(x || y);
    }

    formatName = (text) => {
        const loweredText = text.toLowerCase();
        const words = loweredText.split(" ");
        for (let a = 0; a < words.length; a++) {
            let w = words[a];
            const firstLetter = w[0];
            const xor = this.myXOR(w.length === 3, w[0] === "d");
            console.log(xor)
            if (w.length > 2 && (xor)) {
                w = firstLetter.toUpperCase() + w.slice(1);
            } else {
                w = firstLetter + w.slice(1);
            }

            words[a] = w;
        }
        return words.join(" ");
    }

    salvar = (e) => {
        e.preventDefault();



        const novoCol = {
            colaborador: this.formatName(this.state.colaborador.trim()),
            email: this.state.email.trim(),
            matricula: this.state.matricula,
            login: this.state.login.trim().toLowerCase(),
            grupo: this.state.grupo,
            grupoOriginal: this.state.grupoOriginal,
            perfil: this.state.perfil,
            perfilOriginal: this.state.perfilOriginal,
            noc: this.state.noc,
            apontHoras: this.state.apontHoras,
            ativo: this.state.ativo,
            idRecurso: this.state.idRecurso,
            matriculaOriginal: this.state.matriculaOriginal,
            loginOriginal: this.state.loginOriginal,
            emailOriginal: this.state.emailOriginal,
            apvHoras: this.state.apvHoras,
            apontDetalhado: this.state.apontDetalhado,
            relatoriosGerar: this.state.relatoriosGerar,
            relatoriosListar: this.state.relatoriosListar,
            cadastroProjetos: this.state.cadastroProjetos,
            apontDetalhado2: this.state.apontDetalhado2,
            cadastroColPerm: this.state.cadastroColPerm,
            cadastroFeriado: this.state.cadastroFeriado,
            gerenciamentoHoras: this.state.gerenciamentoHoras,
            cidade: this.state.cidade,
            empresa: this.state.empresa,
            relHoras: this.state.relHoras,
            relHorasPorPrj: this.state.relHorasPorPrj
        }

        if (this.state.btnEditar) {
            axios.post(Backend("update_colaborador"), novoCol)
                .then(res => {
                    this.limparForm();
                    Swal.fire({
                        title: "Dados Salvos com Successo",
                        text: "O Colaborador foi editado",
                        type: "success",
                        confirmButtonColor: "#720425"
                    });
                    this.atualizarTabela();
                })
                .catch(err => this.setState({ errors: err.response.data }))
        }

        else {
            axios.post(Backend("post_colaborador"), novoCol)
                .then(res => {
                    this.limparForm();
                    Swal.fire({
                        title: "Dados salvos com sucesso",
                        text: "Um novo colaborador foi criado",
                        type: "success",
                        confirmButtonColor: "#720423"
                    });
                    this.atualizarTabela();
                })
                .catch(err => this.setState({ errors: err.response.data }))
        }
    }

    carrgarTabela = () => {

        const { index, filtro, colTable } = this.state;

        if (this.state.btnFiltrar) {
            axios.get(Backend(`filtrar_col_search/${filtro}/${index + 1}`))
                .then(res => this.setState({
                    colTable: colTable.concat(res.data),
                    index: index + 1,
                    resultCount: res.data.length
                }))
        }

        else {
            axios.get(Backend(`table_col/${index + 1}`))
                .then(res => this.setState({
                    colTable: colTable.concat(res.data),
                    index: index + 1,
                    resultCount: res.data.length
                }))
        }
    }

    filtrarClick = () => {

        const { filtro } = this.state;

        if (this.state.filtro) {
            this.setState({ btnFiltrar: true, index: 1, colTable: [] });

            axios.post(Backend(`filtrar_col`), { filtro: filtro })
                .then(res => { this.setState({ colTable: res.data, resultCount: res.data.length }) })
        }
    }

    limparFiltro = (e) => {
        this.setState({ filtro: "", btnFiltrar: false, resultCount: 20, index: 1, colTable: [] })
        this.atualizarTabela();
    }

    changeActive = (id, ativo) => {
        const activeInfo = { id: id, ativo: ativo }

        axios.post(Backend("ativar_colaborador"), activeInfo)
            .then(res => {
                this.setState({ index: 1 })
                this.atualizarTabela()
            })
    }



    render() {

        let i = 0;

        return (
            <div className="col-container animated fadeInRight">
                <h1>Cadastro de Colaboradores</h1>

                <form action="">
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label htmlFor=""><span className="asteric">*</span>Nome Colaborador</label>
                            <br />
                            <input type="text" className="form-control" onChange={this.changeCol} value={this.state.colaborador} />
                            <p className="errorMessage">{this.state.errors.colaborador}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-4">
                            <label htmlFor=""><span className="asteric">*</span>E-mail</label>
                            <br />
                            <input type="text" className="form-control" onChange={this.changeEmail} value={this.state.email} />
                            <p className="errorMessage">{this.state.errors.email}</p>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor=""><span className="asteric">*</span>Login</label>
                            <br />
                            <input type="text" className="form-control" onChange={this.changeLogin} value={this.state.login} />
                            <p className="errorMessage">{this.state.errors.login}</p>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor=""><span className="asteric">*</span>Matrícula</label>
                            <br />
                            <input type="text" className="form-control" onChange={this.changeMatricula} value={this.state.matricula} />
                            <p className="errorMessage">{this.state.errors.matricula}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-4">
                            <label htmlFor=""><span className="asteric">*</span>Localidade Trabalho</label>
                            <br />
                            <select className="form-control" onChange={this.changeCidade} value={this.state.cidade}>
                                <option></option>
                                {this.state.cidadeList.map(cid => (
                                    <option key={i++} value={cid.id_cidade}>{cid.cidade}</option>
                                ))}
                            </select>
                            <p className="errorMessage">{this.state.errors.cidade}</p>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor=""><span className="asteric">*</span>Empresa</label>
                            <br />
                            <select className="form-control" onChange={this.changeEmpresa} value={this.state.empresa}>
                                <option value=""></option>
                                {
                                    this.state.empresaList.map(emp => (
                                        <option key={i++} value={emp.id_empresa}>{emp.empresa}</option>
                                    ))
                                }
                            </select>
                            <p className="errorMessage">{this.state.errors.empresa}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-4">
                            <label htmlFor=""><span className="asteric">*</span>Grupo</label>
                            <br />
                            <select className="form-control" onChange={this.changeGrupo} value={this.state.grupo}>
                                <option></option>
                                {this.state.grupoList.map(grp => (
                                    <option key={i++} value={grp.id_grupo}>{grp.grupo}</option>
                                ))}
                            </select>
                            <p className="errorMessage">{this.state.errors.grupo}</p>
                        </div>

                        <div className="form-group col-md-4">
                            <label htmlFor=""><span className="asteric">*</span>Perfil</label>
                            <br />
                            <select className="form-control" onChange={this.changePerfil} value={this.state.perfil}>
                                <option></option>
                                {this.state.perfilList.map(prfl => (
                                    <option key={i++} value={prfl.id_perfil}>{prfl.perfil}</option>
                                ))}
                            </select>
                            <p className="errorMessage">{this.state.errors.perfil}</p>
                        </div>

                        <div className="form-group col-md-4">
                            <div className="row">
                                <div className="col-md-2 checkbox-div">
                                    <label htmlFor="" style={{ marginRight: "7px", marginTop: "1px" }}>NOC: </label>
                                    {this.state.noc === 0 ?
                                        <input type="checkbox" onChange={this.changeNOC} value={this.state.noc} /> :
                                        <input type="checkbox" onChange={this.changeNOC} value={this.state.noc} checked />
                                    }

                                </div>

                                <div className="col-md-8 checkbox-div" style={{ marginLeft: "-18px" }}>
                                    <label htmlFor="" style={{ marginRight: "5px" }}>Banco de Horas: </label>
                                    <input type="checkbox" onChange={this.changeApontHoras} checked={this.state.apontHoras} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="permissoes">
                        <h2>Permissões</h2>

                        <div className="perm-boxes">
                            <div className="perm-box">
                                <div className="perm-box2">
                                    <label htmlFor="">Todos:</label>
                                    {this.state.todos ||
                                        (this.state.apontDetalhado !== null &&
                                            this.state.apvHoras !== null &&
                                            this.state.cadastroProjetos !== null &&
                                            this.state.relatoriosGerar !== null &&
                                            this.state.relatoriosListar !== null &&
                                            this.state.cadastroColPerm !== null &&
                                            this.state.cadastroFeriado !== null &&
                                            this.state.relHoras !== null &&
                                            this.state.relHorasPorPrj !== null &&
                                            this.state.gerenciamentoHoras !== null
                                        ) ?
                                        <label htmlFor="" className="switch2">
                                            <input type="checkbox" className="icon-col2" checked />
                                            <span className="slider round" onClick={this.changeTodos} />
                                        </label>
                                        :
                                        <label htmlFor="" className="switch2">
                                            <input type="checkbox" className="icon-col2" />
                                            <span className="slider round" onClick={this.changeTodos} />
                                        </label>
                                    }
                                </div>
                                <div className="perm-box2">
                                    <label htmlFor="">Apontamento Detalhado: </label>
                                    <div className="slider-div">
                                        {this.state.apontDetalhado === 1 ?
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" checked />
                                                <span className="slider round" onClick={this.changeApontDetalhado} />
                                            </label>
                                            :
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" />
                                                <span className="slider round" onClick={this.changeApontDetalhado} />
                                            </label>
                                        }
                                    </div>
                                </div>

                                <div className="perm-box2">
                                    <label htmlFor="">Aprovação de Horas: </label>
                                    <div className="slider-div">
                                        {this.state.apvHoras === 2 ?
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" checked />
                                                <span className="slider round" onClick={this.changeApvHoras} />
                                            </label>
                                            :
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" />
                                                <span className="slider round" onClick={this.changeApvHoras} />
                                            </label>
                                        }
                                    </div>
                                </div>

                                <div className="perm-box2">
                                    <label htmlFor="">Cadastro de Projetos: </label>
                                    <div className="slider-div">
                                        {this.state.cadastroProjetos === 5 ?
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" checked />
                                                <span className="slider round" onClick={this.changeCadastroProjeto} />
                                            </label>
                                            :
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" />
                                                <span className="slider round" onClick={this.changeCadastroProjeto} />
                                            </label>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="perm-box">
                                <div className="perm-box2">
                                    <label htmlFor="">Cadastro de Colaboradores: </label>
                                    <div className="slider-div">
                                        {this.state.cadastroColPerm === 8 ?
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" checked />
                                                <span className="slider round" onClick={this.changeCadastroColPerm} />
                                            </label>
                                            :
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" />
                                                <span className="slider round" onClick={this.changeCadastroColPerm} />
                                            </label>
                                        }
                                    </div>
                                </div>

                                <div className="perm-box2">
                                    <label htmlFor="">Relatórios Protheus-Gerar: </label>
                                    {this.state.relatoriosGerar === 3 ?
                                        <label htmlFor="" className="switch2">
                                            <input type="checkbox" className="icon-col2" checked />
                                            <span className="slider round" onClick={this.changeRelatoriosGerar} />
                                        </label>
                                        :
                                        <label htmlFor="" className="switch2">
                                            <input type="checkbox" className="icon-col2" />
                                            <span className="slider round" onClick={this.changeRelatoriosGerar} />
                                        </label>
                                    }
                                </div>

                                <div className="perm-box2">
                                    <label htmlFor="">Relatórios Protheus-Listar: </label>
                                    {this.state.relatoriosListar === 4 ?
                                        <label htmlFor="" className="switch2">
                                            <input type="checkbox" className="icon-col2" checked />
                                            <span className="slider round" onClick={this.changeRelatoriosListar} />
                                        </label>
                                        :
                                        <label htmlFor="" className="switch2">
                                            <input type="checkbox" className="icon-col2" />
                                            <span className="slider round" onClick={this.changeRelatoriosListar} />
                                        </label>
                                    }
                                </div>
                                <div className="perm-box2">
                                    <label htmlFor="">Relatórios de Horas: </label>
                                    <div className="slider-div">
                                        {this.state.relHoras === 6 ?
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" checked />
                                                <span className="slider round" onClick={this.changeRelHoras} />
                                            </label>
                                            :
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" />
                                                <span className="slider round" onClick={this.changeRelHoras} />
                                            </label>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="perm-box">
                                <div className="perm-box2">
                                    <label htmlFor="">Relatórios de Horas Por Projeto: </label>
                                    <div className="slider-div">
                                        {this.state.relHorasPorPrj === 7 ?
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" checked />
                                                <span className="slider round" onClick={this.changeRelHorasPorPrj} />
                                            </label>
                                            :
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" />
                                                <span className="slider round" onClick={this.changeRelHorasPorPrj} />
                                            </label>
                                        }
                                    </div>
                                </div>

                                <div className="perm-box2">
                                    <label htmlFor="">Gerenciamento de Horas Por Projeto: </label>
                                    <div className="slider-div">
                                        {this.state.gerenciamentoHoras === 9 ?
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" checked />
                                                <span className="slider round" onClick={this.changeGerenciamentoHoras} />
                                            </label>
                                            :
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" />
                                                <span className="slider round" onClick={this.changeGerenciamentoHoras} />
                                            </label>
                                        }
                                    </div>
                                </div>

                                <div className="perm-box2">
                                    <label htmlFor="">Cadastro de Feriados: </label>
                                    <div className="slider-div">
                                        {this.state.cadastroFeriado === 10 ?
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" checked />
                                                <span className="slider round" onClick={this.changeCadastroFeriado} />
                                            </label>
                                            :
                                            <label htmlFor="" className="switch2">
                                                <input type="checkbox" className="icon-col2" />
                                                <span className="slider round" onClick={this.changeCadastroFeriado} />
                                            </label>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="errorMessage">{this.state.errors.permissoes}</p>
                    </div>

                    <div className="form-group col-md-8">
                        <div className="row">
                            <button type="submit" className="btn btn-cadastro" onClick={this.salvar}>Salvar</button>
                            <button onClick={this.limparForm} className="btn btn-cadastro">Limpar</button>
                        </div>
                    </div>
                </form>

                <div className="form-inline filtro">
                    <label style={{ marginRight: "10px" }}>Filtrar Tabela:</label>
                    <input type="text" style={{ marginRight: "10px" }} className="form-control" value={this.state.filtro} onChange={this.changeFiltro} />
                    <button className="btn btn-cadastro" onClick={this.filtrarClick} style={{ marginRight: "10px" }}>Filtrar</button>
                    <button className="btn btn-cadastro" onClick={this.limparFiltro}>Limpar Filtro</button>
                </div>

                <div className="table3 table-responsive">
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <th class="colunaCol" align="center">Colaborador</th>
                                <th class="colunaEmail" align="center">Email</th>
                                <th class="colunaLogin" align="center">Login</th>
                                <th class="colunaMat" align="center">Matricula</th>
                                <th class="colunaGrupo" align="center">Grupo</th>
                                <th class="colunaPerfil" align="center">Perfil</th>
                                <th class="colunaNOC" align="center">NOC</th>
                                <th class="colunaApt" align="center">Apontamentos<br />de Horas</th>
                                <th class="colunaEditar" align="center">Editar</th>
                                <th class="colunaAti" align="center">Ativação</th>
                            </tr>

                            {!this.state.loading ?
                                this.state.colTable.map(col => (
                                    <React.Fragment>
                                        <tr>
                                            {console.log(this.state.colTable)}
                                            <td align="center">{col.recurso}</td>
                                            <td align="center">{col.email}</td>
                                            <td align="center">{col.login}</td>
                                            <td align="center">{col.matricula}</td>
                                            <td align="center">{col.grupo}</td>
                                            <td align="center">{col.perfil}</td>
                                            <td align="center">{col.noc === 1 ? "Sim" : "Não"}</td>
                                            <td align="center">{col.apont_horas === 1 ? "Sim" : "Não"}</td>
                                            <td align="center">
                                                <i onClick={this.editarClick.bind(this, col.id_recurso)} className="fa fa-pencil-square-o btn-editar" style={{ marginTop: "10px", color: "gray" }} aria-hidden="true"></i>
                                            </td>
                                            <td align="center">
                                                {col.ativo === 1 ?
                                                    <label htmlFor="" className="switch">
                                                        <input type="checkbox" className="icon-col" checked />
                                                        <span className="slider round" onClick={this.changeActive.bind(this, col.id_recurso, col.ativo)} />
                                                    </label>
                                                    :
                                                    <label htmlFor="" className="switch">
                                                        <input type="checkbox" className="icon-col" />
                                                        <span className="slider round" onClick={this.changeActive.bind(this, col.id_recurso, col.ativo)} />
                                                    </label>
                                                }
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )) : null}
                        </tbody>
                    </table>

                    {this.state.resultCount === 0 ? <h2>Nenhum Resultado Encontrado</h2> : null}

                    <div className="carregar-div">
                        {this.state.resultCount < 20 ?
                            <button onClick={this.carrgarTabela} className="btn btn-carregar" disabled>Carregar Mais</button>
                            :
                            <button onClick={this.carrgarTabela} className="btn btn-carregar">Carregar Mais</button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
