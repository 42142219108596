import React, { Component } from 'react';
import Title from '../../template/title';
import Backend from '../../utils/backend';
import axios from 'axios';
import { getToken } from '../../auth/auth';
import jwt_decode from 'jwt-decode';
import DatePicker, { registerLocale } from 'react-datepicker';
import pt_BR from "date-fns/locale/pt-BR";
import Swal from 'sweetalert2';
import TableMacro from './table/macro/table'
import "react-datepicker/dist/react-datepicker.css";
import "./relatorios.css"
registerLocale("pt-BR", pt_BR);
const moment = require('moment');

export default class RelatoriosHorasBanco extends Component{

  pad = (number, length) => {   
    let str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }       
    return str;    
  }

  componentDidMount = () => {
    if(this.state.user.id_perfil !== 6 && this.state.user.id_perfil !== 1) 
      this.setState({id_colaborador : this.state.user.id_recurso});

    if(this.state.user.id_perfil === 6 || this.state.user.id_perfil === 1)
      this.buscarColaboradores(this.state.id_grupo);

    if(this.state.user.id_perfil === 6 || this.state.user.id_perfil === 1)
      this.buscarGrupos(this.state.id_grupo); 
  }

  constructor(){
    super()
    this.state = { 
      user: jwt_decode(getToken()),
      startDate: new Date(),
      endDate: new Date(),
      colaboradores: [],
      id_colaborador: null,
      grupos: [],
      id_grupo: null,
      loading: false,
      exibeMacro: false,
      macro: null,
      nomeArquivo: null
    };
    this.handleChangeGrupo = this.handleChangeGrupo.bind(this)
  }

  handleChangeDate(date, periodo) {
    if(periodo === 1) {
      // if(date <= this.state.endDate){
      //   this.setState({ startDate: date });
      // }
      this.setState({ startDate: date });
    }
    else {
      // if(date >= this.state.startDate){
      //   this.setState({ endDate: date });
      // }
      this.setState({ endDate: date });
    }
  }

  limparCampos = () => {
    this.setState({
      startDate: new Date(),
      endDate: new Date(),
      colaboradores: [],
      id_colaborador: null,
      grupos: [],
      id_grupo: null,
    })
    if(this.state.user.id_perfil === 6 || this.state.user.id_perfil === 1)
      this.buscarColaboradores(null);

    if(this.state.user.id_perfil === 6 || this.state.user.id_perfil === 1)
      this.buscarGrupos(null);
  }

  buscarColaboradores = id_grupo => {
    axios.post(Backend(`colaboradores_ativos_rel_horas`), { id_grupo, id_user: this.state.user.id_recurso }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      this.setState({
        colaboradores : response.data,
        id_grupo
      });
    });
  }

  buscarGrupos = id_grupo => {
    axios.post(Backend(`grupos_rel_horas`), { id_grupo, id_user: this.state.user.id_recurso }, {
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      this.setState({
        grupos : response.data,
        id_grupo
      });
    });
  }

  handleChangeGrupo = (e) => {
    let value = e.target.value;
    if(value === 'todos'){
      value = null;
      this.setState({
        id_grupo: null,
        id_colaborador: null
      })
    }
    this.buscarColaboradores(value);
    this.buscarGrupos(value);
  }

  handleChangeGrupoColaborador = (e) => {
    let id_colaborador = e.target.value;
    if(id_colaborador === 'todos'){
      this.buscarColaboradores(null);
      this.buscarGrupos(null);
      this.setState({
        id_grupo: null,
        id_colaborador: null
      })
    }
    else{
      axios.post(Backend(`colaboradorById`), { id_colaborador }, {
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        this.buscarColaboradores(response.data.id_grupo);
        this.buscarGrupos(response.data.id_grupo);
        this.setState({ 
          id_colaborador,
          id_grupo: response.data.id_grupo
        });
      });
    }    
  }

  gerarRelatorios = async () => {
   
    const dadosRelatorio = {
      id_colaborador : null,
      id_grupo : null,
      start : moment(this.state.startDate).format("YYYY-MM-DD"),
      end : moment(this.state.endDate).format("YYYY-MM-DD"),
      id_gestor : null
    }
    if(this.state.user.id_perfil === 6 || this.state.user.id_perfil === 1){
      dadosRelatorio.id_gestor = this.state.user.id_recurso;
      dadosRelatorio.id_colaborador = this.state.id_colaborador;
      dadosRelatorio.id_grupo = this.state.id_grupo;
    }else {
      dadosRelatorio.id_colaborador = this.state.user.id_recurso;
      dadosRelatorio.id_grupo = this.state.user.id_grupo;
    }
    
    if(dadosRelatorio.start > dadosRelatorio.end){
      Swal.fire({
        title:"Atenção",
        text: "Data inicial não pode ser posterior a data final",
        type: "warning", confirmButtonColor: '#720425',
      });
    }
    else if(dadosRelatorio.start < '2019-09-16'){
      Swal.fire({
        title:"Atenção",
        text: "Data inicial não pode ser anterior ao dia 16/09/2019",
        type: "warning", confirmButtonColor: '#720425',
      });
    }
    else{
      await axios.post(Backend(`busca_dados_relatorio_horas`), dadosRelatorio, {
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        if(response.data.macro.length > 0){
          let text = '';
          let title = 'Relatório gerado';
          let type = 'success';
          Swal.fire({
            title, text,
            type, confirmButtonColor: '#720425',
          }).then((result) => {
            this.setState({
              exibeMacro: true,
              macro: response.data.macro,
              nomeArquivo: response.data.nome,
            })
          });
        }else {
          let text = 'Não existe nenhum apontamento aprovado neste período.';
          let title = 'Relatório não gerado';
          let type = 'warning';
          Swal.fire({
            title, text,
            type, confirmButtonColor: '#720425',
          }).then(result => {
            this.setState({
              exibeMacro: false,
              macro: null,
              nomeArquivo: null
            })
          });
        }
      });
    }
  }

  render(){
    return (
      <>
        <div className="animated fadeInRight home">
          <Title name="Relatórios" />
          <div className="container">
            <div className="row">
              <h2 style={{marginLeft:'15px'}}> Relatórios de Horas  </h2>
              <br />
            </div>
            <div className="row" style={{marginLeft: "-15px", marginBottom: "20px"}}>
              <div className="col-md-12">
                <label className="mr15">
                  Os ciclos de banco de horas são: 
                  <strong> 16/Dezembro a 15/Abril - 16/Abril a 15/Agosto - 16/Agosto a 15/Dezembro</strong>, <br />
                  caso seja selecionado um período fora dos ciclos o mesmo não refletirá o banco de horas real.
                </label>
              </div>
            </div>
            <div className="row" style={{marginLeft: "0px", marginBottom: "20px"}}>
              <label className="mr15" htmlFor="inicio"><span className="astericoVermelho">*</span>Data inicial: </label>
              <DatePicker
                id="inicio" 
                onChange={e => this.handleChangeDate(e, 1)}
                selected={this.state.startDate}
                minDate='2019-09-16'
                maxDate={this.state.endDate}
                locale='pt-BR'
                dateFormat="dd/MM/yyyy"
                todayButton={"Hoje"}
                className="mr15"
              />
              <label className="mr15" htmlFor="fim"><span className="astericoVermelho">*</span>Data final: </label>
              <DatePicker
                id="fim" 
                onChange={e => this.handleChangeDate(e, 2)}
                selected={this.state.endDate}
                minDate={this.state.startDate}
                locale='pt-BR'
                dateFormat="dd/MM/yyyy"
                todayButton={"Hoje"}
                className="mr15"
              />
            </div>
            {this.state.user.id_perfil === 6 || this.state.user.id_perfil === 1 ?
            <div className="row" style={{marginLeft: "0px", marginBottom: "20px"}}>
              <label className="mr15" htmlFor="grupo">Centro de custo: </label>
              <select id="grupo" 
              value={this.state.id_grupo || "todos"} 
              onChange={this.handleChangeGrupo} className="p15 mr25">
                {/* {
                  this.state.grupos.length > 1 ? <option value="todos"></option>: null
                } */}
                <option value="todos">Todos</option>
                {
                  this.state.grupos.map((element, i) => {                   
                    return(
                      <option key={i} value={element.id_grupo}>{element.grupo}</option>
                    )
                  })
                }
              </select>
              <label className="mr15" htmlFor="recurso">Colaborador: </label>
              <select id="recurso"
              value={this.state.id_colaborador || 'todos'}
              onChange={this.handleChangeGrupoColaborador} className="p15 mr25">
                <option value='todos'>Todos</option>
                {
                  this.state.colaboradores.map((element, i) => {
                    return(
                      <option key={i} value={element.id_recurso}>{this.pad(element.matricula, 12) + " - "+ element.recurso}</option>
                    )
                  })
                }
              </select>
            </div>
            : 
            <div className="row" style={{marginLeft: "0px", marginBottom: "20px"}}>
              <label className="mr15" htmlFor="grupo">Centro de custo: </label>
              <input type="text" value={this.state.user.grupo} className="p15 mr25" readOnly/>
              
              <label className="mr15" htmlFor="recurso">Colaborador: </label>
              <input type="text" value={this.state.user.recurso} style={{width: "300px"}} className="p15 mr25" readOnly/>
              
            </div>
            }
            <div className="row" style={{marginLeft: "0px", marginBottom: "20px"}}>
              <button onClick={this.limparCampos} className="btn mr15 btn-danger">Limpar filtro</button>
              <button onClick={this.gerarRelatorios} className="btn mr15 btn-gerar">Gerar relatório</button>
            </div>
            <div className="row">
              {/* <h2 style={{marginLeft:'15px'}}> Relatórios </h2>
              <br /> */}
            </div>
            {this.state.exibeMacro ? <TableMacro macro={this.state.macro} nome={this.state.nomeArquivo}/> : null}
         
          </div>
        </div>
      </>
    )
  }
}