import React from 'react';

import { bindActionCreators } from 'redux';
import * as editProjectActions from '../../../store/actions/editHoraProjeto';
import { connect } from 'react-redux';


const Tbody = (props) => {

  const { projetos, id_aprovador } = props;

  const sendIdProjectToStore = id => {
    props.addIdProjectEditHours(id);
    const link = document.createElement('a');
    link.href = '#/editar-horas-projeto';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }


  return (
    <tbody className="text-center table-striped">

      {
        projetos.map((e, i) => {
          let cor = '#fff';
          // e.horas && e.gastas.hours >= e.horas * 0.9 ? '#fae6e6' : '#fff'
          return (
            <tr key={i} style={{ backgroundColor: cor }}>
              <td className="td-gerenciamento">{
                e.id_projeto_comercial + " - " + e.subprojeto}</td>
              <td className="td-gerenciamento">{e.horas}</td>
              <td className="td-gerenciamento">{
                (e.gastas.hours || '00') + ':' + (e.gastas.minutes || '00')}
              </td>
              <td className="td-gerenciamento">{e.aprovador}</td>
              <td className="td-gerenciamento">{
                e.id_aprovador_horas === id_aprovador ?
                  <i className="fa fa-pencil edit-icon" onClick={() =>
                    sendIdProjectToStore(e.id_subprojeto)} aria-hidden="true"></i>
                  :
                  <i className="fa fa-pencil non-edit-icon" aria-hidden="true"></i>}
              </td>
            </tr>
          )
        })
      }
    </tbody>
  )
}

const mapStateToProps = state => ({
  editHoraProjeto: state.editHoraProjeto,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(editProjectActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tbody);