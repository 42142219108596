import React from 'react';

const Thead = () => {

    return (
        <thead>
            <tr>
                <th className="th-black text-center fixo2">Projeto</th>
                <th className="th-black text-center fixo1">Horas do projeto</th>
                <th className="th-black text-center fixo1">Horas gastas</th>
                <th className="th-black text-center fixo2">Gestor do projeto</th>
                <th className="th-black text-center fixo1"></th>
            </tr>
        </thead>
    )
}

export default Thead;